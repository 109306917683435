import React from 'react'
import classes from './AsideMobile.module.sass'
import AsideButton from './AsideButton'
import CatalogAside from './CatalogAside'
import AnimateHeight from 'react-animate-height'

const AsideMobile = ({ categories, openMobileAside, setOpenMobileAside, pM }) => {
    return (
        <div className={classes.aside}>
            <AsideButton 
                opened={openMobileAside === 'auto'} 
                onClick={() => setOpenMobileAside(prev => prev === 0 ? 'auto' : 0)}
            />
            <AnimateHeight
                duration={500}
                height={openMobileAside}
            >
                <CatalogAside 
                    categories={categories} 
                    setOpenMobileAside={setOpenMobileAside}
                    pM={pM}
                />
            </AnimateHeight>
        </div>
    )
}

export default AsideMobile
