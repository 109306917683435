import React from 'react'
import classes from './Cookies.module.sass'
import BorderButton from '../buttons/BorderButton/BorderButton'

const Cookies = ({ cookiesClick }) => {
	return (
		<div className={classes.cookies}>
			<p>Мы используем файлы cookie для того, чтобы предоставить Вам больше возможностей при использовании сайта.</p>
			<BorderButton name='Хорошо' onClick={cookiesClick} />
		</div>
	)
}

export default Cookies
