import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './redux/reduxStore'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.sass'
import Header from './components/common/Header/Header'
import Footer from './components/common/Footer/Footer'
import AccountProvider from './components/AccountContext'
import RoutesSwitcher from './RoutesSwitcher'
import AccountModals from './components/common/Modals/AccountModals'
import Cookies from './components/common/Cookies/Cookies'

const App = () => {
	const [cookies, setCookies] = useState(true)
	useEffect(() => setCookies(!localStorage.cookies), [])

	const cookiesClick = () => {
		localStorage.cookies = true
		setCookies(false)
	}

	return (
		<Provider store={store}>
			<AccountProvider>
				<Router basename={process.env.PUBLIC_URL}>
					<AccountModals />
					{cookies &&
						<Cookies cookiesClick={cookiesClick} />
					}
					<Header />
					<RoutesSwitcher />
					<Footer />
				</Router>
			</AccountProvider>
		</Provider>
	)
}

export default App