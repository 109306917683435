import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useLocation, NavLink } from 'react-router-dom'
import MenuContainer from './Menu/MenuContainer'
import logo from '../../../assets/img/logo-black.svg'
import classes from './Header.module.sass'
import AccountLink from './AccountLink/AccountLink'
import Search from './Search/Search'
import cn from 'classnames'
import { withBreakpoints } from '../../../hoc/withBreakpoints'
import RotateDevice from '../RotateDevice/RotateDevice'

const Header = ({ D, lM }) => {
    const { pathname } = useLocation()
    return (
        <>
            {lM && <RotateDevice />}
            <header className={cn(classes.Header, { [classes.withLogo]: pathname !== '/' })}>
                <Container>
                    <Row>
                        <Col md={4} xl={6} className='col'>
                            <MenuContainer D={D} />
                            <Link to='/' className={classes.logo}>
                                <img src={logo} alt='' />
                            </Link>
                        </Col>
                        <Col md={4} xl={3} className='col'>
                            <div className={classes.menu}>
                                <AccountLink />
                                <ul className='textCaps'>
                                    <li><NavLink to='/catalog' activeClassName={classes.active}>Каталог</NavLink></li>
                                    <li><NavLink strict to='/compilation' activeClassName={classes.active}>Подборки</NavLink></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4} xl={3} className='col d-none d-md-block'>
                            <div className={classes.search}>
                                <Search />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    )
}

export default withBreakpoints(Header)