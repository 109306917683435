import React from 'react'
import { Row, Col } from 'react-bootstrap'
import classes from './CatalogGrid.module.sass'
import Product from '../common/Product/Product'
import CatalogBanner from './CatalogBanner/CatalogBanner'

const CatalogGrid = ({ products, banners, bannerAfterEach, pM }) => {
    const numOfRows = Math.ceil(products.length / bannerAfterEach)

    const calcSize = i => {
        if (
            i % 6 === 0 ||
            (i - 1) % 6 === 0 ||
            (i - 2) % 6 === 0 ||
            (i - 3) % 6 === 0
        ) { return true }
        return false
    }

    const setProducts = (from = 0, to = bannerAfterEach) =>
        products.slice(from, from + to).map((el, i) =>
            <Col
                key={el.id}
                xs={pM && calcSize(i) ? 6 : 12}
                md={6}
                lg={4}
                className={classes.col}
            >
                <Product 
                    {...el} 
                    smallProduct={pM && calcSize(i)}
                    catalogProduct 
                />
            </Col>
        )

    return (
        <>
            {new Array(numOfRows).fill('').map((_, i) =>
                <React.Fragment key={i}>
                    <Row className={classes.row}>
                        {setProducts(i * bannerAfterEach)}
                    </Row>
                    {banners[i] &&
                        <CatalogBanner el={banners[i]} />}
                </React.Fragment>
            )}
        </>
    )
}

export default CatalogGrid
