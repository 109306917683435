import React, { useEffect } from 'react'
import classes from './Home.module.sass'
import logo from '../../assets/img/main-logo.svg'
import HomeSlider from './HomeSlider/HomeSlider'
import BigButton from '../common/buttons/BigButton/BigButton'
import { Container } from 'react-bootstrap'
import HomeCompilation from './HomeCompilation/HomeCompilation'
import Offer from '../common/Offer/Offer'
import { getHome, getHomeInCart } from '../../redux/homeReducer'
import { connect } from 'react-redux'
import cn from 'classnames'
import BasketLink from '../Catalog/BasketLink/BasketLink'

const Home = ({
      compilation,
      goods,
      getHome,
      isAuth,
      hasGoods,
      fetching,
      getHomeInCart
}) => {

      useEffect(() => compilation.length < 1 && getHome(), [compilation.length, getHome])

      return (
            <div className={cn(classes.homeContainer, 'fetched', fetching && 'loading')}>
                  <section className={classes.title}>
                        <img src={logo} alt='' />
                  </section>
                  <section className={classes.slider}>
                        <Container>
                              <div className={classes.sliderContainer}>
                                    <HomeSlider compilation={compilation} />
                              </div>
                              <div className={classes.buttonContainer}>
                                    <BigButton to='/compilation' name='Посмотреть все подборки' />
                              </div>
                        </Container>
                  </section>
                  <section className={classes.compilation}>
                        <HomeCompilation
                              goods={goods}
                              getHomeInCart={getHomeInCart}
                        />
                        <div className={classes.buttonContainer}>
                              <BigButton to='/catalog' name='Перейти в каталог' />
                        </div>
                  </section>
                  <section className={classes.offer}>
                        <Offer />
                  </section>
                  <BasketLink
                        enabled={!isAuth}
                        notEmpty={hasGoods}
                  />
            </div>
      )
}

const mapStateToProps = state => {
      return {
            compilation: state.home.compilation,
            goods: state.home.goods,
            isAuth: state.account.isAuth,
            hasGoods: state.catalog.hasGoods,
            fetching: state.staticContent.fetching
      }
}

export default connect(mapStateToProps, {
      getHome,
      getHomeInCart
})(Home)