import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classes from './AccountTitle.module.sass'
import cn from 'classnames'
import { Col, Row } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import NumberFormat from 'react-number-format'
import AutosizeInput from 'react-input-autosize'
import { useForm, Controller } from 'react-hook-form'

const AccountTitle = ({
    name,
    phone,
    email,
    discountPercent,
    disabled,
    updateAccountInfo,
    logoutThunk,
    callModal,
    isAuth,
    TM
}) => {
    
    const { control, handleSubmit, reset, errors } = useForm()
    const refName = useRef()
    const refPhone = useRef()
    const refEmail = useRef()
    const [editTitle, setEditTitle] = useState(false)

    const isPhone = value => {
        const replaced = value.replace(/[-()\s]/g, '')
        return replaced.length >= 12
    }

    const onSubmit = data => {
        setEditTitle(false)
        refName.current.blur()
        refPhone.current.blur()
        refEmail.current.blur()
        updateAccountInfo(
            data.name,
            data.phone,
            data.email,
            discountPercent,
            disabled
        )
    }

    const handleClose = () => handleSubmit(onSubmit)()

    const handleLogout = () => logoutThunk()

    const preventEnter = e => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()
            handleClose()
        }
    }

    const escHandler = useCallback(e => {
        if (e.keyCode === 27) {
            setEditTitle(false)
            refName.current.blur()
            refPhone.current.blur()
            refEmail.current.blur()
            reset()
        }
    }, [setEditTitle, reset])

    useEffect(() => {
        document.addEventListener('keydown', escHandler, false)
        return () => document.removeEventListener('keydown', escHandler, false)
    }, [escHandler])

    return (
        <Row>
            <Col xs={12} md={8} lg={6} className='col'>
                <div className={classes.title}>
                    {isAuth ?
                        <div className={cn(
                            classes.registered, 
                            editTitle ? classes.active : null,
                            TM && classes.touch
                            )}>
                            <div className={classes.close} onClick={handleClose}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.99999 10.6L2.49999 7.10001L1.79999 7.80001L5.29999 11.3L5.99999 12L13.1 4.90001L12.4 4.20001L5.99999 10.6Z" fill="#FEFEFC" />
                                </svg>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={classes.name}>
                                    <div className={classes.item}>
                                        <Controller
                                            render={({ value, onChange }) => (
                                                <TextareaAutosize
                                                    value={value}
                                                    onChange={onChange}
                                                    onFocus={() => setEditTitle(true)}
                                                    onKeyDown={e => preventEnter(e)}
                                                    ref={e => refName.current = e}
                                                />
                                            )}
                                            name='name'
                                            control={control}
                                            defaultValue={name}
                                            rules={{
                                                required: true,
                                                minLength: 2,
                                                maxLength: 20,
                                                pattern: /^[A-zа-яА-ЯЁё]+(?:\s[A-zа-яА-ЯЁё]+)+$/
                                            }}
                                        />
                                        <span className={cn(classes.error, 'textSmall')}>
                                            {errors.name?.type === 'required' && <p>обязательное поле</p>}
                                            {errors.name?.type === 'minLength' && <p>слишком короткое имя</p>}
                                            {errors.name?.type === 'maxLength' && <p>слишком длинное имя</p>}
                                            {errors.name?.type === 'pattern' && <p>введите имя и фамилию</p>}
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.lower}>
                                    <div className={classes.item}>
                                        <Controller
                                            as={
                                                <NumberFormat
                                                    onFocus={() => setEditTitle(true)}
                                                    onKeyDown={e => preventEnter(e)}
                                                    format={'+# ### ### ## ##'}
                                                    getInputRef={e => refPhone.current = e}
                                                />
                                            }
                                            name='phone'
                                            control={control}
                                            defaultValue={phone}
                                            rules={{
                                                required: true,
                                                validate: value => isPhone(value)
                                            }}
                                        />
                                        <span className={cn(classes.error, 'textSmall')}>
                                            {errors.phone?.type === 'required' && <p>обязательное поле</p>}
                                            {errors.phone?.type === 'validate' && <p>неверный номер</p>}
                                        </span>
                                    </div>
                                    <div className={classes.item}>
                                        <Controller
                                            render={({ value, onChange }) => (
                                                <AutosizeInput
                                                    type='text'
                                                    value={value}
                                                    onChange={onChange}
                                                    onFocus={() => setEditTitle(true)}
                                                    onKeyDown={e => preventEnter(e)}
                                                    ref={e => e && (e.inputRef = refEmail)}
                                                />
                                            )}
                                            name='email'
                                            control={control}
                                            defaultValue={email}
                                            rules={{
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                            }}
                                        />
                                        <span className={cn(classes.error, 'textSmall')}>
                                            {errors.email?.type === 'required' && <p>обязательное поле</p>}
                                            {errors.email?.type === 'pattern' && <p>введите email</p>}
                                        </span>
                                    </div>
                                    <button
                                        onClick={handleLogout}
                                        className={classes.logout}
                                        type='button'
                                    >
                                        <p>Выйти</p>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.89005 2H3.79681C3.18574 2 2.77836 2.4 2.77836 3L2.77836 5L3.79681 5L3.79681 3L8.89005 3L13.9813 3L13.9813 13L8.88906 13H3.7978L3.7978 11H2.77936L2.77936 13C2.77935 13.6 3.18673 14 3.7978 14H8.88906L13.9813 14H13.9823C14.5934 14 15.0008 13.6 15.0008 13L15.0008 3C15.0008 2.4 14.5934 2 13.9823 2H13.9813H8.89005ZM6.81644 5.21499L7.5366 4.50789L11.1374 8.04342L7.5366 11.579L6.81644 10.8718L9.19295 8.5384H1.99963V7.54845L9.19295 7.54845L6.81644 5.21499Z" fill="#D9917B" />
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className={classes.guest}>
                            <h1>Гость</h1>
                            <Link to='#' onClick={() => callModal('registration')}>
                                Зарегистрироваться
                            </Link>
                        </div>
                    }
                </div>
            </Col>
        </Row >
    )
}

export default AccountTitle
