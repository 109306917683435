import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classes from './MobileMenu.module.sass'
import logo from '../../../../assets/img/logo-white.svg'
import Social from '../../Social/Social'
import Search from '../Search/Search'
import AccountLink from '../AccountLink/AccountLink'
import Mailto from '../../Mailto'
import Tel from '../../Tel'
import cn from 'classnames'

const MobileMenu = ({ setMenuState, setMenuCol, perCol, phone, email }) => {
    const handleClick = () => setMenuState(false)

    const links = [
        { path: '/compilation', title: 'Подборки' },
        { path: '/designers', title: 'Дизайнерам' },
        { path: '/about', title: 'Об авторе' },
        { path: '/delivery', title: 'Доставка' },
        { path: '/payment', title: 'Оплата' },
        { path: '/warranty', title: 'Гарантия' },
        { path: '/returns', title: 'Возврат' },
        { path: '/howto', title: 'Как оформить заказ' }
    ]

    return (
        <Container>
            <div className={classes.upperSide}>
                <Row className={classes.row}>
                    <Col md={4} className='col d-none d-md-block'>
                        <div className={classes.logo}>
                            <img src={logo} alt='' />
                        </div>
                    </Col>
                    <Col xs={6} md={4} className={cn(classes.col, 'col')}>
                        <AccountLink onClick={handleClick} dark />
                    </Col>
                    <Col xs={6} md={4} className={cn(classes.col, 'col')}>
                        <Search isMenu />
                    </Col>
                </Row>
            </div>
            <div className={classes.centerSide}>
                <Row className={classes.row}>
                    <Col xs={12} md={4} className={cn(classes.col, 'col')}>
                        <ul className={cn(classes.firstList, 'textButton')}>
                            {links.map((l, i) =>
                                <li key={i}><Link to={l.path} onClick={handleClick}>{l.title}</Link></li>
                            )}
                        </ul>
                    </Col>
                    {new Array(2).fill('').map((_, i) =>
                        i === 0 ?
                            <Col key={i} xs={6} md={4} className={cn(classes.col, 'col')}>
                                <div className={cn(classes.title, 'textCaps')}>
                                    <Link to='/catalog' onClick={handleClick}>Каталог</Link>
                                </div>
                                <ul className='textButton'>
                                    {setMenuCol(i * perCol)}
                                </ul>
                            </Col> :
                            <Col key={i} xs={6} md={4} className={cn(classes.col, classes.lastCol, 'col')}>
                                <ul className='textButton'>
                                    {setMenuCol(i * perCol)}
                                </ul>
                            </Col>
                    )}
                </Row>
            </div>
            <div className={classes.lowerSide}>
                <Row className={classes.row}>
                    <Col sm={6} md={8} className={cn(classes.col, 'col')}>
                        <div className={classes.leftSide}>
                            <div className={cn(classes.email, 'textSmall')}>
                                <Mailto to={email}>{email}</Mailto>
                            </div>
                            <div className={cn(classes.phone, 'textSmall')}>
                                <Tel to={phone}>{phone}</Tel>
                            </div>
                            <div className={cn(classes.rules, 'textSmall')}>
                                <Link to='privacy_policy' onClick={handleClick}>Политика конфиденциальности</Link>
                            </div>
                            <div className={cn(classes.copyright, 'textButton')}>
                                <p>{`Sam Riseberg © ${new Date().getFullYear()}`}</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4} className={cn(classes.col, 'col')}>
                        <Social />
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default MobileMenu
