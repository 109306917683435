import React from 'react'
import { Link, useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import classes from './Breadcrumbs.module.sass'
import cn from 'classnames'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'

const Breadcrumbs = ({
	categories,
	compilation,
	hideFirst,
	max,
	hideLast,
	isProductCard
}) => {

	const { pathname } = useLocation()
	const { url } = useRouteMatch()
	const history = useHistory()

	const locale = [
		{ en: 'catalog', ru: 'каталог' },
		{ en: 'compilation', ru: 'подборки' },
		{ en: 'designers', ru: 'дизайнерам' },
		{ en: 'about', ru: 'об авторе' },
		{ en: 'checkout', ru: 'оформление заказа' },
		{ en: 'payment', ru: 'оплата' },
		{ en: 'delivery', ru: 'доставка' },
		{ en: 'warranty', ru: 'гарантия' },
		{ en: 'returns', ru: 'возврат' },
		{ en: 'howto', ru: 'Как оформить заказ' },
		{ en: 'privacy_policy', ru: 'Политика конфиденциальности' },
		{ en: 'user_agreement', ru: 'Пользовательское соглашение' },
		{ en: 'account', ru: 'Личный кабинет' },
		{ en: 'search', ru: 'Результаты поиска' }
	]

	let pathnames = pathname.split('/').filter(x => x)
	hideLast && (pathnames = pathnames.slice(0, pathnames.length - 1))
	max && (pathnames = pathnames.slice(0, max))
	pathnames.forEach(path => {
		if (path === 'modal') {
			pathnames = pathnames.slice(0, pathnames.indexOf(path))
		}
	})

	return (
		<Container>
			<ul className={cn(classes.breadcrumbs, 'textCaps')}>
				{hideFirst || (
					pathnames.length > 0 ? (
						<li><Link to='/'>Главная</Link></li>
					) : (
						<li>Главная</li>
					)
				)}
				{pathnames
					.filter(f => f !== 'all')
					.map((name, index) => {
						locale && locale.forEach(el => {
							if (el.en === name)
								name = el.ru
						})

						url.includes('/catalog') && categories.forEach(c => {
							if (c.path === name) {
								name = c.name
							}
							else {
								c.subcategories && c.subcategories.forEach(s => {
									if (s.path === name) {
										name = s.name
									}
								})
							}
						})

						url === '/compilation' && compilation.forEach(c => {
							if (c.path === name) {
								name = c.title
							}
						})

						const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
						const isLast = index === pathnames.length - 1

						return isProductCard
							?
							<li key={name}>
								<Link to={{
									pathname: routeTo,
									fromProduct: true
								}}>
									{name}
								</Link>
							</li>
							:
							isLast ?
								<li key={name}>{name}</li> :
								<li key={name}>
									<Link to='#' onClick={() => history.push(routeTo)}>
										{name}
									</Link>
								</li>
						// isLast
						// 	?
						// 	isProductCard ?
						// 		<li key={name}>
						// 			<Link to='#' onClick={() => history.push(routeTo)}>
						// 				{name}
						// 			</Link>
						// 		</li>
						// 		: <li key={name}>{name}</li>
						// 	:
						// 	<li key={name}>
						// 		<Link to='#' onClick={() => history.push(routeTo)}>
						// 			{name}
						// 		</Link>
						// 	</li>
					})}
			</ul>
		</Container>
	)
}

const mapStateToProps = (state) => {
	return {
		categories: state.catalog.categories,
		compilation: state.compilation.compilation
	}
}

export default connect(mapStateToProps)(Breadcrumbs)