import React from 'react'
import classes from './FiltersButton.module.sass'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const ClosedIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.3331 5.3333H15.8831C15.6498 4.04999 14.4832 3 13.0832 3C11.6832 3 10.5166 4.04999 10.2832 5.3333H2V6.49995H10.2832C10.5166 7.78327 11.6832 8.83325 13.0832 8.83325C14.4832 8.83325 15.6498 7.78327 15.8831 6.49995H18.3331V5.3333ZM13.0851 7.66736C12.1517 7.66736 11.3351 6.85071 11.3351 5.91739C11.3351 4.98407 12.1517 4.16741 13.0851 4.16741C14.0184 4.16741 14.835 4.98407 14.835 5.91739C14.835 6.85071 14.0184 7.66736 13.0851 7.66736ZM2 14.6666H4.44997C4.6833 15.9499 5.84995 16.9999 7.24993 16.9999C8.64991 16.9999 9.81656 15.9499 10.0499 14.6666H18.3331V13.4999H10.0499C9.81656 12.2166 8.64991 11.1666 7.24993 11.1666C5.84995 11.1666 4.6833 12.2166 4.44997 13.4999H2V14.6666ZM7.2501 12.3319C8.18342 12.3319 9.00008 13.1486 9.00008 14.0819C9.00008 15.0152 8.18342 15.8319 7.2501 15.8319C6.31678 15.8319 5.50013 15.0152 5.50013 14.0819C5.50013 13.1486 6.31678 12.3319 7.2501 12.3319Z" fill="#242422" />
            <circle cx="14" cy="6" r="6" fill="#FEFEFC" />
            <circle cx="14" cy="6" r="4" fill="#D9917B" />
        </svg>
    )
}

const OpenedIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#242422" />
        </svg>
    )
}

const FiltersButton = ({ onClick, filtersIsOpen }) => {

    const transitionClasses = {
        enter: classes.enter,
        enterDone: classes.enterDone,
        exitActive: classes.exitActive
    }

    return (
        <div
            role='button'
            className={classes.button}
            onClick={onClick}
        >
            <SwitchTransition mode='out-in'>
                <CSSTransition
                    key={filtersIsOpen}
                    timeout={50}
                    classNames={transitionClasses}
                >
                    {filtersIsOpen ?
                        <div className={classes.item}>
                            <p>Закрыть фильтр</p>
                            <OpenedIcon />
                        </div> :
                       <div className={classes.item}>
                            <p>Фильтр</p>
                            <ClosedIcon />
                        </div>
                    }
                </CSSTransition>
            </SwitchTransition>
        </div>
    )
}

export default FiltersButton
