import React from 'react'
import classes from './Social.module.sass'
import ExternalLink from '../ExternalLink'
import { connect } from 'react-redux'

const Social = ({ youtube, instagram, houzz, pinterest }) => {
    return (
        <div className={classes.Social}>
            <ExternalLink to={instagram}>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 3.5H12M4.5 0.5H10.5C12.7091 0.5 14.5 2.29086 14.5 4.5V10.5C14.5 12.7091 12.7091 14.5 10.5 14.5H4.5C2.29086 14.5 0.5 12.7091 0.5 10.5V4.5C0.5 2.29086 2.29086 0.5 4.5 0.5ZM7.5 10.5C5.84315 10.5 4.5 9.15685 4.5 7.5C4.5 5.84315 5.84315 4.5 7.5 4.5C9.15685 4.5 10.5 5.84315 10.5 7.5C10.5 9.15685 9.15685 10.5 7.5 10.5Z" stroke="#FEFEFC" />
                </svg>
            </ExternalLink>
            <ExternalLink to={houzz}>
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.2424 10.6679V10.1679H8.7424H5.21032H4.71032V10.6679V15.5H0.5V0.5H3.19754V3.73214V4.10532L3.5553 4.21148L13.5 7.16247V15.5H9.2424V10.6679Z" stroke="#FEFEFC" />
                </svg>
            </ExternalLink>
            <ExternalLink to={youtube}>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.61087 12.7387L1.5061 13.2276H1.5061L1.61087 12.7387ZM13.3891 12.7387L13.4939 13.2276L13.3891 12.7387ZM13.3891 2.26257L13.4939 1.77367V1.77367L13.3891 2.26257ZM1.61087 2.26257L1.71563 2.75147L1.61087 2.26257ZM6.5 5.50061L6.77735 5.08459C6.62392 4.9823 6.42665 4.97276 6.26407 5.05977C6.10149 5.14678 6 5.31621 6 5.50061H6.5ZM6.5 9.50061H6C6 9.68501 6.10149 9.85444 6.26407 9.94145C6.42665 10.0285 6.62392 10.0189 6.77735 9.91664L6.5 9.50061ZM9.5 7.50061L9.77735 7.91664C9.91645 7.8239 10 7.66779 10 7.50061C10 7.33344 9.91645 7.17732 9.77735 7.08459L9.5 7.50061ZM0 3.6367V11.3645H1V3.6367H0ZM15 11.3645V3.6367H14V11.3645H15ZM1.5061 13.2276C5.45719 14.0742 9.54281 14.0742 13.4939 13.2276L13.2844 12.2498C9.4714 13.0668 5.5286 13.0668 1.71563 12.2498L1.5061 13.2276ZM13.4939 1.77367C9.54281 0.927007 5.45719 0.927007 1.5061 1.77367L1.71563 2.75147C5.5286 1.93441 9.4714 1.93441 13.2844 2.75147L13.4939 1.77367ZM15 3.6367C15 2.73827 14.3724 1.96192 13.4939 1.77367L13.2844 2.75147C13.7018 2.84092 14 3.2098 14 3.6367H15ZM14 11.3645C14 11.7914 13.7018 12.1603 13.2844 12.2498L13.4939 13.2276C14.3724 13.0393 15 12.263 15 11.3645H14ZM0 11.3645C0 12.263 0.627614 13.0393 1.5061 13.2276L1.71563 12.2498C1.29821 12.1603 1 11.7914 1 11.3645H0ZM1 3.6367C1 3.2098 1.29821 2.84092 1.71563 2.75147L1.5061 1.77367C0.627614 1.96192 0 2.73827 0 3.6367H1ZM6 5.50061V9.50061H7V5.50061H6ZM6.77735 9.91664L9.77735 7.91664L9.22265 7.08459L6.22265 9.08459L6.77735 9.91664ZM9.77735 7.08459L6.77735 5.08459L6.22265 5.91664L9.22265 7.91664L9.77735 7.08459Z" fill="#FEFEFC" />
                </svg>
            </ExternalLink>
            <ExternalLink to={pinterest}>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 13.5L7.5 6.5M4.26389 9.5C3.78885 8.96924 3.5 8.26835 3.5 7.5V7C3.5 5.067 5.067 3.5 7 3.5H8C9.933 3.5 11.5 5.067 11.5 7V7.5C11.5 9.15685 10.1569 10.5 8.5 10.5C7.60421 10.5 6.80893 9.92679 6.52566 9.07697L6.5 9M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5Z" stroke="#FEFEFC" />
                </svg>
            </ExternalLink>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        youtube: state.staticContent.youtube,
        instagram: state.staticContent.instagram,
        houzz: state.staticContent.houzz,
        pinterest: state.staticContent.pinterest
    }
}

export default connect(mapStateToProps, null)(Social)
