import React from 'react'
import { withForm } from '../../../hoc/withForm'
import { Modal } from 'reactstrap'
import image from '../../../assets/img/order-request.png'
import { Link } from 'react-router-dom'
import Mailto from '../../common/Mailto'

const OrderRequestModal = ({
    modal: { id, title, open },
    modalRef,
    callModal
}) => {
    return (
        <Modal
            isOpen={open}
            modalClassName='orderRequestModalWrapper'
            contentClassName='commonModal orderRequestModal'
            centered
        >
            <div className='wrapper' ref={modalRef}>
                <div className='inner'>
                    <div className='closeBtn' onClick={() => callModal(id)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                        </svg>
                    </div>
                    <h2>{title}</h2>
                    <div className='steps'>
                        <p>Для этого нужно выполнить несколько простых шагов:</p>
                        <div className='step'>
                            <h2>1</h2>
                            <div className='content'>
                                <div className='image'>
                                    <img src={image} alt='' className='fitContain' />
                                </div>
                                <p>Скачайте в <Link
                                    to='/account'
                                    onClick={() => callModal(id)}
                                >личном кабинете</Link> PDF файлы со Списками, которые вы хотите оплатить.</p>
                            </div>
                        </div>
                        <div className='step'>
                            <h2>2</h2>
                            <div className='content'>
                                <p>Оправьте их вместе с вашими реквизитами на почту <Mailto to='order@shop.riseberg.com'>
                                    order@shop.riseberg.com
                                </Mailto>.</p>
                            </div>
                        </div>
                        <div className='step'>
                            <h2>3</h2>
                            <div className='content'>
                                <p>Мы пришлем счёт в ответном письме, в течении часа.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withForm(OrderRequestModal)
