import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import classes from './Footer.module.sass'
import cn from 'classnames'
import Mailto from '../Mailto'
import Tel from '../Tel'
import { Link } from 'react-router-dom'
import AccountLink from '../Header/AccountLink/AccountLink'
import ExternalLink from '../ExternalLink'
import Social from '../Social/Social'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withBreakpoints } from '../../../hoc/withBreakpoints'

const Footer = ({ email, phone, T }) => {
	return (
		<footer className={classes.Footer}>
			<Container>
				<div className={classes.upperSide}>
					<Row>
						<Col md={4} xl={6} className={cn(classes.col, 'col')}>
							<h1>Контакты</h1>
							<div className={cn(classes.textCol, 'textButton')}>
								<Mailto to={email}>{email}</Mailto>
								<Tel to={phone}>{phone}</Tel>
								<p>Интерьерные решения</p>
								<ExternalLink to='https://riseberg.ru/'>
									Sam Riseberg Architect
								</ExternalLink>
							</div>
						</Col>
						<Col md={4} xl={3} className={cn(classes.col, 'col d-none d-md-block')}>
							<AccountLink dark />
							<ul className="textButton">
								<li><Link to='/catalog' onClick={() => window.scrollTo(0, 0)}>Каталог</Link></li>
								<li><Link to='/compilation'>Подборки</Link></li>
								<li><Link to='/designers'>Дизайнерам</Link></li>
								<li><Link to='/about'>Об авторе</Link></li>
								<li><Link to='/catalog/sale' onClick={() => window.scrollTo(0, 0)}>Sale</Link></li>
							</ul>
						</Col>
						<Col md={4} xl={3} className={cn(classes.col, 'col d-none d-md-block')}>
							<ul className="textButton">
								<li><Link to='/payment'>Оплата</Link></li>
								<li><Link to='/delivery'>Доставка</Link></li>
								<li><Link to='/warranty'>Гарантия</Link></li>
								<li><Link to='/returns'>Возврат</Link></li>
								<li><Link to='/howto'>Как оформить заказ</Link></li>
							</ul>
						</Col>
					</Row>
				</div>
				<div className={classes.lowerSide}>
					<Row className='h-100'>
						<Col xs={12} md={4} xl={6} className='col align-self-start'>
							<div className={classes.leftWrapper}>
								<div className={cn(classes.copyright, 'textButton')}>
									<p>{`Sam Riseberg © ${new Date().getFullYear()}`}</p>
								</div>
								{!T &&
									<div className={classes.socialWrapper}>
										<Social />
									</div>
								}
							</div>
						</Col>
						<Col xs={12} md={4} xl={3} className='col'>
							<div className={classes.rules}>
								{T &&
									<div className={classes.socialWrapper}>
										<Social />
									</div>
								}
								<Link to='/privacy_policy'>Политика конфиденциальности</Link>
								<Link to='/user_agreement'>Пользовательское соглашение</Link>
							</div>
						</Col>
						<Col xs={12} md={4} xl={3} className='col'>
							<div className={classes.requisites}>
								<p>195197, Санкт-Петербург, пр-кт Маршала Блюхера, д. 9, <br /> корп. 1, стр. 1, кв. 237</p>
								<p>ИП САМАРИН СЕМЕН ВЛАДИМИРОВИЧ</p>
								<p>ИНН 254010579448<span>ОГРН 319784700300586</span></p>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</footer>
	)
}

const mapStateToProps = state => {
	return {
		email: state.staticContent.email,
		phone: state.staticContent.phone
	}
}

export default compose(
	withBreakpoints,
	connect(mapStateToProps)
)(Footer)
