import React, {useRef} from 'react'
import { Modal } from 'reactstrap'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'
import { withForm } from '../../../hoc/withForm'
import ReCAPTCHA from 'react-google-recaptcha'

const SendPdfModal = ({
    modal: { id, title, open },
    useForm: { handleSubmit, register, errors },
    modalRef,
    callModal,
    list,
    sendPdf 
}) => { 
    const reRef = useRef()
    const onSubmit = async d => {
        const token = await reRef.current.executeAsync()
        reRef.current.reset()
        sendPdf(list, d.email, token, d.no_discount)
    }
    return (
        <Modal
            isOpen={open}
            contentClassName='commonModal'
            centered
        >
            <div className='wrapper' ref={modalRef}>
                <div className='inner'>
                    <div className='closeBtn' onClick={() => callModal(id)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                        </svg>
                    </div>
                    <h3>{title}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITEKEY}
                            size='invisible'
                            ref={reRef}
                        />
                        <div className='formGroup'>
                            <input
                                type='text'
                                name='email'
                                placeholder='Адрес почты'
                                ref={register({
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                })}
                            />
                            <span className='error textSmall'>
                                {errors.email?.type === 'required' && <p>обязательное поле</p>}
                                {errors.email?.type === 'pattern' && <p>введите email</p>}
                            </span>
                        </div>
                        <div className='btnContainer'>
                            <BorderButton name='Отправить' type='submit' />
                        </div>
                        <div className='rules altRules'>
                            <label>
                                <input
                                    type='checkbox'
                                    name='no_discount'
                                    ref={register}
                                />
                                <p>Сохранить с ценами без указания персональной скидки</p>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default withForm(SendPdfModal)
