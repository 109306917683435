import React from 'react'
import classes from './NewListButton.module.sass'
import cn from 'classnames'
import { callModal } from '../../../../redux/modalsReducer'
import { connect } from 'react-redux'

const NewListButton = ({ account, lists, maxLists, callModal }) => {
    const handleClick = () => {
        account ? callModal('add_list') : callModal('enter')
    }
    return (
        <button
            onClick={handleClick}
            className={cn(classes.newList, lists.length >= maxLists && classes.disabled)}
        >
            <p>Новый список</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7.44446" y="3" width="1.11111" height="10" fill="#D9917B" />
                <rect x="13" y="7.44446" width="1.11111" height="10" transform="rotate(90 13 7.44446)" fill="#D9917B" />
            </svg>
        </button>
    )
}

const mapStateToProps = state => {
    return {
        lists: state.account.lists,
        maxLists: state.account.maxLists
    }
}

export default connect(mapStateToProps, { callModal })(NewListButton)
