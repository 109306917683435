import { catalogAPI, listsAPI } from '../api/api'

const SET_CATEGORIES = 'catalog/SET_CATEGORIES'
const SET_CATALOG_TITLE = 'catalog/SET_CATALOG_TITLE'
const SET_ACTIVE_VALUE = 'catalog/SET_ACTIVE_VALUE'
const CLEAR_PRODUCTS = 'catalog/CLEAR_PRODUCTS'
const SET_TOTAL_COUNT = 'catalog/SET_TOTAL_COUNT'
const SET_PRODUCTS = 'catalog/SET_PRODUCTS'
const SET_BANNER = 'catalog/SET_BANNER'
const CLEAR_PRODUCT = 'catalog/CLEAR_PRODUCT'
const SET_PRODUCT = 'catalog/SET_PRODUCT'
const SET_EMPTY_PRODUCT = 'catalog/SET_EMPTY_PRODUCT'
const SET_PROPS = 'catalog/SET_PROPS'
const CLEAR_PROPS = 'catalog/CLEAR_PROPS'
const SET_FILTERED_COUNT = 'catalog/SET_FILTERED_COUNT'
const SET_SEARCH = 'catalog/SET_SEARCH'
const SET_LIST_STATUS = 'catalog/SET_LIST_STATUS'
const SET_PRODUCT_IN_CART = 'catalog/SET_PRODUCT_IN_CART'

const initialState = {
	categories: [
		{ id: 'all', path: null, name: 'Весь каталог' },
		{ id: 'sale', name: 'Sale', path: 'sale' }
	],
	products: [],
	product: {},
	otherProducts: [],
	orderList: {
		orders: [
			{ id: 1, value: 'price_desc', title: 'Сначала дорогие' },
			{ id: 2, value: 'price_asc', title: 'Сначала дешевые' },
			{ id: 3, value: 'created_at_desc', title: 'Сначала новинки' }
		],
		activeValue: 'created_at_desc'
	},
	filters: [],
	banners: [],
	productEmpty: false,
	hasGoods: false,
	totalCount: null,
	itemsCount: 21,
	catalogTitle: null,
	bannerAfterEach: 12,
	filteredCount: null,
	search: null
}

const catalogReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CATEGORIES: {
			return {
				...state,
				categories: state.categories.reduce((acc, c, i, arr) =>
					arr.length < 3 &&
						i === 0 ? [...acc, c, ...action.categories] : [...acc, c]
					, [])
			}
		}
		case SET_TOTAL_COUNT: {
			return {
				...state,
				totalCount: action.count
			}
		}
		case CLEAR_PRODUCTS: {
			return {
				...state,
				products: [],
				banners: []
			}
		}
		case SET_PRODUCTS: {
			return {
				...state,
				products: [...state.products, ...action.products]
			}
		}
		case SET_BANNER: {
			return {
				...state,
				banners: [...state.banners, action.banner]
			}
		}
		case CLEAR_PRODUCT: {
			return {
				...state,
				product: {},
				otherProducts: {}
			}
		}
		case SET_PRODUCT: {
			return {
				...state,
				product: action.product,
				otherProducts: action.other
			}
		}
		case SET_EMPTY_PRODUCT: {
			return {
				...state,
				product: {
					empty: true
				}
			}
		}
		case SET_PRODUCT_IN_CART: {
			return {
				...state,
				otherProducts: state.otherProducts.map(p =>
					action.id === p.id ?
						{
							...p,
							inCart: action.cart
						} :
						{ ...p }

				)
			}
		}
		case SET_CATALOG_TITLE: {

			const getTitle = () => {
				let title
				if (action.category) {
					state.categories.forEach((cat, i) => {
						if (action.subcategory) {
							cat.subcategories && cat.subcategories.forEach(sub => {
								if (sub.path === action.subcategory) {
									title = sub.name
								}
							})
						} else {
							if (cat.path === action.category) {
								title = cat.name
							} else {
								if (action.t === 'search' && i === 0) {
									title = 'Результаты поиска'
								}
							}
						}
					})
				} else {
					if (action.t === 'sale') {
						title = 'Sale'
					} else {
						title = 'Весь каталог'
					}
				}
				return title
			}
			
			return {
				...state,
				catalogTitle: getTitle()
				// action.category ?
				// 	state.categories.map((cat, i) =>
				// 		action.subcategory
				// 			?
				// 			cat.subcategories &&
				// 			cat.subcategories.filter(el => el.path === action.subcategory).map(s => s.name)
				// 			:
				// 			cat.path === action.category
				// 				?
				// 				cat.name
				// 				:
				// 				action.t === 'search' &&
				// 				i === 0 && 'Результаты поиска'
				// 	)
				// 	: (action.t === 'sale' && 'Sale') || 'Весь каталог'
			}
		}
		case SET_ACTIVE_VALUE: {
			return {
				...state,
				orderList: {
					...state.orderList,
					activeValue: action.value
				}
			}
		}
		case SET_PROPS: {
			let count = 0
			return {
				...state,
				filters: action.props.map((prop, _, arr) => {
					const isSize = arr.some(prop =>
						prop.id === 'width' || prop.id === 'height' || prop.id === 'length'
					)
					if (prop.id === 'width' || prop.id === 'height' || prop.id === 'length') {
						return {
							id: prop.id,
							title: prop.name,
							name: prop.id,
							group: action.props.length > 3 ? 2 : 1,
							kind: 'range',
							default: 1,
							min: 1,
							max: 1000,
							step: 1,
							suffix: 'мм.'
						}
					} else if (prop.id === 'color') {
						count += 1
						return {
							id: prop.id,
							title: prop.name,
							name: prop.id,
							group: isSize ? action.props.length > 3 ?
								count <= 3 ? 1 : 3 : 2 :
								count <= 3 ? 1 : (count > 3 && count <= 6) ? 2 : 3,
							kind: 'color',
							items: prop.possible_values
						}
					} else {
						count += 1
						return {
							id: prop.id,
							title: prop.name,
							group: action.props.length > 3 ? count < 3 ? 1 : 3 : 2,
							kind: 'checkbox',
							items: prop.possible_values
						}
					}
				})
			}
		}
		case CLEAR_PROPS: {
			return {
				...state,
				filters: []
			}
		}
		case SET_FILTERED_COUNT: {
			return {
				...state,
				filteredCount: action.count
			}
		}
		case SET_SEARCH: {
			return {
				...state,
				search: action.search || null
			}
		}
		case SET_LIST_STATUS: {
			return {
				...state,
				hasGoods: action.status
			}
		}
		default:
			return state
	}
}

export const setCategories = categories => ({ type: SET_CATEGORIES, categories })
export const setCatalogTitle = (category, subcategory, t) =>
	({ type: SET_CATALOG_TITLE, category, subcategory, t })
export const setActiveValue = value => ({ type: SET_ACTIVE_VALUE, value })
export const clearProducts = () => ({ type: CLEAR_PRODUCTS })
export const setTotalCount = count => ({ type: SET_TOTAL_COUNT, count })
export const setProducts = products => ({ type: SET_PRODUCTS, products })
export const clearProduct = () => ({ type: CLEAR_PRODUCT })
export const setBanner = banner => ({ type: SET_BANNER, banner })
export const setProduct = (product, other) => ({ type: SET_PRODUCT, product, other })
export const setEmptyProduct = () => ({ type: SET_EMPTY_PRODUCT })
export const setProps = props => ({ type: SET_PROPS, props })
export const clearProps = () => ({ type: CLEAR_PROPS })
export const setFilteredCount = count => ({ type: SET_FILTERED_COUNT, count })
export const setSearch = search => ({ type: SET_SEARCH, search })
export const setListStatus = status => ({ type: SET_LIST_STATUS, status })
const setProductInCart = (id, cart) => ({ type: SET_PRODUCT_IN_CART, id, cart })

export const getCategories = () => async dispatch => {
	try {
		const data = await catalogAPI.getCategories()
		dispatch(setCategories(data.categories))
	} catch (error) {
		console.log(error)
	}
}

export const getBanners = (count, offset, banners) => dispatch => {
	const bannersCount = banners.length
	const i = offset / count
	bannersCount - 1 >= i &&
		dispatch(setBanner(banners[i]))
}

export const getListStatus = () => async dispatch => {
	try {
		const data = await catalogAPI.getListStatus()
		dispatch(setListStatus(data.has_goods))
	} catch (error) {
		console.log(error)
	}
}

const getProductsInner = (type, count, offset, sort, props, c, s) => async dispatch => {
	const t = props && props[0] && props[0].type
	const d = props && props[0] && props[0].data
	try {
		let data
		switch (type) {
			case 'all':
				data = await catalogAPI.getAllProducts(count, offset, sort, d)
				break
			case 'sale':
				data = await catalogAPI.getSaleProducts(count, offset, sort, d)
				break
			case 'search':
				data = await catalogAPI.getSearch(count, offset, sort, d, c)
				break
			case 'cat':
				data = await catalogAPI.getCategoryProducts(count, offset, sort, d, c)
				break
			case 'sub':
				data = await catalogAPI.getSubcategoryProducts(count, offset, sort, d, c, s)
				break
			default: break
		}
		if ((t !== 'changes' && !d && !offset) || (t !== 'changes' && !offset)) {
			dispatch(clearProducts())
		}
		dispatch(setCatalogTitle(c, s, type))
		t !== 'changes' && dispatch(setProducts(data.goods))
		type !== 'search' && dispatch(getBanners(count, offset, data.suggested_compilations))
		dispatch(setTotalCount(data.total_count))
	} catch (error) {
		console.log(error)
	}
}

export const getAllProducts = (count, offset, sort, props) =>
	getProductsInner('all', count, offset, sort, props)
export const getSaleProducts = (count, offset, sort, props) =>
	getProductsInner('sale', count, offset, sort, props)
export const getSearch = (count, offset, sort, props, c) =>
	getProductsInner('search', count, offset, sort, props, c)
export const getCategoryProducts = (count, offset, sort, props, c) =>
	getProductsInner('cat', count, offset, sort, props, c)
export const getSubcategoryProducts = (count, offset, sort, props, c, s) =>
	getProductsInner('sub', count, offset, sort, props, c, s)

const getPropsInner = (type, c, s) => async dispatch => {
	try {
		let data
		switch (type) {
			case 'all':
				data = await catalogAPI.getAllProps()
				break
			case 'sale':
				data = await catalogAPI.getSaleProps()
				break
			case 'cat':
				data = await catalogAPI.getCategoryProps(c)
				break
			case 'sub':
				data = await catalogAPI.getSubcategoryProps(c, s)
				break
			default: break
		}
		dispatch(setProps(data.props))
	} catch (error) {
		console.log(error)
	}
}

export const getAllProps = () => getPropsInner('all')
export const getSaleProps = () => getPropsInner('sale')
export const getCategoryProps = (c) => getPropsInner('cat', c)
export const getSubcategoryProps = (c, s) => getPropsInner('sub', c, s)

export const getProduct = (c, s, g) => async dispatch => {
	dispatch(clearProduct())
	try {
		const data = await catalogAPI.getProduct(c, s, g)
		dispatch(setProduct(data.good, data.suggested_goods))
	} catch (error) {
		dispatch(setEmptyProduct())
		// console.log(error)
	}
}

export const getProductInCart = id => async dispatch => {
	try {
		const data = await listsAPI.isGoodInCart(id)
		dispatch(setProductInCart(id, data.in_cart))
	} catch (error) {
		// console.log(error)
	}
}

export default catalogReducer