import React, { useState, useRef, useEffect } from 'react'
import classes from './ProductColor.module.sass'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import ProductColorItem from './ProductColorItem'
import { updateGood } from '../../../redux/accountReducer'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'
import { TinyColor } from '@ctrl/tinycolor'

const ProductColor = ({
    colors,
    defaultColor,
    updateGood,
    size,
    productId,
    listId,
    entryId,
    quantity,
    isAccount,
    basket,
    productCard,
    accountProduct,
    catalog,
    onSubmit,
    index
}) => {
    const [currentColor, setCurrentColor] = useState(defaultColor || colors[0])

    const { register, handleSubmit } = useForm({
        defaultValues: {
            color: currentColor
        }
    })
    const [dropdown, setDropdown] = useState(false)
    const dropdownRef = useRef()

    const moreThenOne = colors.length > 1

    useEffect(() => {
        const handleClickOutside = e => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdown(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropdownRef])

    const getCurrentColor = color => {
        productCard && setCurrentColor(color)
        accountProduct &&
            updateGood(listId, entryId, quantity, color)
    }

    const isBorder = c => {
        const color = new TinyColor(c)
        return color.isLight()
    }

    const dropdownStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    }

    const colorsType = () => {
        if (catalog) {
            return colors.map((c, i) =>
                <div
                    key={i}
                    className={cn(isBorder(c) && classes.border, classes.color)}
                    style={{ background: c, width: size, height: size }}
                ></div>
            )
        } else {
            return <div className={cn(
                accountProduct && classes.accountProduct,
                isAccount && classes.isAccount,
                basket && classes.basket
            )}>
                <div className={classes.colorsContainer}>
                    <div 
                        className={cn(classes.current, !moreThenOne && classes.one)} 
                        onClick={() => setDropdown(true)}
                    >
                        <div
                            className={cn(
                                classes.currentColor,
                                isBorder(defaultColor) && classes.border
                            )}
                            style={{ background: defaultColor }}
                        ></div>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 5.75L14.375 10.125L13.5 11L10 7.5L6.50001 11L5.62501 10.125L10 5.75Z" fill="#242422" />
                        </svg>
                    </div>
                    {(productCard || (moreThenOne && accountProduct)) &&
                        <Transition
                            in={isAccount ? dropdown : basket ? dropdown : true}
                            timeout={300}
                            mountOnEnter
                            unmountOnExit
                        >
                            {state => (
                                <form
                                    className={classes.colorForm}
                                    style={{ ...dropdownStyles[state] }}
                                    ref={dropdownRef}
                                >
                                    <svg onClick={() => setDropdown(false)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8 11L3 5.99999L3.7 5.29999L8 9.59999L12.3 5.29999L13 5.99999L8 11Z" fill="#242422" />
                                    </svg>
                                    {colors.map((c, i) =>
                                        <ProductColorItem
                                            key={i}
                                            index={index}
                                            register={register}
                                            size={size}
                                            productId={productId}
                                            id={listId}
                                            color={c}
                                            border={isBorder(c)}
                                            getCurrentColor={getCurrentColor}
                                            checked={productCard ? c === currentColor : c === defaultColor}
                                            handleSubmit={handleSubmit}
                                            onSubmit={onSubmit}
                                            productCard={productCard}
                                        />
                                    )}
                                </form>
                            )}
                        </Transition>
                    }
                </div>
            </div>
        }
    }

    return colorsType()
}

export default connect(null, { updateGood })(ProductColor)
