import React from 'react'

const FormNameField = ({ name, register, errors, placeholder, third, altValidation }) => {

    const reg = register({
        required: true,
        pattern: altValidation || /^[A-zа-яА-ЯЁё]+(?:\s[A-zа-яА-ЯЁё]+)+$/
    })

    return (
        <>
            <input
                type='text'
                name={name}
                placeholder={placeholder || 'Имя и фамилия'}
                ref={name !== 'name' ?
                    third === 0 ? null : reg :
                    reg}
            />
            <span className='error textSmall'>
                {name !== 'name' ?
                    <>
                        {errors.thirdName?.type === 'required' && <p>обязательное поле</p>}
                        {errors.thirdName?.type === 'pattern' && <p>введите имя и фамилию</p>}
                    </> :
                    <>
                        {errors.name?.type === 'required' && <p>обязательное поле</p>}
                        {errors.name?.type === 'pattern' && <p>введите имя и фамилию</p>}
                    </>
                }
            </span>
        </>
    )
}

export default FormNameField
