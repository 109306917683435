import React, { useRef, useState, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import classes from './FilterSelect.module.sass'
import cn from 'classnames'
import FilterInput from './FilterInput'

const FilterSelect = ({ el, register, reset, getChangeEvent }) => {
    const listRef = useRef()
    const closeRef = useRef()
    const [open, setOpen] = useState(0)
    const [clear, setClear] = useState(false)
    const [isChecked, setIsChecked] = useState([])
    const handleClickOutside = (e) => {
        if (listRef.current && !listRef.current.contains(e.target)) {
            setOpen(0)
        }
    }
    document.addEventListener('mousedown', handleClickOutside)

    const handleClear = () => {
        setClear(true)
        setTimeout(() => setClear(false), 100)
        setIsChecked([])
    }

    const isElChecked = (bool, id) => {
        if (bool && !isChecked.some(el => el === id)) {
            setIsChecked(prev => [...prev, id])
        }
        if (!bool && isChecked.some(el => el === id)) {
            const index = isChecked.indexOf(id)
            index > -1 && isChecked.splice(index, 1)
        }
    }

    useEffect(() => {
        if (reset) {
            setIsChecked([])
            setClear(false)
        }
    }, [reset])

    const titleClick = (e) => {
        if (closeRef.current && !closeRef.current.contains(e.target)) {
            open === 0 && setOpen('auto')
        }
    }

    const animationClasses = {
        animating: classes.animating,
        static: classes.static
    }

    return (
        <div className={classes.wrapper}>
            <div className={cn(classes.filter, open === 'auto' && classes.active)} ref={listRef}>
                <div onClick={(e) => titleClick(e)} className={classes.title}>
                    <div className={classes.titleWrapper}>
                        <p>{el.title}</p>
                        <div
                            className={cn(classes.clear, isChecked.length > 0 ? classes.active : null)}
                            onClick={handleClear}
                            ref={closeRef}
                        >
                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.54236 3.9085L0 7.511L0.434448 7.95282L3.97681 4.35032L7.56555 8L8 7.55818L4.41125 3.9085L7.82005 0.441824L7.3856 0L3.97681 3.46667L0.614401 0.0471776L0.179953 0.489002L3.54236 3.9085Z" fill="#FEFEFC" />
                            </svg>
                        </div>
                    </div>
                    <svg
                        onClick={() => open === 'auto' && setOpen(0)}
                        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                    </svg>
                </div>
                <AnimateHeight
                    duration={300}
                    height={open}
                    className={classes.heightContainer}
                    animationStateClasses={animationClasses}
                >
                    <ul>
                        {el.items && el.items.map((item, i) =>
                            <FilterInput
                                key={i}
                                i={i}
                                propId={el.id}
                                el={item}
                                register={register}
                                clear={clear}
                                reset={reset}
                                isElChecked={isElChecked} 
                                getChangeEvent={getChangeEvent}
                            />
                        )}
                    </ul>
                </AnimateHeight>
            </div>
        </div>
    )
}

export default FilterSelect
