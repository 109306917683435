import React from 'react'

const ArrowRightWhite = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.04 7.25L14.04 8.25L2.00004 8.25L2.00004 7.25L14.04 7.25Z" fill="#FEFEFC" />
            <path d="M14.5422 7.75L13.538 7.75L13.0358 7.25006L11.0272 5.24996L12.0315 5.25L14.5422 7.75Z" fill="#FEFEFC" />
            <path d="M14.5422 7.75L13.538 7.75L13.0358 8.25L11.0273 10.25L12.0316 10.25L14.5422 7.75Z" fill="#FEFEFC" />
        </svg>
    )
}

export default ArrowRightWhite
