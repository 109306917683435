import React, { useRef } from 'react'
import classes from './HomeSlider.module.sass'
import cn from 'classnames'
import Point from '../../common/Points/Point'
import SliderArrows from '../../common/svg/SliderArrows'
import { withBreakpoints } from '../../../hoc/withBreakpoints'

import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Navigation, Pagination, EffectCoverflow])

const HomeSlider = ({ compilation, pM }) => {

    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const containerRef = useRef()

    return (
        <div className={classes.sliderContainer} ref={containerRef}>
            <div className={classes.navigation}>
                <SliderArrows
                    customClasses={classes}
                    prevRef={prevRef}
                    nextRef={nextRef}
                />
                <div className={classes.pagination}></div>
            </div>
            <Swiper
                className={classes.slider}
                effect='coverflow'
                coverflowEffect={{
                    rotate: 1,
                    stretch: pM ? 20 : 61,
                    depth: 18,
                    modifier: 16,
                    slideShadows: false
                }}
                slideToClickedSlide
                allowTouchMove={pM}
                speed={800}
                loop
                loopedSlides={compilation.length}
                navigation={{
                    prevEl: prevRef.current ? prevRef.current : undefined,
                    nextEl: nextRef.current ? nextRef.current : undefined
                }}
                pagination={{
                    el: '.' + classes.pagination,
                    type: 'fraction',
                    renderFraction: function (currentClass, totalClass) {
                        return '<span class="' + cn(currentClass, classes.bullet) + '"></span>' +
                            '/' +
                            '<span class="' + cn(totalClass, classes.bullet) + '"></span>'
                    }
                }}
                onInit={swiper => {
                    swiper.params.navigation.prevEl = prevRef.current
                    swiper.params.navigation.nextEl = nextRef.current
                    swiper.navigation.update()
                }}
            >
                {compilation.map((el, i) =>
                    <SwiperSlide key={i}>
                        {({ isActive }) => (
                            <div
                                className={cn(
                                    classes.imgContainer,
                                    isActive ? classes.active : classes.notActive,
                                    'imgContainer')}
                            >
                                <div className={classes.wrapper}>
                                    <img src={process.env.REACT_APP_BACKEND_URL + el.photo} alt={el.title} className='fitCover' />
                                    {el.goods && el.goods.map(item =>
                                        item.coordinates &&
                                        <Point
                                            key={item.good.id}
                                            title={item.good.title}
                                            category={item.good.category_path}
                                            subcategory={item.good.subcategory_path}
                                            path={item.good.good_path}
                                            top={item.coordinates[1]}
                                            left={item.coordinates[0]}
                                            containerRef={containerRef}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default withBreakpoints(HomeSlider)
