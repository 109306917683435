import React, { useRef, useState, useEffect } from 'react'
import classes from './CombineButton.module.sass'
import cn from 'classnames'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import NoBorderButton from '../NoBorderButton/NoBorderButton'
import { callModal } from '../../../../redux/modalsReducer'

const CombineButton = ({ lists, callModal, checkout }) => {
    const { register, handleSubmit, reset } = useForm()
    const dropdownRef = useRef()
    const [dropdown, setDropdown] = useState(false)

    const onSubmit = data => {
        if (data.list.length > 1) {
            callModal('combine', data.list)
            setDropdown(false)
            reset()
        }
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdown(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropdownRef])

    const dropdownClasses = {
        enter: classes.enter,
        enterDone: classes.enterDone,
        exit: classes.exit,
        exitDone: classes.exitDone
    }

    return (
        <div className={cn(
            classes.combineContainer, 
            checkout && classes.checkout
        )} ref={dropdownRef}>
            <button
                onClick={() => setDropdown(prev => !prev)}
                className={cn(
                    classes.combine, 
                    lists.length < 2 && classes.disabled
                )}
            >
                <p>{checkout ? 'Объедините' : 'Объединить для оплаты'}
                    {checkout && <span>ваши списки, чтобы оплатить их вместе</span>}</p>
                <SwitchTransition>
                    <CSSTransition
                        key={dropdown}
                        timeout={0}
                        classNames={dropdownClasses}
                        mountOnEnter
                        unmountOnExit
                    >
                        {dropdown ?
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.00005 10.6L2.50005 7.10001L1.80005 7.80001L5.30005 11.3L6.00005 12L13.1 4.90001L12.4 4.20001L6.00005 10.6Z" fill="#27AE60" />
                            </svg>
                            :
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 10.09L5.205 8.295L4.5 9L7 11.5L11.5 7L10.795 6.29L7 10.09ZM12.5 2.5H11V2C11 1.44772 10.5523 1 10 1H6C5.44772 1 5 1.44772 5 2V2.5H3.5C2.94772 2.5 2.5 2.94772 2.5 3.5V14C2.5 14.5523 2.94772 15 3.5 15H12.5C13.0523 15 13.5 14.5523 13.5 14V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5ZM6 2H10V4H6V2ZM3.5 14H12.5V3.5H11V5H5V3.5H3.5V14Z" fill="#27AE60" />
                            </svg>
                        }
                    </CSSTransition>
                </SwitchTransition>
            </button>
            <CSSTransition
                in={dropdown}
                timeout={0}
                classNames={dropdownClasses}
                mountOnEnter
                unmountOnExit
            >
                <div className={classes.dropdown}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ul>
                            {lists.map(list =>
                                <li key={list.id}>
                                    <input type='checkbox' name='list' value={list.id} id={list.id} ref={register} />
                                    <label htmlFor={list.id}>{list.name}</label>
                                </li>
                            )}
                        </ul>
                        <NoBorderButton name='Объединить' type='submit' />
                    </form>
                </div>
            </CSSTransition>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lists: state.account.lists
    }
}

export default connect(mapStateToProps, { callModal })(CombineButton)
