import React, { useEffect } from 'react'
import classes from './HomeCompilationItem.module.sass'
import cn from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import NoBorderButton from '../../common/buttons/NoBorderButton/NoBorderButton'
import AddPlusButton from '../../common/buttons/AddButton/AddPlusButton'
import noPhoto from '../../../assets/img/No_Photo.png'

const HomeCompilationItem = ({
    p,
    addClick,
    isAuth,
    lists,
    goodInCart,
    addGoodToList,
    getInCart,
    compLocation
}) => {
    const history = useHistory()

    useEffect(() => {
        !isAuth && getInCart(!isAuth && p.id)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const addClickHandler = () => {
        const find = p.props && p.props.find(f => f.id === 'color')
        const color = find && find.length > 0 && find.value[0]
        if (isAuth) {
            addClick({
                img: p.photos[0].thumb,
                longtitle: p.title,
                id: p.id,
                color: color
            })
        } else {
            const reg = /^#[a-z0-9]{3}([a-z0-9]{3})?$/i
            const isHex = reg.test(color)
            addGoodToList(
                lists.length > 0 && lists[0].id,
                p.id,
                1,
                isHex && color,
                isAuth,
                compLocation
            )
        }
    }

    const oneClickHandler = () => history.replace(`/account/checkout/good_id_${p.id}`)

    return (
        <Col xs={12} md={4} lg={3} className={cn(classes.col, 'col')}>
            <div className={classes.wrapper}>
                <Link to={`/catalog/${p.category_path === undefined ? 'all' : p.category_path}/${p.subcategory_path === undefined ? 'all' : p.subcategory_path}/${p.good_path}`}>
                    <div className={cn(classes.imgContainer, 'imgContainer')}>
                        {p.photos.length > 0 ?
                            <img src={process.env.REACT_APP_BACKEND_URL + p.photos[0].thumb} alt={p.photos[0].alt} className='fitContain' />
                            :
                            <img src={noPhoto} alt='' className='fitContain' />
                        }
                    </div>
                    <div className={classes.textContainer}>
                        {p.designer_name &&
                            <p>{p.designer_name}</p>
                        }
                        <h3>{p.title}</h3>
                    </div>
                </Link>
                <div className={classes.btnContainer}>
                    <AddPlusButton
                        onClick={addClickHandler}
                        goodInCart={!isAuth && goodInCart}
                        isAuth={isAuth}
                    />
                    <NoBorderButton
                        name='Купить в один клик'
                        onClick={oneClickHandler}
                    />
                </div>
            </div>
        </Col>
    )
}

export default HomeCompilationItem
