import React, { useEffect } from 'react'
import classes from './ProductCard.module.sass'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import ProductCardBanner from './ProductCardBanner/ProductCardBanner'
import ProductCardContent from './ProductCardContent'
import cn from 'classnames'
import ProductCardCompilation from './ProductCardCompilation/ProductCardCompilation'
import { getProduct, getCategories, getProductInCart } from '../../../redux/catalogReducer';
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import ProductEmpty from './ProductEmpty'
import { withList } from '../../../hoc/withList'
import { compose } from 'redux'
import { withBreakpoints } from '../../../hoc/withBreakpoints'
import BasketLink from '../BasketLink/BasketLink'
import { isGoodInCart } from '../../../redux/accountReducer'

const ProductCard = ({
    product: productItem,
    getProduct,
    otherProducts,
    categories,
    getCategories,
    addOneClick,
    addClick,
    isAuth,
    addGoodToList,
    hasGoods,
    lists,
    getLists,
    goodInCart,
    isGoodInCart,
    getProductInCart,
    fetching,
    pT,
    pM
}) => {

    const { category, subcategory, product } = useParams()
    useEffect(() => {
        getProduct(category, subcategory, product)
    }, [getProduct, category, subcategory, product])

    return (
        <div className={cn(classes.productContainer, 'preFooterDec', 'fetched', fetching && 'loading')}>
            <Breadcrumbs hideFirst max={3} isProductCard/>
            {productItem && !productItem.empty ?
                <>
                    <ProductCardContent
                        product={productItem}
                        addClick={addClick}
                        addOneClick={addOneClick}
                        isAuth={isAuth}
                        addGoodToList={addGoodToList}
                        lists={lists}
                        getLists={getLists}
                        goodInCart={goodInCart}
                        isGoodInCart={isGoodInCart}
                        pM={pM}
                    />
                    {productItem.designer &&
                        <ProductCardBanner product={productItem} />
                    }
                    <ProductCardCompilation
                        otherProducts={otherProducts}
                        addClick={addClick}
                        addOneClick={addOneClick}
                        isAuth={isAuth}
                        lists={lists}
                        getProductInCart={getProductInCart}
                        addGoodToList={addGoodToList}
                        pT={pT}
                    />
                </> :
                <ProductEmpty
                    categories={categories}
                    getCategories={getCategories}
                    category={category}
                    subcategory={subcategory}
                />
            }
            <BasketLink
                enabled={!isAuth}
                notEmpty={hasGoods}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        categories: state.catalog.categories,
        product: state.catalog.product,
        otherProducts: state.catalog.otherProducts,
        hasGoods: state.catalog.hasGoods,
        goodInCart: state.account.goodInCart,
        fetching: state.staticContent.fetching
    }
}

export default compose(
    withBreakpoints,
    withList,
    connect(mapStateToProps, {
        getProduct,
        getCategories,
        isGoodInCart,
        getProductInCart
    })
)(ProductCard)
