import React from 'react'

export const PhoneField = ({ input, placeholder, type, meta: { touched, error, warning } }) => {

    const red = '#F33C42'
    const black = '#242422'

    const styles = {
        borderColor: touched && ((error && red)),
        color: touched && ((error && black))
    }

    return (
        <>
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                style={styles}
            />
            {touched && ((error && <span className='errorLabel'>{error}</span>))}
        </>
    )
}