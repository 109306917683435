import React from 'react'
import { Link } from 'react-router-dom'
import classes from './BigButton.module.sass'
import cn from 'classnames'
import { withBreakpoints } from '../../../../hoc/withBreakpoints'

const BigButton = ({ to, name, disabled, TM }) => {
    return (
        <Link to={to} className={cn(
            classes.button, 
            TM && classes.touch,
            {'disabledBtn': disabled}, 
            'textButton'
            )}>
            <p className={classes.text}>{name}</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0391 7.25L14.0391 8.25L1.99906 8.25L1.99906 7.25L14.0391 7.25Z" fill="#242422" />
                <path d="M14.5425 7.75049L13.5382 7.75049L13.0361 7.25055L11.0275 5.25045L12.0318 5.25049L14.5425 7.75049Z" fill="#242422" />
                <path d="M14.5425 7.74951L13.5382 7.74951L13.036 8.24952L11.0276 10.2495L12.0318 10.2495L14.5425 7.74951Z" fill="#242422" />
            </svg>
        </Link>
    )
}

export default withBreakpoints(BigButton)
