import React from 'react'
import classes from './Product.module.sass'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import ProductColor from './ProductColor'
import ProductPrice from './ProductPrice'
import image from '../../../assets/img/No_Photo.png'

const Product = ({
    good_path: path,
	designer_name,
    title,
    photos: gallery,
    old_price: oldPrice,
    price,
    props: params,
    category_path: category,
    subcategory_path: subcategory,
    smallProduct,
    catalogProduct
}) => {

    return (
        <Link
            to={`/catalog/${!category ? 'all' : category}/${!subcategory ? 'all' : subcategory}/${path}`}
            className={cn(classes.item, smallProduct && classes.small)}
        >
            <div className={classes.image}>
                {gallery.length > 0 ?
                    <img src={process.env.REACT_APP_BACKEND_URL + gallery[0].thumb} alt={gallery[0].alt} className='fitContain' />
                :
                <img src={image} alt='' className='fitCover' />
                }
            </div>
            <div className={classes.text}>
                <div className={cn(classes.params, 'textSmall')}>
                    {designer_name && <p>{designer_name}</p>}
                    <h3>{title}</h3>
                    {params && params.filter((_, i) => i < 2).map((el, i) =>
                        el.id === 'color' ?
                            <div key={i} className={cn(classes.param, classes.colors)}>
                                <ProductColor
                                    colors={el.value}
                                    size={8}
                                    catalog
                                />
                            </div>
                            :
                            <div key={i} className={classes.param}>
                                <p>
                                    {el.id === 'size' ?
                                        `${el.value[0]}x${el.value[1]}x${el.value[2]} ${el.value[3]}` :
                                        el.value}
                                </p>
                            </div>
                    )}
                </div>
                <ProductPrice
                    parentClasses={classes}
                    oldPrice={oldPrice}
                    price={price}
                    catalogProduct={catalogProduct}
                />
            </div>
        </Link>
    )
}

export default Product
