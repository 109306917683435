import React from 'react'
import { Container } from 'react-bootstrap'
import CompilationGrid from './CompilationGrid'
import classes from './Compilation.module.sass'
import BorderButton from '../common/buttons/BorderButton/BorderButton'

const CompilationGridContainer = ({ compilation, totalCount, handleClickMore }) => {
    
    const buttonDisabled = compilation.length < 1 || compilation.length === totalCount

    return (
        <Container>
            <div className={classes.grid}>
                <CompilationGrid compilation={compilation} />
                <div className={classes.btnContainer}>
                    <BorderButton 
                        onClick={handleClickMore}
                        name='Показать ещё'
                        disabled={buttonDisabled}
                        icon
                    />
                </div>
            </div>
        </Container>
    )
}

export default CompilationGridContainer
