import classes from './Sale.module.sass'
import React from 'react'

const Sale = () => {
    return (
        <div className={classes.Sale}>
            
        </div>
    )
}

export default Sale
