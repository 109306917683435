import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import { Container, Row, Col } from 'react-bootstrap'
import classes from './Catalog.module.sass'
import cn from 'classnames'
import {
    getAllProducts,
    getSaleProducts,
    getCategoryProducts,
    getSubcategoryProducts,
    getAllProps,
    getSaleProps,
    getCategoryProps,
    getSubcategoryProps,
    getSearch
} from '../../redux/catalogReducer'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import CatalogAside from './CatalogAside'
import FiltersButton from './Filters/FiltersButton'
import Filters from './Filters/Filters'
import CatalogOrder from './Filters/CatalogOrder'
import CatalogGrid from './CatalogGrid'
import BasketLink from './BasketLink/BasketLink'
import BorderButton from '../common/buttons/BorderButton/BorderButton'
import ToTop from '../common/ToTop/ToTop'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import { compose } from 'redux'
import AsideMobile from './AsideMobile'

const Catalog = ({
    products,
    banners,
    bannerAfterEach,
    catalogTitle,
    itemsCount,
    totalCount,
    getAllProducts,
    getSaleProducts,
    getSearch,
    getCategoryProducts,
    getSubcategoryProducts,
    filters,
    getAllProps,
    getSaleProps,
    getCategoryProps,
    getSubcategoryProps,
    categories,
    search,
    isAuth,
    hasGoods,
    fetching,
    pM
}) => {

	// console.log(catalogTitle)

    const { category, subcategory } = useParams()
    const { register, watch } = useForm()
    const [openFilters, setOpenFilters] = useState(0)
    const [order, setOrder] = useState()
    const [openMobileAside, setOpenMobileAside] = useState(0)

    const isAllCatalog = !category && !subcategory

    const buttonDisabled = products.length < 1 || products.length === totalCount

    const getProducts = useCallback((count = itemsCount, offset = 0, sort, ...props) => {
        if (search && category === 'search') {
            getSearch(count, offset, sort, props, search)
        } else if (category === 'sale') {
            getSaleProducts(count, offset, sort, props)
        } else if (category && !subcategory) {
            getCategoryProducts(count, offset, sort, props, category)
        } else if (category && subcategory) {
            getSubcategoryProducts(count, offset, sort, props, category, subcategory)
        } else {
            getAllProducts(count, offset, sort, props)
        }
    }, [
        category,
        subcategory,
        getSaleProducts,
        getAllProducts,
        getCategoryProducts,
        getSubcategoryProducts,
        itemsCount,
        search,
        getSearch
    ])

    useEffect(() => {
        order && getProducts(itemsCount, 0, order)
    }, [category, subcategory, search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        openMobileAside === 0 &&
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [openMobileAside])

    const getValue = useCallback(name => setOrder(watch(name)), [watch])

    const handleClick = () => getProducts(itemsCount, products.length, order)

    const handleCloseFilters = () => {
        setOpenFilters(0)
        pM && setTimeout(() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            , 300)
    }

    return (
        <section className={cn(classes.catalogSection, 'preFooterDec')}>
            <Breadcrumbs max={2} />
            <Container className={classes.container}>
                <Row className={classes.row}>
                    <Col md={4} lg={3} className={cn(classes.col, 'd-none d-md-block')}>
                        <CatalogAside
                            categories={categories}
                            pM={pM}
                        />
                    </Col>
                    <Col xs={12} md={8} lg={9} className={classes.col}>
                        <div className={classes.catalog}>
                            <div className={classes.title}>
                                <h2>{catalogTitle}</h2>
                                {pM &&
                                    <AsideMobile
                                        categories={categories}
                                        openMobileAside={openMobileAside}
                                        setOpenMobileAside={setOpenMobileAside}
                                        pM={pM}
                                    />
                                }
                                <div className={cn(
                                    classes.buttons,
                                    openMobileAside === 'auto' && classes.closed
                                )}>
                                    {!isAllCatalog &&
                                        <FiltersButton
                                            onClick={() => setOpenFilters(prev => prev === 0 ? 'auto' : 0)}
                                            filtersIsOpen={openFilters === 'auto'}
                                        />
                                    }
                                    <CatalogOrder getValue={getValue} register={register} />
                                </div>
                            </div>
                            <AnimateHeight
                                duration={300}
                                height={openFilters}
                            >
                                <div className={classes.filters}>
                                    <Filters
                                        classActive={openFilters === 'auto'}
                                        closeFilters={handleCloseFilters}
                                        totalCount={totalCount}
                                        category={category}
                                        subcategory={subcategory}
                                        filters={filters}
                                        getProducts={getProducts}
                                        getAllProps={getAllProps}
                                        getSaleProps={getSaleProps}
                                        getCategoryProps={getCategoryProps}
                                        getSubcategoryProps={getSubcategoryProps}
                                        itemsCount={itemsCount}
                                        order={order}
                                        pM={pM}
                                    />
                                </div>
                            </AnimateHeight>
                            <div className={cn(
                                classes.catalogGrid,
                                openMobileAside === 'auto' && classes.closed,
                                'fetched',
                                fetching && 'loading'
                            )}>
                                <CatalogGrid
                                    products={products}
                                    banners={banners}
                                    bannerAfterEach={bannerAfterEach}
                                    pM={pM}
                                />
                                {products.length > 0 &&
                                    <div className={classes.button}>
                                        <BorderButton
                                            onClick={handleClick}
                                            name='Показать ещё'
                                            icon
                                            disabled={buttonDisabled}
                                        />
                                    </div>
                                }
                                {pM &&
                                    <div className={classes.toTopContainer}>
                                        <ToTop pM={pM} />
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <BasketLink
                enabled={!isAuth}
                notEmpty={hasGoods}
            />
            {pM || <ToTop />}
        </section>
    )
}

const mapStateToProps = state => {
    return {
        catalogTitle: state.catalog.catalogTitle,
        itemsCount: state.catalog.itemsCount,
        products: state.catalog.products,
        banners: state.catalog.banners,
        bannerAfterEach: state.catalog.bannerAfterEach,
        totalCount: state.catalog.totalCount,
        filters: state.catalog.filters,
        categories: state.catalog.categories,
        search: state.catalog.search,
        isAuth: state.account.isAuth,
        hasGoods: state.catalog.hasGoods,
        fetching: state.staticContent.fetching
    }
}

export default compose(
    withBreakpoints,
    connect(mapStateToProps, {
        getAllProducts,
        getSaleProducts,
        getSearch,
        getCategoryProducts,
        getSubcategoryProducts,
        getAllProps,
        getSaleProps,
        getCategoryProps,
        getSubcategoryProps
    })
)(Catalog)