import React, { useEffect, useCallback, useMemo } from 'react'
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import classes from './AccountContent.module.sass'
import cn from 'classnames'
import { Col, Row } from 'react-bootstrap'
import AccountLists from './AccountLists'
import AccountListsTransform from './AccountListsTransform'
import AccountProducts from './AccountProducts/AccountProducts'
import AccountTotal from './AccountProducts/AccountTotal'

const AccountContent = ({
    lists,
    paid,
    baseUrl,
    getLists,
    getOrders,
    getGoodsOfList,
    isAuth,
    redirect,
    setRedirect,
    discount,
    isAccount,
    renameSuccess,
    hidden,
    renameList,
    callModal,
    setRenameSuccess,
    getShippedPdf,
    fetching,
    pM,
    TM
}) => {
    const { params: { list, payment } } = useRouteMatch()
    const history = useHistory()
    const { state } = useLocation()

    const getUrl = useCallback(() => {
        (isAccount || !isAuth) ?
            lists.length > 0 && history.replace(`/account/lists/${lists.find((_, i) => i === 0).id}`) :
            paid.length > 0 &&
            history.replace(`${baseUrl}/${paid.find((_, i) => i === 0).id}`)
    }, [history, baseUrl, isAccount, lists, paid, isAuth])

    useEffect(() => {
        redirect && history.replace(`${baseUrl}/${redirect}`)
        return () => setRedirect()
    }, [redirect, setRedirect, baseUrl, history])

    useEffect(() => {
        !redirect && !state && !payment && (list || !isAccount) && history.replace(baseUrl)
    }, [isAuth, lists.length]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        lists.length < 1 && getLists()
    }, [isAuth, lists.length, getLists])

    useEffect(() => {
        isAuth && paid.length < 1 && getOrders()
    }, [paid.length, getOrders, isAuth])

    useEffect(() => {
        ((isAccount && lists.length > 0) || (!isAccount && paid.length > 0)) && !list && getUrl()
    }, [list, lists.length, getUrl, isAccount, paid.length])

    useEffect(() => {
        isAccount && !redirect && list && lists.length > 0 && getGoodsOfList(list)
    }, [isAccount, redirect, list, getGoodsOfList])  // eslint-disable-line react-hooks/exhaustive-deps


    const currentList = useMemo(() => {
        const current = isAccount ? lists : paid
        return current.length > 0 && current.filter(l => l.id === list)[0]
    }, [lists, list, isAccount, paid])

    const tracking = !isAccount && currentList && currentList.status
    const payment_url = !isAccount && currentList && currentList.payment_url

    const handleTabClick = e => {
        setTimeout(() => {
            e.target.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })
        }, 400)
    }

    return (
        <div className={classes.content}>
            <div className={classes.tabs}>
                <div className={classes.tab}>
                    <NavLink
                        to='/account/lists/'
                        activeClassName={cn(isAuth || classes.unactive, classes.active)}
                        onClick={e => pM && handleTabClick(e)}
                    >
                        <h3>Ваши списки</h3>
                        <div className={cn(classes.counter, 'textSmall')}>
                            <p>{isAuth ? lists.length : 1}</p>
                        </div>
                    </NavLink>
                </div>
                {isAuth &&
                    <div className={classes.tab}>
                        <NavLink
                            to='/account/orders/'
                            activeClassName={classes.active}
                            onClick={e => pM && handleTabClick(e)}
                        >
                            <h3>Ваши заказы</h3>
                            <div className={cn(classes.counter, 'textSmall')}>
                                <p>{paid.length}</p>
                            </div>
                        </NavLink>
                    </div>
                }
            </div>
            <Row>
                <Col xs={12} md={4} lg={3} className='col'>
                    <div className={classes.listsContainer}>
                        <AccountLists
                            lists={isAccount ? lists : paid}
                            basket={isAuth ? false : lists[0]}
                            baseUrl={baseUrl}
                            isAccount={isAccount}
                            isAuth={isAuth}
                            payment={payment}
                            renameSuccess={renameSuccess}
                            hidden={hidden}
                            renameList={renameList}
                            callModal={callModal}
                            setRenameSuccess={setRenameSuccess}
                            tracking={tracking}
                            TM={TM}
                            pM={pM}
                        />
                    </div>
                    {!pM && list &&
                        <AccountListsTransform
                            account={isAccount}
                            register={isAuth}
                        />
                    }
                </Col>
                <Col xs={12} md={8} lg={9} className={cn('col', 'fetched', fetching && 'loading')}>
                    <AccountProducts
                        isAccount={isAccount}
                        lists={isAccount ? lists : paid}
                        list={list}
                        baseUrl={baseUrl}
                        basket={!isAuth && lists[0]}
                        callModal={callModal}
                    />
                    <AccountTotal
                        isAccount={isAccount}
                        currentList={currentList}
                        tracking={tracking}
                        payment_url={payment_url}
                        list={list}
                        basket={!isAuth && lists[0]}
                        discount={discount}
                        getShippedPdf={getShippedPdf}
                    />
                </Col>
            </Row>
        </div>
    )
}



export default AccountContent