import React, { useEffect } from 'react'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import classes from './About.module.sass'
import cn from 'classnames'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import ExternalLink from '../common/ExternalLink'
import BorderButton from '../common/buttons/BorderButton/BorderButton'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import { compose } from 'redux'
import { useLocation } from 'react-router-dom'
import { getStatic } from '../../redux/staticReducer'
import BasketLink from '../Catalog/BasketLink/BasketLink'

const About = ({ staticContent, getStatic, isAuth, hasGoods, fetching, pM }) => {
    const { pathname } = useLocation()

    useEffect(() => {
        const path = pathname.replace(/\//g, '')
        getStatic(path)
    }, [pathname, getStatic])

    const aboutImg = () => {
        return <div className={classes.imgContainer}>
            <img src={process.env.REACT_APP_BACKEND_URL + staticContent.image} alt={staticContent.title} className='fitCover' />
        </div>
    }

    return (
        <>
            <Breadcrumbs />
            <section className={cn(classes.about, 'preFooterDec left', 'fetched', fetching && 'loading')}>
                <div className={classes.title}>
                    {pM || aboutImg()}
                    <Container>
                        <Row>
                            <Col xs={12} md={4} xl={3} className='col'>
                                <h1>{staticContent.title}</h1>
                                {pM && aboutImg()}
                                <h3>{staticContent.description}</h3>
                                <ExternalLink to='https://riseberg.ru/'>riseberg.ru</ExternalLink>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row className='justify-content-end'>
                        <Col xs={12} md={8} xl={6} className='col'>
                            <div className={classes.content}>
                                <div dangerouslySetInnerHTML={{ __html: staticContent.content }}></div>
                                {/* <div className={classes.imgContainer}>
                                    <img src={process.env.REACT_APP_BACKEND_URL + staticContent.decImg} alt='' className='fitContain' />
                                </div> */}
                                <div className={classes.button}>
                                    <BorderButton to='/compilation' name='Посмотреть подборки' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <BasketLink
                    enabled={!isAuth}
                    notEmpty={hasGoods}
                />
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        staticContent: state.staticContent.staticPage,
        isAuth: state.account.isAuth,
        hasGoods: state.catalog.hasGoods,
        fetching: state.staticContent.fetching
    }
}

export default compose(
    withBreakpoints,
    connect(mapStateToProps, { getStatic })
)(About)
