import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import classes from './Menu.module.sass'
import cn from 'classnames'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Menu = ({ slicedArr, arr, perCol, phone, email, search, D }) => {
    const dropdownRef = useRef()
    const sandwichRef = useRef()
    const [menuClosed, setMenuState] = useState(false)
    const [menuTop, setMenuTop] = useState()
    menuTop === 0 && setMenuTop(dropdownRef.current.clientHeight)

    const menuOpen = () => setMenuState(prev => !prev)

    useEffect(() => search && setMenuState(false), [search])

    const menuItem = el =>
        <li key={el.id}>
            <NavLink to={`/catalog/${el.path}`} onClick={() => setMenuState(false)}>
                {el.name}
            </NavLink>
        </li>

    const setMenuCol = (from = 0) => slicedArr.slice(from, from + perCol).map(menuItem)
    const setLastCol = () => arr.slice(-2, arr.length).map(menuItem)

    const handleClickOutside = (e) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            !sandwichRef.current.contains(e.target)) {
            setMenuState(false)
        }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return (
        <div className={classes.Menu}>
            <div
                id='sandwich'
                onClick={menuOpen}
                className={menuClosed ? 'active' : null}
                ref={sandwichRef}
            >
                <div className='swTopper'></div>
                <div className='swBottom'></div>
                <div className='swFooter'></div>
            </div>
            <div className={cn(classes.wrapper, menuClosed ? classes.active : '')}>
                <div
                    className={cn(classes.DropdownMenu, menuClosed ? classes.active : '')}
                    style={{ top: '-' + menuTop + 'px' }}
                    ref={dropdownRef}
                >
                    {D ?
                        <DesktopMenu
                            phone={phone}
                            email={email}
                            setMenuState={setMenuState}
                            setMenuCol={setMenuCol}
                            setLastCol={setLastCol}
                            perCol={perCol}
                        /> :
                        <MobileMenu
                            phone={phone}
                            email={email}
                            setMenuState={setMenuState}
                            setMenuCol={setMenuCol}
                            perCol={perCol}
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default Menu
