import React from 'react'
import { Link } from 'react-router-dom'
import classes from './BasketLink.module.sass'
import cn from 'classnames'

const BasketLink = ({ enabled, notEmpty }) => {
    return (
        <Link to='/account/lists/' className={cn(
            classes.basketLink,
            enabled && classes.enabled,
            notEmpty && classes.notEmpty)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 15C5.55228 15 6 14.5523 6 14C6 13.4477 5.55228 13 5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15Z" fill="#FEFEFC" />
                <path d="M12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15Z" fill="#FEFEFC" />
                <path d="M14 3.49986H2.91L2.5 1.39986C2.47662 1.28522 2.41379 1.18241 2.32243 1.10932C2.23107 1.03623 2.11697 0.997499 2 0.999858H0V1.99986H1.59L3.5 11.5999C3.52338 11.7145 3.58621 11.8173 3.67757 11.8904C3.76893 11.9635 3.88303 12.0022 4 11.9999H13V10.9999H4.41L4 8.99986H13C13.1156 9.00268 13.2286 8.96537 13.3197 8.89426C13.4109 8.82315 13.4746 8.72265 13.5 8.60986L14.5 4.10986C14.5168 4.03567 14.5164 3.95864 14.4989 3.88462C14.4814 3.81061 14.4472 3.74156 14.399 3.68274C14.3508 3.62392 14.2898 3.57687 14.2206 3.54518C14.1515 3.51349 14.076 3.49799 14 3.49986ZM12.6 7.99986H3.81L3.11 4.49986H13.375L12.6 7.99986Z" fill="#FEFEFC" />
            </svg>
        </Link>
    )
}

export default BasketLink
