import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import classes from './FilterRange.module.sass'

const FilterRange = ({ el, register, reset, getChangeEvent }) => {
    const [rangeVal, setRangeVal] = useState(el.default)

    const getWidth = value => value.toString().length * 8.5
    const [width, setWidth] = useState(getWidth(el.default))

    useEffect(() => getChangeEvent(), [rangeVal, getChangeEvent])

    useEffect(() => {
        setWidth(getWidth(rangeVal))
        if (rangeVal > el.max) {
            setRangeVal(el.max)
        }
        if (rangeVal < 1) {
            setRangeVal('')
        }
    }, [rangeVal, el.min, el.max])

    useEffect(() => {
        reset && setRangeVal(el.default)
    }, [reset, el.default])

    return (
        <div className={classes.filter}>
            <div className={classes.upper}>
                <div className={classes.title}>
                    <p>{el.title}</p>
                </div>
                <div className={classes.inputText}>
                    <span className={classes.inputWrapper} style={{ width: `${width}px` }}>
                        <NumberFormat
                            thousandSeparator={' '}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            value={rangeVal}
                            onChange={e => setRangeVal(+e.target.value.replace(/\D/g, ''))}
                            onBlur={e => +e.target.value.replace(/\D/g, '') < el.min && setRangeVal(el.min)}
                        />
                    </span>
                    <p>{el.suffix}</p>
                </div>
                {/* <input name={el.name} value={rangeVal} ref={register} readOnly /> */}
            </div>
            <div className={classes.range}>
                <input
                    // {...register(el.name)}
                    type='range'
                    name={el.name}
                    value={rangeVal}
                    min={el.min}
                    max={el.max}
                    step={el.step}
                    onChange={e => setRangeVal(+e.target.value)}
                    ref={register}
                />
            </div>
        </div>
    )
}

export default FilterRange
