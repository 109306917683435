import { homeAPI, listsAPI } from '../api/api'

const SET_HOME = 'home/SET_HOME'
const SET_HOME_IN_CART = 'home/SET_HOME_IN_CART'

const initialState = {
    compilation: [],
    goods: []
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HOME: {
            return {
                ...state,
                compilation: action.compilation,
                goods: action.goods
            }
        }
        case SET_HOME_IN_CART: {
            return {
                ...state,
                goods: state.goods.map(g =>
                    action.id === g.id ?
                        {
                            ...g,
                            inCart: action.cart
                        } :
                        { ...g }
                )
            }
        }
        default:
            return state
    }
}

export const setHome = (compilation, goods) => ({ type: SET_HOME, compilation, goods })
const setHomeInCart = (id, cart) => ({ type: SET_HOME_IN_CART, id, cart })

export const getHome = () => async dispatch => {
    try {
        const data = await homeAPI.getHome()
        dispatch(setHome(data.compilations, data.goods))
    } catch (error) {
        console.log(error)
    }
}

export const getHomeInCart = id => async dispatch => {
    try {
        const data = await listsAPI.isGoodInCart(id)
        dispatch(setHomeInCart(id, data.in_cart))
    } catch (error) {
        console.log(error)
    }
}

export default homeReducer