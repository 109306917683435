import React, { useState, useRef, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import classes from './CommonSelect.module.sass'
import cn from 'classnames'

const CommonSelect = props => {
    const { 
        name, 
        options, 
        customClasses,
        activeValue, 
        setActiveValue, 
        getValue, 
        register } = props

    const listRef = useRef()
    const [index, setIndex] = useState(activeValue)
    const [currentTitle, setCurrentTitle] = useState()
    const [listOpen, setListOpen] = useState(0)

    const handleClickList = (value, title) => {
        setIndex(value)
        setCurrentTitle(title)
        setActiveValue && setActiveValue(value)
        setListOpen(0)
    }
    const handleClickTitle = () => setListOpen(prev => prev === 0 ? 'auto' : 0)
    const handleClickOutside = (e) => {
        if (listRef.current && !listRef.current.contains(e.target)) {
            setListOpen(0)
        }
    }
    document.addEventListener('mousedown', handleClickOutside)

    useEffect(() => getValue(name), [index, getValue, name])
    useEffect(() => {
        activeValue && setCurrentTitle(options.filter(el => el.value === activeValue)[0].title)
    }, [options, activeValue])

    return (
        <div className={cn(classes.customSelect, customClasses.customSelect)}>
            <div className={cn(classes.list, customClasses.list)} ref={listRef}>
                <div
                    className={cn(classes.title, listOpen === 'auto' ? classes.active : null)}
                    onClick={handleClickTitle}
                >
                    <p>{currentTitle}</p>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                    </svg>
                </div>
                <AnimateHeight
                    duration={300}
                    height={listOpen}
                >
                    <ul className={listOpen === 'auto' ? classes.active : null}>
                        {options.filter(el => el.value !== activeValue).map(el =>
                            <li key={el.id}>
                                <button
                                    type='button'
                                    onClick={() => handleClickList(el.value, el.title)}
                                >
                                    {el.title}
                                </button>
                            </li>
                        )}
                    </ul>
                </AnimateHeight>
            </div>
            <select
                name={name}
                value={index}
                ref={register}
                readOnly
            >
                {options.map(el =>
                    <option key={el.id} value={el.value}>{el.title}</option>
                )}
            </select>
        </div>
    )
}

export default CommonSelect