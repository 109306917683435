import React from 'react'
import BorderButton from '../../../common/buttons/BorderButton/BorderButton'
import MoneyFormat from '../../../../tools/MoneyFormat'
import classes from './AccountTotal.module.sass'
import cn from 'classnames'
import ExternalLink from '../../../common/ExternalLink'

const AccountTotalInner = ({
    isAccount,
    basket,
    total,
    tracking,
    payment_url,
    list,
    getShippedPdf
}) => {
    const shipped = tracking === 'Отгружен'
    const awaiting = tracking === 'Ожидание оплаты'

    const checkoutButton = () =>
        <div className={classes.button}>
            <BorderButton
                name='Оформить заказ'
                to={`/account/checkout/${list}`}
            />
        </div>

    const shippedButton = () =>
        <div className={classes.button}>
            <BorderButton
                name='Открыть накладную'
                onClick={() => getShippedPdf(list)}
            />
        </div>

    const paymentButton = () =>
        <div className={classes.button}>
            <ExternalLink to={payment_url}>Оплатить</ExternalLink>
        </div>

    return (
        <div className={classes.totalContainer}>
            {total('price') > 0 &&
                <div className={classes.totalWrapper}>
                    <p>Итого:</p>
                    <div className={classes.total}>
                        <p><MoneyFormat value={total('oldPrice')} /></p>
                        <h2><MoneyFormat value={total('price')} /></h2>
                        {tracking &&
                            <div className={cn(classes.tracking, shipped && classes.shipped)}>
                                <p>{tracking}</p>
                            </div>
                        }
                    </div>
                    {(isAccount || basket) && checkoutButton()}
                    {shipped && shippedButton()}
                    {awaiting && paymentButton()}
                </div>
            }
        </div>
    )
}

export default AccountTotalInner
