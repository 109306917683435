import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProductPrice from '../../common/Product/ProductPrice'
import AddPlusButton from '../../common/buttons/AddButton/AddPlusButton'
import NoBorderButton from '../../common/buttons/NoBorderButton/NoBorderButton'
import AnimateHeight from 'react-animate-height'
import DetailsImage from './DetailsImage'
import ProductGallery from './ProductGallery/ProductGallery'
import cn from 'classnames'
import classes from './ProductCardContent.module.sass'

const ProductCardContent = ({
    product: {
        id,
        title,
        description,
		designer_name,
        props: params,
        old_price,
        price,
        long_title,
        photos,
        detail_text,
        detail_photos
    },
    addClick,
    isAuth,
    addGoodToList,
    lists,
    getLists,
    goodInCart,
    isGoodInCart,
    pM
}) => {

    const history = useHistory()
    const [detailsHeight, setDetailsHeight] = useState('auto')
    const [color, setColor] = useState()

    useEffect(() => {
        const color = params && params.find(p => p.id === 'color')
        setColor(color && color.value[0])
    }, [params])

    useEffect(() => {
        !isAuth && lists.length < 1 && getLists()
    }, [lists, getLists, isAuth])

    useEffect(() => {
        id && isGoodInCart(!isAuth && id)
    }, [id, isAuth, isGoodInCart])

    const addClickHandler = () => {
        if (isAuth) {
            addClick({
                img: photos[0].thumb,
                longtitle: long_title,
                id: id,
                color: color
            })
        } else {
            const reg = /^#[a-z0-9]{3}([a-z0-9]{3})?$/i
            const isHex = reg.test(color)
            addGoodToList(
                lists.length > 0 && lists[0].id,
                id,
                1,
                isHex && color,
                isAuth
            )
        }
    }

    const oneClickHandler = () => history.replace(`/account/checkout/good_id_${id}`)

    return (
        <section className={classes.product}>
            <Container>
                <Row className='justify-content-between'>
                    <Col xs={12} md={5} className='col order-2 order-md-1'>
                        <div className={classes.textContainer}>
                            {pM || <h2>{title}</h2>}
                            <div 
                                className={classes.description} 
                                dangerouslySetInnerHTML={{ __html: description }} 
                            />
                            {designer_name &&
                                <div className={classes.designer}>
                                    <p><span className={classes.bold}>Бренд: </span>{designer_name}</p>
                                </div>
                            }
                            <div className={classes.params}>
                                {params && params.map(el =>
                                    el.id === 'color' ?
                                        <div key={el.id} className={cn(classes.param, classes.colors)}>
                                            <p>{`${el.name}: `}</p>
                                            <div className={classes.point} style={{ background: el.value[0] || 'transparent' }}></div>
                                        </div>
                                        :
                                        <div key={el.id} className={classes.param}>
                                            {el.id === 'size' ?
                                                <p>
                                                    <span className={classes.bold}>Длина </span>{`${el.value[0]} ${el.value[3]}`} x
                                                    <span className={classes.bold}> Ширина </span>{`${el.value[1]} ${el.value[3]}`} x
                                                    <span className={classes.bold}> Высота </span>{`${el.value[2]} ${el.value[3]}`}
                                                </p>
                                                :
                                                <p><span className={classes.bold}>{`${el.name}: `}</span>{el.value}</p>
                                            }
                                        </div>
                                )}
                            </div>
                            <ProductPrice
                                parentClasses={classes}
                                oldPrice={old_price}
                                price={price}
                            />
                            <div className={classes.buttons}>
                                <AddPlusButton
                                    style={{ maxWidth: pM && 'none' }}
                                    onClick={addClickHandler}
                                    goodInCart={goodInCart}
                                    isAuth={isAuth}
                                />
                                <NoBorderButton
                                    style={{ maxWidth: pM && 'none' }}
                                    name='Купить в один клик'
                                    onClick={oneClickHandler}
                                />
                            </div>
                            {(detail_text || (detail_photos && detail_photos.length > 0)) &&
                                <div className={classes.details}>
                                    <div
                                        onClick={() => setDetailsHeight(p =>
                                            p === 0 ? 'auto' : 0
                                        )}
                                        className={cn(classes.title,
                                            detailsHeight === 'auto' ? classes.active : null)}
                                    >
                                        <h3>Важные детали</h3>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                                        </svg>
                                    </div>
                                    <AnimateHeight
                                        duration={500}
                                        height={detailsHeight}
                                        className={classes.detailsContainer}
                                    >
                                        {detail_text &&
                                            <div
                                                className={classes.text}
                                                dangerouslySetInnerHTML={{ __html: detail_text }} />
                                        }
                                        {detail_photos &&
                                            <div className={classes.images}>
                                                {detail_photos.map((img, i) =>
                                                    <DetailsImage
                                                        key={i}
                                                        image={img}
                                                        classes={classes}
                                                    />
                                                )}
                                            </div>
                                        }
                                    </AnimateHeight>
                                </div>
                            }
                        </div>
                    </Col>
                    <Col xs={12} md={7} lg={6} className='col order-1 oredr-md-2'>
                        {pM && <h2>{title}</h2>}
                        <ProductGallery gallery={photos} pM={pM} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProductCardContent
