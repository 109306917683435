import React from 'react'
import { connect } from 'react-redux'
import RegistrationModal from './RegistrationModal'
import EnterModal from './EnterModal'
import PhoneModal from './PhoneModal'
import SmsModal from './SmsModal'
import ErrorModal from './ErrorModal'
import { callModal } from '../../../redux/modalsReducer'
import {
	loginThunk,
	registerThunk,
	registerConfirmThunk,
	resetThunk,
	resetConfirmThunk
} from '../../../redux/accountReducer'
import AppSuccessModal from './AppSuccessModal'
import AppErrorModal from './AppErrorModal'
import OneSuccessModal from './OneSuccessModal'
import OneErrorModal from './OneErrorModal'
import AutorizationErrorModal from './AutorizationErrorModal'

const AccountModals = ({
	modals,
	email,
	callModal,
	loginThunk,
	registerThunk,
	confirmedPhone,
	registerConfirmThunk,
	resetPhone,
	resetThunk,
	resetConfirmThunk
}) => {
	return (
		modals.map((m, i) => {
			switch (m.id) {
				case 'registration':
					return <RegistrationModal
						key={i}
						modal={m}
						callModal={callModal}
						registerThunk={registerThunk}
					/>
				case 'enter':
					return <EnterModal
						key={i}
						modal={m}
						callModal={callModal}
						loginThunk={loginThunk}
					/>
				case 'phone':
					return <PhoneModal
						key={i}
						modal={m}
						callModal={callModal}
						resetThunk={resetThunk}
					/>
				case 'sms':
					return <SmsModal
						key={i}
						modal={m}
						callModal={callModal}
						confirmedPhone={confirmedPhone}
						registerConfirmThunk={registerConfirmThunk}
						resetPhone={resetPhone}
						resetConfirmThunk={resetConfirmThunk}
					/>
				case 'error':
					return <ErrorModal key={i} email={email} modal={m} callModal={callModal} />
				case 'app_success':
					return <AppSuccessModal
						key={i}
						modal={m}
						callModal={callModal}
					/>
				case 'app_error':
					return <AppErrorModal
						key={i}
						modal={m}
						callModal={callModal}
						email={email}
					/>
				case 'one_success':
					return <OneSuccessModal
						key={i}
						modal={m}
						callModal={callModal}
					/>
				case 'one_error':
					return <OneErrorModal
						key={i}
						modal={m}
						callModal={callModal}
						email={email}
					/>
				case 'autorization_error':
					return <AutorizationErrorModal
						key={i}
						modal={m}
						callModal={callModal}
					/>
				default:
					return false
			}
		})
	)
}

const mapStateToProps = state => {
	return {
		email: state.staticContent.email,
		modals: state.modals.modals,
		confirmedPhone: state.account.confirmedPhone,
		resetPhone: state.account.resetPhone
	}
}

export default connect(mapStateToProps, {
	callModal,
	loginThunk,
	registerThunk,
	registerConfirmThunk,
	resetThunk,
	resetConfirmThunk
})(AccountModals)

