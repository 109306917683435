import React from 'react'
import classes from './CatalogAsideItem.module.sass'
import { NavLink } from 'react-router-dom'

const CatalogAsideItem = ({ title, path, category, setOpenMobileAside, pM }) => {
    return (
        <li className={classes.item}>
            <NavLink 
                to={`/catalog/${category}/${path}`} 
                className={classes.link} 
                activeClassName={classes.active}
                onClick={() => pM && setOpenMobileAside(0)}
            >
                {title}
            </NavLink>
        </li>
    )
}

export default CatalogAsideItem
