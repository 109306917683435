import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'

export const withForm = (WrappedComponent) => {
    const FormContainer = ({ ...props }) => {
        const modalRef = useRef()
        const { pathname } = useLocation()
        const history = useHistory()

        const changeUrl = () =>
            history.push(pathname.slice(0, pathname.lastIndexOf('/')))

        const useOutsideAlerter = ref => {
            useEffect(() => {
                const handleClickOutside = e => {
                    if (ref.current && !ref.current.contains(e.target)) {
                        props.callModal(props.modal.id)
                        if (props.modal.id === 'payment_successful' ||
                            props.modal.id === 'payment_error')
                            changeUrl()
                    }
                }
                document.addEventListener('mousedown', handleClickOutside)
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside)
                }
            }, [ref])
        }
        useOutsideAlerter(modalRef)

        const isPhone = value => {
            const replaced = value.replace(/[-()\s]/g, '')
            return replaced.length >= 12
        }

        return <WrappedComponent
            useForm={useForm()}
            modalRef={modalRef}
            changeUrl={changeUrl}
            isPhone={isPhone}
            rules={props.rules}
            email={props.email}
            {...props} />
    }

    return FormContainer
}