import React, { useEffect } from 'react'
import { Modal } from 'reactstrap'
import classes from './OneClick.module.sass'
import { reduxForm, Field } from 'redux-form'
import BorderButton from '../../../common/buttons/BorderButton/BorderButton'
import { required, minLength } from '../../../common/validators'
import { PhoneField } from '../../../common/fields/PhoneField'
import { createTextMask } from 'redux-form-input-masks'
import ReCAPTCHA from 'react-google-recaptcha'

const minLength15 = minLength(15)

const OneClick = ({ 
    handleSubmit, 
    reset, 
    modalData: {title, img}, 
    oneClickModalOpen, 
    setModal,
    reRef
}) => {

    useEffect(() => 
        setTimeout(() =>
            !oneClickModalOpen  && reset()
        , 500)
    , [oneClickModalOpen, reset])

    const phoneMask = createTextMask({
        pattern: '+7(999)999-9999',
        guide: false,
        allowEmpty: false,
        stripMask: false
    })

    return (
        <Modal
            isOpen={oneClickModalOpen}
            toggle={() => setModal('oneClick')}
            centered
            className={classes.modal}
        >
            <div className={classes.container}>
                <button onClick={() => setModal('oneClick')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                    </svg>
                </button>
                <div className={classes.imgContainer}>
                    <img src={process.env.REACT_APP_BACKEND_URL + img} alt={title} />
                </div>
                <div className={classes.textContainer}>
                    <div className={classes.title}>
                        <h3>Перезвоним и оформим заказ без регистрации</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITEKEY}
                            size='invisible'
                            ref={reRef}
                        />
                        <div className={classes.phone}>
                            <Field
                                name='phone'
                                component={PhoneField}
                                type='text'
                                placeholder='Номер телефона'
                                validate={[required, minLength15]}
                                {...phoneMask}
                            />
                        </div>
                        <BorderButton name='Оформить предзаказ' type='submit' />
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default reduxForm({
    form: 'oneClick'
})(OneClick)
