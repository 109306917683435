import React from 'react'
import classes from './NoBorderButton.module.sass'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { withBreakpoints } from '../../../../hoc/withBreakpoints'

const NoBorderButton = ({name, disabled, onClick, style, type, to, TM}) => {
    return (
        to ?
        <Link 
            to={to}
            className={cn(
                classes.button, 
                TM && classes.touch,
                {'disabledBtn': disabled}, 
                'textButton'
            )}
        >
            {name}
        </Link>
        : 
        <button 
            type={type && type}
            onClick={onClick} 
            className={cn(
                classes.button, 
                TM && classes.touch,
                {'disabledBtn': disabled}, 
                'textButton'
            )}
            style={style}
        >
            {name}
        </button>
    )
}

export default withBreakpoints(NoBorderButton)
