import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Menu from './Menu'
import { getCategories, getListStatus } from '../../../../redux/catalogReducer'
import { sessionThunk } from '../../../../redux/accountReducer'
import { getStatic } from '../../../../redux/staticReducer'

const MenuContainer = ({
    categories,
    email,
    phone,
    sessionThunk,
    getCategories,
    getStatic,
    search,
    getListStatus,
    D
}) => {
    const arr = categories.slice(1, categories.length)
    const slicedArr = D ? arr.slice(0, -2) : arr
    const catLength = slicedArr.length
    const colLength = D ? 3 : 2
    const perCol = Math.ceil(catLength / colLength) + (D ? 0 : 1)

    useEffect(() => getListStatus(), [getListStatus])
    useEffect(() => categories.length < 3 && getCategories(), [categories.length, getCategories])
    useEffect(() => !email && getStatic('contacts'), [email, getStatic])
    useEffect(() => sessionThunk(), [sessionThunk])

    return <Menu
        arr={arr}
        slicedArr={slicedArr}
        perCol={perCol}
        email={email}
        phone={phone}
        search={search}
        D={D}
    />
}

const mapStateToProps = state => {
    return {
        categories: state.catalog.categories,
        email: state.staticContent.email,
        phone: state.staticContent.phone,
        search: state.catalog.search
    }
}

export default connect(mapStateToProps, {
    sessionThunk,
    getCategories,
    getStatic,
    getListStatus
})(MenuContainer)
