import React from 'react'
import { Modal } from 'reactstrap'
import { withForm } from '../../../hoc/withForm'

const AutorizationErrorModal = ({ modal: { open, id, title }, callModal, modalRef }) => {
    return (
        <Modal
            contentClassName='commonModal appError'
            isOpen={open}
            centered
        >
            <div className='wrapper errorModal' ref={modalRef}>
                <div className='inner'>
                    <div className='closeBtn' onClick={() => callModal(id)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                        </svg>
                    </div>
                    <h3>{title}</h3>
                    <div className='account textSmall'>
                        <p>Возможно, введены неправильные данные</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withForm(AutorizationErrorModal)
