import React, { useEffect } from 'react'
import { withForm } from '../../../hoc/withForm'
import { Modal } from 'reactstrap'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'

const PaymentSuccessModal = ({
    modal: { id, title, description, open },
    modalRef,
    changeUrl,
    callModal,
    payment
}) => {
    useEffect(() => {
       payment === id ? callModal(id) : open && callModal(id)
    }, [payment, callModal, id]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = () => {
        callModal(id)
        changeUrl()
    }

    return (
        <Modal
            isOpen={open}
            contentClassName='commonModal paymentModal successful'
            centered
        >
            <div className='wrapper' ref={modalRef}>
                <div className='inner'>
                    <div className='closeBtn' onClick={handleClose}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                        </svg>
                    </div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                    <div className='btnContainer'>
                        <BorderButton 
                            name='Перейти в личный кабинет'
                            to='/account' 
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default withForm(PaymentSuccessModal)
