import React from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

const FormPhoneField = ({ name, placeholder, control, errors, isPhone, third }) => {

    const rul = {
        required: true,
        validate: value => isPhone(value)
    }

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={(props) => (
                    <NumberFormat
                        value={props.value}
                        onChange={props.onChange}
                        placeholder={placeholder || 'Номер телефона'}
                        format='+7(###)###-####'
                        mask='_'
                        allowEmptyFormatting={true}
                    />
                )}
                defaultValue={null}
                rules={name !== 'phone' ?
                    third === 0 ? null : rul :
                    rul}
            />
            <span className='error textSmall'>
                {name !== 'phone' ?
                    <>
                        {errors.thirdPhone?.type === 'required' && <p>обязательное поле</p>}
                        {errors.thirdPhone?.type === 'validate' && <p>введите номер телефона</p>}
                    </> :
                    <>
                        {errors.phone?.type === 'required' && <p>обязательное поле</p>}
                        {errors.phone?.type === 'validate' && <p>введите номер телефона</p>}
                    </>
                }
            </span>
        </>
    )
}

export default FormPhoneField
