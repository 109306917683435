import React from 'react'
import NumberFormat from 'react-number-format'

const MoneyFormat = ({ value }) => {

    const toFixed = v => {
        const num = v && Number(v).toFixed(2)
        const split = num && num.split('.')[1].split('')
        let zero = 0
        split && split.forEach(z => z === '0' && zero++)
        if (zero !== 2) {
            if (v === 0) {
                return v
            }
            return Number(v).toFixed(2)
        }
        return Number(v).toFixed()
    }
    return <NumberFormat
        value={toFixed(value)}
        displayType={'text'}
        thousandSeparator={' '}
        suffix={' ₽'}
    />
}

export default MoneyFormat
