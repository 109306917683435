import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRightBlack from '../../svg/ArrowRightBlack'
import ArrowRightWhite from '../../svg/ArrowRightWhite'
import classes from './AccountLink.module.sass'
import cn from 'classnames'
import { callModal } from '../../../../redux/modalsReducer'
import { connect } from 'react-redux'
import { withBreakpoints } from '../../../../hoc/withBreakpoints'
import { compose } from 'redux'

const AccountLink = ({
    name,
    dark,
    onClick,
    callModal,
    isAuth,
    hasGoods,
    TM
}) => {

    const nameTransform = () => {
        const split = name && name.length > 0 && name.split(' ')
        const first = TM ? `${split && split[0][0]}` : split && split[0]
        const secondLetter = split && split.length > 1 ? ` ${split && split[1][0]}${TM ? '' : '.'}` : ''
        return `${first}${secondLetter}`
    }

    return (
        <div className={cn(
            classes.AccountLink,
            dark ? classes.isMenu : classes.isHeader
        )} onClick={onClick}>
            {isAuth ?
                <Link to='/account/lists/' className={cn(
                    classes.registered,
                    hasGoods && classes.notEmpty
                )}>
                    <p>{nameTransform()}</p>
                    {dark ? <ArrowRightWhite /> : <ArrowRightBlack />}
                </Link>
                :
                <Link to='#' onClick={() => callModal('enter')} className='textCaps'>
                    <p>Вход</p>
                    {dark ? <ArrowRightWhite /> : <ArrowRightBlack />}
                </Link>
            }
        </div >
    )
}

const mapStateToProps = state => {
    return (
        {
            name: state.account.name,
            isAuth: state.account.isAuth,
            hasGoods: state.catalog.hasGoods
        }
    )
}

export default compose(
    withBreakpoints,
    connect(mapStateToProps, { callModal })
)(AccountLink)
