import React, {useEffect} from 'react'
import classes from './ProductColor.module.sass'
import cn from 'classnames'

const ProductColorItem = ({
    id,
    productId,
    color,
    size,
    border,
    register,
    getCurrentColor,
    checked,
    handleSubmit,
    onSubmit,
    productCard,
    index }) => {

    const handleChange = () => getCurrentColor(color)
   
    useEffect(() => {
        productCard && checked && handleSubmit(onSubmit)()
    }, [checked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.item}>
            <input
                type='checkbox'
                name='color'
                value={color}
                ref={register}
                onChange={() => handleChange()}
                id={`color_${productId}_${id}_${color}_${index}`}
                checked={checked}
            />
            <label
                htmlFor={`color_${productId}_${id}_${color}_${index}`}
                className={cn(
                    border && classes.border,
                    classes.color,
                    classes.label
                )}
                style={{ background: color, width: size, height: size }}
            >
            </label>
        </div>
    )
}

export default ProductColorItem
