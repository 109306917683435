import React from 'react'
import classes from './RotateDevice.module.sass'
import icon from '../../../assets/img/rotate.svg'

const RotateDevice = () => {
    return (
        <div className={classes.rotate}>
            <div className={classes.wrapper}>
                <img src={icon} alt='' />
                <h3>Пожалуйста, поверните телефон для наиболее удобного использования сайта.</h3>
            </div>
        </div>
    )
}

export default RotateDevice
