import React from 'react'
import AccountProduct from './AccountProduct'
import AccountEmpty from './AccountEmpty'

const AccountProducts = ({ isAccount, lists, list, basket, callModal }) => {
    return (
        basket ? 
        basket.items && basket.items.length > 0 ? 
            basket.items.map((it, i) =>
                <AccountProduct
                    key={i}
                    listId={basket.id}
                    entryId={it.entry_id}
                    defaultColor={it.props && it.props.length > 0 && it.props.find(f => f.id === 'color').value}
                    quantity={it.amount}
                    product={it.good}
                    basket
                />
            ) :
                <AccountEmpty orders={!isAccount} list={list} />
            :
            lists && lists.length > 0 ? 
            lists.filter(f => f.id === list).map((l, i) =>
                l.items && l.items.length > 0 ? 
                l.items.map((it, i) =>
                    <AccountProduct
                        key={i}
                        index={i}
                        listId={l.id}
                        entryId={it.entry_id}
                        defaultColor={it.props && it.props.length > 0 && it.props.find(f => f.id === 'color').value}
                        quantity={it.amount}
                        product={it.good}
                        isAccount={isAccount}
                    />
                ) :
                    <AccountEmpty key={i} orders={!isAccount} list={list} />
            ) :
                <AccountEmpty orders={!isAccount} list={list} callModal={callModal} />
    )
}

export default AccountProducts
