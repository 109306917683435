import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ScrollToTop from './tools/ScrollToTop'

import Home from './components/Home/Home'
import Catalog from './components/Catalog/Catalog'
import ProductCard from './components/Catalog/ProductCard/ProductCard'
import CompilationContainer from './components/Compilation/Compilation'
import Designers from './components/Designers/Designers'
import About from './components/About/About'
import Checkout from './components/Checkout/Checkout'
import Sale from './components/Sale/Sale'
import Account from './components/Account/Account'
import StaticContainer from './components/Howto/StaticContainer'

const RoutesSwitcher = () => {

	const staticPathes = [
		'/delivery',
		'/payment',
		'/warranty',
		'/returns',
		'/howto',
		'/privacy_policy',
		'/user_agreement'
	]

    return (
        <>
            <Switch>
                <Route exact path='/catalog/:category?/:subcategory?' component={Catalog} />
                <ScrollToTop>
                    <Switch>
                        <Route path='/catalog/:category/:subcategory/:product' component={ProductCard} />
                        <Route path='/account/checkout/:list?/:payment?' component={Checkout} />
                        <Route path='/account/lists/:list?/:payment?' component={Account} />
                        <Route path='/account/orders/:list?/:payment?' component={Account} />
                        <Route exact path='/account'>
                            <Redirect to='/account/lists/' />
                        </Route>
                        <Route path='/sale' component={Sale} />
                        <Route path='/compilation' component={CompilationContainer} />
                        <Route path='/designers' component={Designers} />
                        <Route path='/about' component={About} />
						{staticPathes.map(el => (
							<Route key={el} path={el} component={StaticContainer} />
						))}
                        <Route path='/' component={Home} />
                        <Route><div>404</div></Route>
                    </Switch>
                </ScrollToTop>
            </Switch>
        </>
    )
}

export default RoutesSwitcher
