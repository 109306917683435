import React, { useState, useEffect } from 'react'
import classes from './CheckoutData.module.sass'
import cn from 'classnames'
import AnimateHeight from 'react-animate-height'
import FormPhoneField from '../../common/fields/FormPhoneField'
import FormNameField from '../../common/fields/FormNameField'
import FormEmailField from '../../common/fields/FormEmailField'

const CheckoutPayer = ({ register, unregister, errors, clearErrors, control }) => {
    const [third, setThird] = useState(0)
    const handleChange = (e) => setThird(e.target.checked ? 'auto' : 0)

    const isPhone = (value) => {
        const replaced = value.replace(/[-()\s]/g, '')
        return replaced.length >= 12
    }

    useEffect(() => {
        if (third === 0) {
            clearErrors(['thirdName', 'thirdPhone'])
            unregister('thirdName')
        }
    }, [third, clearErrors, unregister])

    return (
        <div className={classes.payerData}>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <FormNameField
                    name='name'
                    register={register}
                    errors={errors}
                />
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <FormPhoneField
                    name='phone'
                    control={control}
                    errors={errors}
                    isPhone={isPhone}
                />
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <FormEmailField
                    register={register}
                    errors={errors}
                />
            </div>
            <div className={classes.third}>
                <label>
                    <input type='checkbox' onChange={(e) => handleChange(e)} />
                    <p>Получатель - третье лицо</p>
                </label>
                <AnimateHeight
                    height={third}
                    duration={300}
                    className={classes.dropdown}
                >
                    <div className={cn(classes.formGroup, 'formGroup')}>
                        <FormNameField
                            name='thirdName'
                            register={register}
                            errors={errors}
                            third={third}
                        />
                    </div>
                    <div className={cn(classes.formGroup, 'formGroup')}>
                        <FormPhoneField
                            name='thirdPhone'
                            control={control}
                            errors={errors}
                            isPhone={isPhone}
                            third={third}
                        />
                    </div>
                </AnimateHeight>
            </div>
        </div>
    )
}

export default CheckoutPayer
