import React from 'react'
import classes from './CatalogOrder.module.sass'
import { connect } from 'react-redux'
import CommonSelect from '../../common/CommonSelect/CommonSelect'
import { setActiveValue } from '../../../redux/catalogReducer'

const CatalogOrder = ({ orders, activeValue, setActiveValue, getValue, register }) => {
    const selectName = 'catalogOrder'

    return (
        <div className={classes.order}>
            <form>
                <CommonSelect
                    name={selectName}
                    options={orders}
                    activeValue={activeValue}
                    setActiveValue={setActiveValue}
                    getValue={getValue}
                    register={register}
                    customClasses={classes}
                />
            </form>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        orders: state.catalog.orderList.orders,
        activeValue: state.catalog.orderList.activeValue
    }
}

export default connect(mapStateToProps, {setActiveValue})(CatalogOrder)