import React from 'react'
import AccountTotalInner from './AccountTotalInner'

const AccountTotal = ({ 
    isAccount, 
    list, 
    basket,
    discount, 
    getShippedPdf, 
    currentList, 
    tracking,
    payment_url
}) => {
    const finalPrice = p =>{
        
        return p * (100 - discount) / 100
        }



    const total = type => {
        const currentItems = basket ? basket.items : currentList && currentList.items

        const currentProducts = currentItems && currentItems.reduce((acc, p) => (
            [
                ...acc,
                {
                    price: p.good.price * p.amount,
                    old_price: p.good.old_price * p.amount
                }
            ]
        ), [])
        const total = currentProducts && currentProducts.reduce((acc, p) =>
            acc + (type === 'price' ? finalPrice(p.price) : p.old_price)
            , 0)
        return total
    }

    return (
        <AccountTotalInner
            isAccount={isAccount}
            total={total}
            basket={basket && basket.items && basket.items.length > 0}
            list={list}
            tracking={tracking}
            payment_url={payment_url}
            getShippedPdf={getShippedPdf}
        />
    )
}

export default AccountTotal
