import { compilationsAPI } from '../api/api'

const SET_TOTAL_COUNT = 'compilation/SET_TOTAL_COUNT'
const SET_COMPILATIONS = 'compilation/SET_COMPILATIONS'
const CLEAR_COMPILATIONS = 'compilation/CLEAR_COMPILATIONS'
const CLEAR_ITEM = 'compilation/CLEAR_ITEM'
const SET_COMPILATION_ITEM = 'compilation/SET_COMPILATION_ITEM'

const initialState = {
    compilation: [],
    compilationItem: {},
    totalCount: null,
    itemsToShow: 11
}

const compilationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: action.count
            }
        }
        case SET_COMPILATIONS: {
            return {
                ...state,
                compilation: [...state.compilation, ...action.compilations]
            }
        }
        case CLEAR_COMPILATIONS: {
            return {
                ...state,
                compilation: []
            }
        }
        case CLEAR_ITEM: {
            return {
                ...state,
                compilationItem: {}
            }
        }
        case SET_COMPILATION_ITEM: {
            return {
                ...state,
                compilationItem: action.id
            }
        }
        default:
            return state
    }
}

export const setTotalCount = count => ({ type: SET_TOTAL_COUNT, count })
export const setCompilations = compilations => ({ type: SET_COMPILATIONS, compilations })
export const clearCompilations = () => ({ type: CLEAR_COMPILATIONS })
export const clearItem = () => ({ type: CLEAR_ITEM })
export const setCompilationItem = id => ({ type: SET_COMPILATION_ITEM, id })

export const getCompilations = (count, offset = 0) => async dispatch => {
    try {
        const data = await compilationsAPI.getCompilations(count, offset)
        dispatch(setCompilations(data.compilations))
        offset < 1 && dispatch(setTotalCount(data.total_count))
    } catch (error) {
        console.log(error)
    }
}

export const getCompilationItem = id => async dispatch => {
    dispatch(clearItem())
    try {
        const data = await compilationsAPI.getCompilationItem(id)
        dispatch(setCompilationItem(data.compilation))
    } catch (error) {
        console.log(error)
    }
}

export default compilationReducer

