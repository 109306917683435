import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NoBorderButton from '../../common/buttons/NoBorderButton/NoBorderButton'
import classes from './CatalogBanner.module.sass'
import cn from 'classnames'

const CatalogBanner = ({ el }) => {
    return (
        <Row className={classes.row}>
            <Col xs={12} className={classes.col}>
                <div className={classes.banner}>
                    <Row className={classes.row}>
                        <Col xs={12} lg={6} className={cn(classes.col, 'order-2 order-lg-1')}>
                            <div className={classes.text}>
                                <h3>{`Вам точно понравится подборка 
                                «${el.title}»`}</h3>
                                <div className={classes.btnContainer}>
                                    <Link to={el.path ? `/compilation/${el.path}` : ''}>
                                        <NoBorderButton name='Посмотреть' />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className={cn(classes.col, 'order-1 order-lg-2')}>
                            <div className={classes.image}>
                                <img src={process.env.REACT_APP_BACKEND_URL + el.photo} alt={el.title} className='fitCover' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default CatalogBanner
