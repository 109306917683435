import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import classes from './Point.module.sass'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { withBreakpoints } from '../../../hoc/withBreakpoints'
import ClampLines from 'react-clamp-lines'


const Point = ({ title, top, left, containerRef, category, subcategory, path, TM }) => {
    const pointRef = useRef()

    const [fadeIn, setFadeIn] = useState(false)
    const [offsetState, setOffsetState] = useState({})

    const handleEnter = e => {
        const container = containerRef.current.getBoundingClientRect()
        const point = e.target.getBoundingClientRect()
        const top = point.top - container.top
        const left = point.left - container.left
        const right = Math.abs(point.right - container.right)
        const bottom = Math.abs(point.bottom - container.bottom)
        const minOffsetX = 120
        const minOffsetY = 40

        const calc = (name, offset, minOffset) => {
            if (offsetState) {
                let diff = minOffset - offset
                setOffsetState(prev => ({
                    ...prev,
                    [name]: parseInt(`-${(minOffset - diff) * 2}`) + 'px'
                }))
            }
        }

        top < minOffsetY && calc('top', top, minOffsetY)
        bottom < minOffsetY && calc('bottom', bottom, minOffsetY)
        left < minOffsetX && calc('left', left, minOffsetX)
        right < minOffsetX && calc('right', right, minOffsetX)

        TM && setFadeIn(true)
    }

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setFadeIn(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }
    useOutsideAlerter(pointRef)

    const titleCut = t => t.split('(')[0]

    const transitionStyle = {
        enterActive: classes.enterActive,
        enterDone: classes.enterDone,
        exitActive: classes.exitActive
    }

    return (
        <div
            className={classes.point}
            onMouseEnter={(e) => TM || handleEnter(e)}
            onMouseOver={() => TM || setFadeIn(true)}
            onMouseOut={() => TM || setFadeIn(false)}
            onClick={(e) => TM && handleEnter(e)}
            style={{ top: `${top}%`, left: `${left}%` }}
            ref={pointRef}
        >
            <div className={classes.dot}>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 3.2H4.8V0H3.2V3.2H0V4.8H3.2V8H4.8V4.8H8V3.2Z" fill="#242422" />
                </svg>
            </div>
            <CSSTransition
                in={fadeIn}
                timeout={0}
                classNames={transitionStyle}
                mountOnEnter
                unmountOnExit
            >
                <Link
                    to={`/catalog/${category === undefined ? 'all' : category}/${subcategory === undefined ? 'all' : subcategory}/${path}`}
                    className={cn(classes.lable, 'textButton')}
                    style={offsetState}
                >
                    <ClampLines
                        text={titleCut(title)}
                        lines={3}
                        ellipsis='...'
                        buttons={false}
                        innerElement='p'
                    />
                </Link>
            </CSSTransition>
        </div>
    )
}

export default withBreakpoints(Point)
