import React from 'react'
import { Link } from 'react-router-dom'
import classes from './CheckoutOrder.module.sass'
import cn from 'classnames'
import MoneyFormat from '../../../tools/MoneyFormat'

const CheckoutProduct = ({
    listId,
    entryId,
    quantity,
    deleteGood,
    oneClick,
    pM,
    product: {
        good_path: path,
        category_path: category,
        subcategory_path: subcategory,
        title,
        photos: gallery,
        price,
        old_price: oldPrice
    }
}) => {
    const url = `/catalog/${!category ? 'all' : category}/${!subcategory ? 'all' : subcategory}/${path}`

    const handleRemove = () => deleteGood(listId, entryId)

    return (
        <div className={classes.item}>
            <Link to={url} className={classes.leftSide}>
                <div className={classes.image}>
                    {gallery && gallery.length > 0 &&
                        <img src={process.env.REACT_APP_BACKEND_URL + gallery[0].thumb} alt={gallery[0].alt} className='fitContain' />
                    }
                </div>
                <div className={classes.flexBlock}>
                    <div className={classes.title}>
                        <p>{title}</p>
                    </div>
                    {pM &&
                        <div className={classes.price}>
                            <p><MoneyFormat value={price * quantity} /></p>
                            <div className={cn(classes.oldPrice, 'textSmall')}>
                                <p><MoneyFormat value={oldPrice * quantity} /></p>
                            </div>
                        </div>
                    }
                </div>
            </Link>
            <div className={classes.rightSide}>
                {pM ||
                    <div className={classes.price}>
                        <p><MoneyFormat value={price * quantity} /></p>
                        <div className={cn(classes.oldPrice, 'textSmall')}>
                            <p><MoneyFormat value={oldPrice * quantity} /></p>
                        </div>
                    </div>
                }
                {!oneClick &&
                    <button onClick={handleRemove} className={classes.remove}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 1H6V2H10V1ZM2 3V4H3V14C3 14.6 3.4 15 4 15H12C12.6 15 13 14.6 13 14V4H14V3H2ZM4 14V4H12V14H4ZM6 6H7V12H6V6ZM10 6H9V12H10V6Z" fill="#F33C42" />
                        </svg>
                    </button>
                }
            </div>
        </div>
    )
}

export default CheckoutProduct
