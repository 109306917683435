import React from 'react'
import { Container, Row } from 'react-bootstrap'
import classes from './ProductCardCompilation.module.sass'
import cn from 'classnames'
import HomeCompilationItem from '../../../Home/HomeCompilation/HomeCompilationItem'
import BigButton from '../../../common/buttons/BigButton/BigButton'

const ProductCardCompilation = ({ 
    otherProducts, 
    addOneClick, 
    addClick, 
    isAuth,
    lists,
    getProductInCart,
    addGoodToList,
    pT 
}) => {
    return (
        <section className={classes.compilation}>
            <Container>
                <div className={classes.title}>
                    <p>Вам могут понравиться:</p>
                </div>
                <Row className={cn(classes.row, 'justify-content-center')}>
                    {otherProducts.length > 0 && otherProducts.filter((_, i) => i < (pT ? 3 : 4)).map((p, i) =>
                        <HomeCompilationItem
                            key={i}
                            p={p}
                            addOneClick={addOneClick}
                            addClick={addClick}
                            isAuth={isAuth}
                            lists={lists}
                            goodInCart={p.inCart}
                            getInCart={getProductInCart}
                            addGoodToList={addGoodToList}
                            compLocation='product_card'
                        />
                    )}
                </Row>
                <div className={classes.button}>
                    <BigButton name='Каталог' to='/catalog' />
                </div>
            </Container>
        </section >
    )
}

export default ProductCardCompilation
