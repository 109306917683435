import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const withBreakpoints = (WrappedComponent) => {
    const Breakpoints = ({ ...props }) => {

        const D = useMediaQuery({ type: 'screen', minWidth: 1141 })
        const TM = useMediaQuery({ type: 'screen', maxWidth: 1140 })
        const T = useMediaQuery({ type: 'screen', minWidth: 768, maxWidth: 1140 })
        const pT = useMediaQuery({ type: 'screen', orientation: 'portrait', minWidth: 768, maxWidth: 1140 })
        const pTM = useMediaQuery({ type: 'screen', orientation: 'portrait', maxWidth: 768 })
        const pM = useMediaQuery({ type: 'screen', orientation: 'portrait', maxWidth: 767 })
        const lM = useMediaQuery({ type: 'screen', orientation: 'landscape', maxWidth: 896 })

        return <WrappedComponent 
            D={D} 
            TM={TM} 
            T={T} 
            pT={pT}
            pTM={pTM} 
            pM={pM} 
            lM={lM}
            {...props} 
        />
    }

    return Breakpoints
}