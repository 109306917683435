import React, { useEffect } from 'react'
import classes from './Offer.module.sass'
import { Container, Row, Col } from 'react-bootstrap'
import BigButton from '../buttons/BigButton/BigButton'
import { connect } from 'react-redux'
import { getOffer } from '../../../redux/staticReducer'

const Offer = ({ offer, getOffer }) => {

    useEffect(() => {
        Object.keys(offer).length === 0 && getOffer()
    }, [offer, getOffer])

    return (
        <div className={classes.wrapper}>
            <Container className={classes.container}>
                <Row>
                    <Col xs={12} xl={6} className='col'>
                        <div className={classes.textContainer}>
                            <div 
								className={classes.content} 
								dangerouslySetInnerHTML={{ __html: offer.desc }} />                            <div className={classes.buttonContainer}>
                                <BigButton to='/designers' name='Предложение для профессионалов' />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={classes.imgContainer}>
                <img src={process.env.REACT_APP_BACKEND_URL + offer.photo} alt='' className='absoluteCenter' />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        offer: state.staticContent.offer
    }
}

export default connect(mapStateToProps, { getOffer })(Offer)
