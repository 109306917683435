import React from 'react'
import classes from './AccountListsTransform.module.sass'
import NewListButton from '../../common/buttons/NewListButton/NewListButton'
import CombineButton from '../../common/buttons/CombineButton/CombineButton'

const AccountListsTransform = ({ account, register }) => {
    return (
        <div className={classes.transform}>
            {register || <NewListButton />}
            {register && account &&
                <>
                    <CombineButton />
                    <NewListButton account={account} />
                </>
            }
        </div>
    )
}

export default AccountListsTransform
