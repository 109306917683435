import React, { useEffect } from 'react'
import classes from './Account.module.sass'
import cn from 'classnames'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import { Container } from 'react-bootstrap'
import AccountTitle from './AccountTitle/AccountTitle'
import AccountContent from './AccountContent/AccountContent'
import AccountPageModals from './Modals/AccountPageModals'
import { useRouteMatch } from 'react-router-dom'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import {
    getLists,
    getGoodsOfList,
    getOrders,
    setRedirect,
    renameList,
    setRenameSuccess,
    getShippedPdf,
    updateAccountInfo,
    logoutThunk
} from '../../redux/accountReducer'
import { callModal } from '../../redux/modalsReducer'
import { compose } from 'redux'
import { connect } from 'react-redux'

const Account = ({
    isAuth,
    lists,
    paid,
    redirect,
    discount,
    renameSuccess,
    hidden,
    fetching,
    name,
    phone,
    email,
    discountPercent,
    disabled,
    getLists,
    getGoodsOfList,
    getOrders,
    setRedirect,
    renameList,
    setRenameSuccess,
    getShippedPdf,
    callModal,
	logoutThunk,
	updateAccountInfo,
    TM,
    pM
}) => {

    const { path } = useRouteMatch()
    const baseUrl = path.slice(0, path.lastIndexOf('/', path.lastIndexOf('/') - 1))
    const isAccount = baseUrl === '/account/lists'

    useEffect(() => {
        if (localStorage.oldSid !== localStorage.sid) {
            callModal('basket_first_visit')
            localStorage.oldSid = localStorage.sid
        }
    }, [callModal, localStorage.sid]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <AccountPageModals baseUrl={baseUrl} />
            <Breadcrumbs max={1} />
            <section className={cn(classes.account, 'preFooterDec', 'left')}>
                <Container>
                    <AccountTitle
                        isAuth={isAuth}
                        name={name}
                        phone={phone}
                        email={email}
                        discountPercent={discountPercent}
                        disabled={disabled}
                        updateAccountInfo={updateAccountInfo}
                        logoutThunk={logoutThunk}
                        callModal={callModal}
                        TM={TM}
                    />
                    <AccountContent
                        isAuth={isAuth}
                        lists={lists}
                        paid={paid}
                        redirect={redirect}
                        discount={discount}
                        renameSuccess={renameSuccess}
                        hidden={hidden}
                        fetching={fetching}
                        getLists={getLists}
                        getGoodsOfList={getGoodsOfList}
                        getOrders={getOrders}
                        setRedirect={setRedirect}
                        renameList={renameList}
                        setRenameSuccess={setRenameSuccess}
                        getShippedPdf={getShippedPdf}
                        callModal={callModal}
                        baseUrl={baseUrl}
                        isAccount={isAccount}
                        TM={TM}
                        pM={pM}
                    />
                </Container>
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        isAuth: state.account.isAuth,
        lists: state.account.lists,
        paid: state.account.paid,
        redirect: state.account.redirect,
        discount: state.account.discount_percent,
        renameSuccess: state.account.renameSuccess,
        hidden: state.account.hidden,
        fetching: state.staticContent.fetching,
        name: state.account.name,
        phone: state.account.phone,
        email: state.account.email,
        discountPercent: state.account.discount_percent,
        disabled: state.account.disabled,
    }
}

export default compose(
    withBreakpoints,
    connect(mapStateToProps, {
        getLists,
        getOrders,
        getGoodsOfList,
        setRedirect,
        renameList,
        callModal,
        setRenameSuccess,
        getShippedPdf,
        updateAccountInfo,
        logoutThunk
    })
)(Account)
