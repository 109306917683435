import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import classes from './ProductCardBanner.module.sass'
import cn from 'classnames'

const ProductCardBanner = ({ product }) => {
    return (
        <section className={classes.banner}>
            <Container>
                <Row>
                    <Col xs={12} md={5} lg={6} className='col'>
                        <div className={classes.image}>
                            <img 
                                src={product.designer && process.env.REACT_APP_BACKEND_URL + product.designer.photo} 
                                alt={product.designer && product.designer.name} 
                                className='fitCover' 
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={7} lg={6} className='col'>
                        <div className={classes.text}>
                            <div className={classes.wrapper}>
                                <div className={cn(classes.preTitle, 'textCaps')}>
                                    <p>Бренд</p>
                                </div>
                                <h2>{product.designer && product.designer.name}</h2>
                                <div dangerouslySetInnerHTML={{ __html: product.designer && product.designer.description }}></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProductCardBanner
