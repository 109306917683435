import React, { useState, useRef } from 'react'
import classes from './ProductGallery.module.sass'
import cn from 'classnames'
import { Modal } from 'reactstrap'
import SliderArrows from '../../../common/svg/SliderArrows'
import ProductGalleryMobile from './ProductGalleryMobile'
import noPhoto from '../../../../assets/img/No_Photo_2.png'

import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'


SwiperCore.use([Navigation, Pagination])

const ProductGallery = ({ gallery, pM }) => {
    const prevRef = useRef(null)
    const nextRef = useRef(null)

    const isSlider = gallery && gallery.length > 1
    const [modal, setModal] = useState(false)
    const [slideIndex, setSlideIndex] = useState(0)
    const handleClick = id => {
        setSlideIndex(id - 1)
        setModal(true)
    }

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={() => setModal(!modal)}
                centered
                className={classes.modal}
                contentClassName={classes.content}
                backdropClassName={classes.backdrop}
                backdropTransition={{ baseClass: classes.show, timeout: 300 }}
            >
                {isSlider ?
                    <div className={classes.sliderContainer}>
                        <div className={classes.close} onClick={() => setModal(false)}>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.6271 23.7255L12 34.533L13.3033 35.8585L23.9304 25.051L34.6967 36L36 34.6745L25.2338 23.7255L35.4601 13.3255L34.1568 12L23.9304 22.4L13.8432 12.1415L12.5399 13.467L22.6271 23.7255Z" fill="#242422" />
                            </svg>
                        </div>
                        <SliderArrows
                            customClasses={classes}
                            prevRef={prevRef}
                            nextRef={nextRef}
                        />
                        <div className={classes.pagination}></div>
                        <Swiper
                            className={classes.slider}
                            initialSlide={slideIndex}
                            speed={800}
                            slidesPerView={1}
                            loop
                            navigation={{
                                prevEl: prevRef.current ? prevRef.current : undefined,
                                nextEl: nextRef.current ? nextRef.current : undefined
                            }}
                            pagination={{
                                el: '.' + classes.pagination,
                                type: 'bullets',
                                clickable: true,
                                bulletClass: classes.bullet,
                                bulletActiveClass: classes.active
                            }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current
                                swiper.params.navigation.nextEl = nextRef.current
                                swiper.navigation.update()
                            }}
                        >
                            {gallery && gallery.map((image, i) =>
                                <SwiperSlide key={i}>
                                    <div
                                        className={classes.slideContainer}
                                    >
                                        <img
                                            src={process.env.REACT_APP_BACKEND_URL + image.img}
                                            alt={image.alt} className='fitContain'
                                        />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    :
                    gallery && gallery.length > 0 &&
                    <div className={classes.sliderContainer}>
                        <div className={classes.slideContainer}>
                            <div className={classes.close} onClick={() => setModal(false)}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.6271 23.7255L12 34.533L13.3033 35.8585L23.9304 25.051L34.6967 36L36 34.6745L25.2338 23.7255L35.4601 13.3255L34.1568 12L23.9304 22.4L13.8432 12.1415L12.5399 13.467L22.6271 23.7255Z" fill="#242422" />
                                </svg>
                            </div>
                            <img
                                src={process.env.REACT_APP_BACKEND_URL + gallery[0].img}
                                alt={gallery[0].alt} className='fitContain'
                            />
                        </div>
                    </div>
                }
            </Modal>
            <div className={classes.gallery}>
                {pM ?
                    <ProductGalleryMobile
                        gallery={gallery}
                        isSlider={isSlider}
                    />
                    :
                    gallery && gallery.length > 0 && gallery[0].img !== '' ?
                        gallery.filter((_, i) => i < 3).map((image, i) =>
                            <div
                                key={i}
                                className={classes.imgContainer}
                                onClick={() => handleClick(i + 1)}
                            >
                                <img
                                    src={process.env.REACT_APP_BACKEND_URL + image.img}
                                    alt={image.alt}
                                    className='fitContain'
                                />
                            </div>
                        )
                        :
                        <div className={cn(classes.imgContainer, classes.noPhoto)}>
                            <img src={noPhoto} alt='' className='fitContain'/>
                        </div>
                }

            </div>
        </>
    )
}

export default ProductGallery
