import React from 'react'
import AccountListItem from './AccountListItem'

const AccountLists = ({ 
    basket, 
    lists, 
    baseUrl, 
    isAuth, 
    renameSuccess, 
    setRenameSuccess, 
    renameList, 
    callModal,
    hidden,
    payment,
    isAccount,
    tracking,
    pM,
    TM
}) => {
    return (
        isAuth ?
            lists.map(l =>
                <AccountListItem
                    key={l.id}
                    lists={lists}
                    id={l.id}
                    title={l.name}
                    value={l.id}
                    date={l.created_at}
                    baseUrl={baseUrl}
                    isAccount={isAccount}
                    renameSuccess={renameSuccess}
                    setRenameSuccess={setRenameSuccess}
                    renameList={renameList}
                    hidden={hidden}
                    callModal={callModal}
                    payment={payment}
                    tracking={tracking}
                    pM={pM}
                    TM={TM}
                />
            ) : 
            <>
                <AccountListItem
                    title={basket && basket.name}
                    value={basket && basket.id}
                    baseUrl={baseUrl}
                    callModal={callModal}
                    payment={payment}
                    pM={pM}
                    TM={TM}
                    basket
                />
            </>
    )
}

export default AccountLists
