import React, { useState, useRef, useEffect } from 'react'
import classes from './CompilationItem.module.sass'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import BorderButton from '../common/buttons/BorderButton/BorderButton'
import Point from '../common/Points/Point'
import Product from '../common/Product/Product'
import CompilationPrevNext from './CompilationPrevNext'
import { withList } from '../../hoc/withList'

const CompilationItem = ({
    compilation,
    compilationItem,
    addClick,
    getCompilationItem,
    lists,
    getLists,
    isAuth,
    addCompilationGoods,
    compilationInCart,
    isCompilationInCart
}) => {

    const containerRef = useRef()
    const { id } = useParams()

    useEffect(() => {
        getCompilationItem(id)
    }, [getCompilationItem, id])

    useEffect(() => {
        !isAuth && 
        compilationItem.id && 
        isCompilationInCart(!isAuth && compilationItem.id)
    }, [compilationItem.id, isAuth, isCompilationInCart])

    useEffect(() => {
        !isAuth && lists.length < 1 && getLists()
    }, [lists, getLists, isAuth])

    const addClickHandler = () => {
        if (isAuth) {
            addClick({
                longtitle: compilationItem.title,
                comp_id: compilationItem.id
            })
        } else {
            !compilationInCart && addCompilationGoods(
                lists[0].id,
                compilationItem.id,
                isAuth
            )
        }
    }

    const btnNamePicker = () => {
        if (isAuth) {
            return 'Добавить  всю подборку в список'
        }
        if (!compilationInCart) {
            return 'Добавить  всю подборку в корзину'
        }
        return 'Перейти в корзину'
    }

    const [landscape, setLandscape] = useState(true)
    const handleLoad = e => {
        const width = e.target.naturalWidth
        const height = e.target.naturalHeight
        setLandscape(width > height)
    }

    const getPrevNext = () => {
        const currentIndex = compilation.findIndex(f =>
            f.path === compilationItem.path
        )
        const prev = currentIndex > 0 &&
            compilation.filter((_, i) => i === currentIndex - 1).map((el, i) =>
                <CompilationPrevNext
                    key={i}
                    name='Предыдущая подборка'
                    title={el.title}
                    path={el.path}
                    img={el.photo}
                    shortDescription={el.short_description}
                />
            )
        const next = currentIndex < compilation.length - 1 &&
            compilation.filter((_, i) => i === currentIndex + 1).map((el, i) =>
                <CompilationPrevNext
                    key={i}
                    name='Следующая подборка'
                    title={el.title}
                    path={el.path}
                    img={el.photo}
                    shortDescription={el.short_description}
                />
            )
        return [prev, next]
    }

    return (
        <Container>
            <div className={classes.title}>
                <Row className='justify-content-between'>
                    <Col xs={12} md={8} xl={6} className='col'>
                        <div className={cn(classes.text, landscape || classes.portrait)}>
                            <h2>{compilationItem.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: compilationItem.description }}></div>
                        </div>
                    </Col>
                    <Col xs={12} md={landscape ? 12 : 6} className='col'>
                        <div
                            className={cn(classes.image, landscape && classes.landscape)}
                            ref={containerRef}
                        >
                            <div className={classes.wrapper}>
                                <img
                                    onLoad={(e) => handleLoad(e)}
                                    src={process.env.REACT_APP_BACKEND_URL + compilationItem.photo}
                                    alt={compilationItem.title}
                                    className='fitCover'
                                />
                                {compilationItem.goods && compilationItem.goods.map(item =>
                                    item.coordinates &&
                                    <Point
                                        key={item.good.id}
                                        title={item.good.title}
                                        category={item.good.category_path}
                                        subcategory={item.good.subcategory_path}
                                        path={item.good.good_path}
                                        top={item.coordinates[1]}
                                        left={item.coordinates[0]}
                                        containerRef={containerRef}
                                    />
                                )}
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            <div className={classes.compilation}>
                <h2>В этой подборке:</h2>
                <Row className={classes.row}>
                    {compilationItem.goods && compilationItem.goods.map(item =>
                        <Col key={item.good.id} xs={12} md={4} lg={3} className={classes.col}>
                            <Product {...item.good} />
                        </Col>
                    )}
                </Row>
            </div>
            <div className={classes.button}>
                <BorderButton
                    name={btnNamePicker()}
                    to={!isAuth && compilationInCart ? '/account' : ''}
                    onClick={addClickHandler}
                />
            </div>
            <div className={classes.others}>
                <Row className={cn(classes.row, 'mx-xs-0 mx-md-n4')}>
                    {compilation.length > 1 && getPrevNext()}
                </Row>
            </div>
        </Container>
    )
}

export default withList(CompilationItem)