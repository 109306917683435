import React, { useEffect } from 'react'
import classes from './Howto.module.sass'
import cn from 'classnames'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import BasketLink from '../Catalog/BasketLink/BasketLink'

const Howto = ({ content, getStatic, isAuth, hasGoods, fetching }) => {
    const { pathname } = useLocation()

    useEffect(() => {
        const path = pathname.replace(/\//g, '')
        getStatic(path)
    }, [pathname, getStatic])

    return (
        <>
            <Breadcrumbs />
            <section className={cn(classes.howTo, 'preFooterDec', 'fetched', fetching && 'loading')}>
                <Container>
                    <Row>
                        <Col xs={12} md={9} xl={6} className='col'>
                            <div
                                className={classes.content}
                                dangerouslySetInnerHTML={{ __html: content }}
                            ></div>
                        </Col>
                    </Row>
                </Container>
                <BasketLink
                    enabled={!isAuth}
                    notEmpty={hasGoods}
                />
            </section>
        </>
    )
}

export default Howto
