import { staticAPI, designersAPI } from '../api/api'
import { callModal } from './modalsReducer'

const SET_OFFER = 'static/SET_OFFER'
const SET_STATIC = 'static/SET_STATIC'
const SET_CONTACTS = 'static/SET_CONTACTS'
const SET_FETCHING = 'static/SET_FETCHING'

const initialState = {
    staticPage: {},
    offer: {},
    fetching: false,
    email: null,
    phone: null,
    youtube: null,
    instagram: null,
    houzz: null,
    pinterest: null
}

const staticReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FETCHING: {
            return {
                ...state,
                fetching: action.f
            }
        }
        case SET_OFFER: {
            return {
                ...state,
                offer: action.offer
            }
        }
        case SET_STATIC: {
            return {
                ...state,
                staticPage: action.content
            }
        }
        case SET_CONTACTS: {
            return {
                ...state,
                email: action.email,
                phone: action.phone,
                youtube: action.youtube,
                instagram: action.instagram,
                houzz: action.houzz,
                pinterest: action.pinterest
            }
        }
        default:
            return state
    }
}

export const setFetching = f => ({ type: SET_FETCHING, f })
export const setOffer = offer => ({ type: SET_OFFER, offer })
const setStatic = content => ({ type: SET_STATIC, content })
const setContacts = (email, phone, youtube, instagram, houzz, pinterest) => ({
    type: SET_CONTACTS, email, phone, youtube, instagram, houzz, pinterest
})

export const getOffer = () => async dispatch => {
    try {
        const data = await staticAPI.getOffer()
        dispatch(setOffer(data.ad))
    } catch (error) {
        console.log(error)
    }
}

export const putDesigner = (studio_name, phone, portfolio_url, captcha_token) => async dispatch => {
    try {
        await designersAPI.putDesigner(studio_name, phone, portfolio_url, captcha_token)
        dispatch(callModal('app_success'))
    } catch (error) {
        dispatch(callModal('app_error'))
        console.log(error)
    }
}

export const getStatic = id => async dispatch => {
    try {
        const data = await staticAPI.getStatic(id)
        if (id === 'contacts') {
            dispatch(setContacts(
                data.email,
                data.phone,
                data.youtube,
                data.instagram,
                data.houzz,
                data.pinterest
            ))
        } else {
            dispatch(setStatic(data))
        }
    } catch (error) {
        console.log(error)
    }
}

export default staticReducer