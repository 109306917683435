import React, {useState, useEffect} from 'react'
import classes from './FilterColor.module.sass'
import { TinyColor } from '@ctrl/tinycolor'

const FilterColor = ({ el, register, getChangeEvent }) => {

    const [check, setCheck] = useState()

    useEffect(() => getChangeEvent(), [check, getChangeEvent])

    const isBorder = c => {
        const color = new TinyColor(c)
        return color.isLight()
    }

    return (
        <div className={classes.filter}>
            <div className={classes.title}>
                <p>{el.title}</p>
            </div>
            <div className={classes.colors}>
                {el && el.items.map((item, i) =>
                    <div key={i} className={classes.item}>
                        <input 
                            type='checkbox' 
                            name={el.id} 
                            value={item} 
                            ref={register} 
                            id={`color_${item}`} 
                            onChange={e => setCheck([e.target.id, e.target.checked])}
                        />
                        <label 
                            htmlFor={`color_${item}`} 
                            style={{ background: item }} 
                            className={isBorder(item) ? classes.border : ''}
                        ></label>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FilterColor