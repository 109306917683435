import React from 'react'
import { withForm } from '../../../hoc/withForm'
import { Modal } from 'reactstrap'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'

const AddListModal = ({
    modal: { id, title, open },
    useForm: { handleSubmit, register, errors },
    lists,
    modalRef,
    callModal,
    createList,
    defaultValueIndex,
    setDefaultValueIndex }) => {

    const isExist = title => !lists.some(list => list.name === title)

    const onSubmit = d => {
        createList(d.new_list, 'account')
        d.new_list === `Список для оплаты ${defaultValueIndex}` && setDefaultValueIndex()
    }

    return (
        <Modal
            isOpen={open}
            contentClassName='commonModal'
            centered
        >
            <div className='wrapper' ref={modalRef}>
                <div className='inner'>
                    <div className='closeBtn' onClick={() => callModal(id)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                        </svg>
                    </div>
                    <h3>{title}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='formGroup'>
                            <input
                                type='text'
                                name='new_list'
                                placeholder={`Список для оплаты ${defaultValueIndex}`}
                                defaultValue={`Список для оплаты ${defaultValueIndex}`}
                                ref={register({
                                    required: true,
                                    maxLength: 20,
                                    validate: isExist
                                })}
                            />
                            <span className='error textSmall'>
                                {errors.new_list?.type === 'required' && <p>обязательное поле</p>}
                                {errors.new_list?.type === 'validate' && <p>это название уже занято</p>}
                                {errors.new_list?.type === 'maxLength' && <p>слишком длинное название</p>}
                            </span>
                        </div>
                        <div className='altBtnContainer'>
                            <BorderButton
                                name='Создать'
                                type='submit'
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default withForm(AddListModal)
