import React from 'react'
import { Modal } from 'reactstrap'
import classes from './AddToListSuccess.module.sass'
import cn from 'classnames'
import { Link } from 'react-router-dom'

const AddToListSuccess = ({ 
    modalData: { img, longtitle, list, value }, 
    setModal, 
    successModalOpen 
}) => {
    return (
        <Modal
            isOpen={successModalOpen}
            toggle={() => setModal('success')}
            centered
            className={cn(classes.modal, img || classes.compilation)}
        >
            <div className={classes.container}>
                <button onClick={() => setModal('success')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                    </svg>
                </button>
                <div className={classes.imgContainer}>
                    <img src={process.env.REACT_APP_BACKEND_URL + img} alt={longtitle} />
                </div>
                <div className={classes.textContainer}>
                    <div className={classes.title}>
                        <h3>{`${img ? '' : 'Подборка '}«${longtitle}» добавлен${img ? '' : 'а'} в список «${list}»`}</h3>
                    </div>
                    <div className={cn(classes.link, "textSmall")}>
                        <Link onClick={() => setModal('success')} to={ value ? {
                            pathname: `/account/lists/${value}`,
                            state: { account: value }
                        } : ''}>Перейти к списку?</Link>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddToListSuccess
