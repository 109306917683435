import React from 'react'
import classes from './QuantityField.module.sass'

const QuantityField = ({ input: { value, onChange } }) => {

    const handleChange = (e) => {
        onChange(e.target.value.replace(/\D+/g, ''))
    }
    const handleKeyDown = (e) => {
        const numVal = e.target.value.replace(/\D+/g, '')
        if (e.keyCode === 8) {
            e.preventDefault()
            onChange(numVal.slice(0, -1))
        }
    }
    const handleBlur = (e) => {
        const numVal = e.target.value.replace(/\D+/g, '')
        numVal === '' && onChange(value = 1)
    }

    return (
        <div className={classes.container}>
            <button onClick={() => onChange(value.replace(/\D+/g, '') - 1)} type='button'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="13" y="7.44434" width="1.11111" height="10" transform="rotate(90 13 7.44434)" fill="#242422" />
                </svg>
            </button>
            <input
                type='text'
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
            />
            <button onClick={() => onChange(Number(value.replace(/\D+/g, '')) + 1)} type='button'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7.44531" y="3" width="1.11111" height="10" fill="#242422" />
                    <rect x="13" y="7.44434" width="1.11111" height="10" transform="rotate(90 13 7.44434)" fill="#242422" />
                </svg>
            </button>
        </div>
    )
}

export default QuantityField
