import React from 'react'
import classes from './AsideButton.module.sass'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const AsideButton = ({ opened, onClick }) => {
    const classNames = {
        enter: classes.enter,
        enterDone: classes.enterDone,
        exitActive: classes.exitActive
    }

    return (
        <div className={classes.button}>
        <SwitchTransition mode='out-in'>
            <CSSTransition
                key={opened}
                timeout={50}
                classNames={classNames}
            >
                {opened ?
                    <button onClick={onClick}>
                        <p>Закрыть разделы каталога</p>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.31354 7.86275L2 13.2665L2.65167 13.9292L7.96521 8.52548L13.3483 14L14 13.3373L8.61688 7.86275L13.7301 2.66274L13.0784 2L7.96521 7.20001L2.9216 2.07077L2.26993 2.7335L7.31354 7.86275Z" fill="#242422" />
                        </svg>
                    </button>
                    :
                    <button onClick={onClick}>
                        <p>Разделы каталога</p>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 3H14.5V4H5.5V3ZM5.5 12H14.5V13H5.5V12ZM5.5 7.5H14.5V8.5H5.5V7.5ZM2.5 7.5H3.5V8.5H2.5V7.5ZM2.5 3H3.5V4H2.5V3ZM2.5 12H3.5V13H2.5V12Z" fill="#242422" />
                        </svg>
                    </button>
                }
            </CSSTransition>
        </SwitchTransition>
        </div>
    )
}

export default AsideButton
