import React, { useState, useEffect } from 'react'
import classes from './ToTop.module.sass'
import cn from 'classnames'
import { Transition } from 'react-transition-group'

const ToTop = ({ pM }) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (!pM) {
            const handleScroll = () => setVisible(window.scrollY > 500)
            document.addEventListener('scroll', handleScroll)
            return () => document.removeEventListener('scroll', handleScroll)
        }
    }, [pM])

    const duration = 300
    const defaultStyle = {
        transition: `opacity ${duration}ms ease`,
        opacity: 0,
    }
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 }
    }

    return (
        <Transition
            in={pM || visible}
            timeout={300}
            mountOnEnter
            unmountOnExit
        >
            {state => (
                <button
                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                    className={cn(classes.toTop, pM && classes.mobile)}
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.25 1.95996L8.25 1.95996L8.25 14L7.25 14L7.25 1.95996Z" fill="#242422" />
                        <path d="M7.75 1.45789L7.75 2.46215L7.25006 2.96431L5.24996 4.97288L5.25 3.96861L7.75 1.45789Z" fill="#242422" />
                        <path d="M7.75 1.45789L7.75 2.46215L8.25 2.96433L10.25 4.97281L10.25 3.96855L7.75 1.45789Z" fill="#242422" />
                    </svg>
                </button>
            )}
        </Transition>
    )
}

export default ToTop
