import React, { useRef } from 'react'
import classes from './AccountQuantity.module.sass'
import cn from 'classnames'
import { connect } from 'react-redux'
import AutosizeInput from 'react-input-autosize'
import { deleteGood, updateGood } from '../../../../redux/accountReducer'

const AccountQuantity = ({
    value,
    maxQuantity,
    isAccount,
    basket,
    updateGood,
    deleteGood,
    listId,
    entryId,
    color
 }) => {

    const inputRef = useRef()

    const setInputVal = val => updateGood(listId, entryId, val, color)

    const handleRemove = () => deleteGood(listId, entryId)

    const handleChange = v => {
        const val = Number(v.replace(/\D+/g, ''))
        setInputVal(val > maxQuantity ? maxQuantity : val < 1 ? '' : val)
    }

    const handleBlur = v => {
        const val = Number(v.replace(/\D+/g, ''))
        setInputVal(val < 1 ? 1 : val)
    }

    const handleClick = type => {
        const val = Number(inputRef.current.input.value.replace(/\D+/g, ''))
        setInputVal(
            type === 'dec' ?
                val > 1 ? value - 1 : val :
                val < maxQuantity ? value + 1 : val
        )
    }

    const handleKeyDown = e => {
        const val = e.target.value.replace(/\D+/g, '')
        if (e.keyCode === 8) {
            e.preventDefault()
            setInputVal(Number(val.slice(0, -1)))
        }
    }

    return (
        <div className={cn(
            classes.quantityContainer,
            isAccount && classes.isAccount,
            basket && classes.basket
        )}>
            <div
                className={classes.delete}
                onClick={handleRemove}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 1H6V2H10V1ZM2 3V4H3V14C3 14.6 3.4 15 4 15H12C12.6 15 13 14.6 13 14V4H14V3H2ZM4 14V4H12V14H4ZM6 6H7V12H6V6ZM10 6H9V12H10V6Z" fill="#F33C42" />
                </svg>
            </div>
            <div className={classes.quantity}>
                <button onClick={() => handleClick('dec')}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="13" y="7.44446" width="1.11111" height="10" transform="rotate(90 13 7.44446)" fill="#242422" />
                    </svg>
                </button>
                <AutosizeInput
                    name='quantity'
                    type='text'
                    value={value === 0 ? '' : `${value} шт.`}
                    onChange={(e) => handleChange(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    ref={inputRef}
                />
                <button onClick={() => handleClick('inc')}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="7.44446" y="3" width="1.11111" height="10" fill="#242422" />
                        <rect x="13" y="7.44446" width="1.11111" height="10" transform="rotate(90 13 7.44446)" fill="#242422" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        maxQuantity: state.account.maxQuantity
    }
}

export default connect(mapStateToProps, { updateGood, deleteGood })(AccountQuantity)