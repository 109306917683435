import React from 'react'
import NumberFormat from 'react-number-format'

const Tel = ({ to, children }) => {
    const replaced = to ? to.replace(/\(0\)|\s+/g,'') : ''
    return <a href={`tel:${replaced}`}>
        <NumberFormat value={children} displayType={'text'} format="+# ### ### ## ##" />
    </a>
}

export default Tel
