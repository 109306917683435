import React from 'react'
import classes from './ProductGalleryMobile.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import noPhoto from '../../../../assets/img/No_Photo.png'

const ProductGalleryMobile = ({ gallery, isSlider }) => {
    return (
        <>
            {isSlider ?
                <div className={classes.sliderContainer}>
                    <Swiper
                        className={classes.slider}
                        speed={500}
                        slidesPerView={2}
                        spaceBetween={16}
                        loop
                    >
                        {gallery && gallery.map((image, i) =>
                            <SwiperSlide key={i}>
                                <div className={classes.image} >
                                    <img
                                        src={process.env.REACT_APP_BACKEND_URL + image.thumb}
                                        alt={image.alt} className='fitContain'
                                    />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                :
                gallery && gallery.length > 0 ?
                <div className={classes.imageContainer}>
                    <div className={classes.image} >
                        <img
                            src={process.env.REACT_APP_BACKEND_URL + gallery[0].thumb}
                            alt={gallery[0].alt} className='fitContain'
                        />
                    </div>
                </div>
                :
                <div className={classes.imageContainer}>
                    <div className={classes.image} >
                        <img src={noPhoto} alt='' className='fitContain'/>
                    </div>
                </div>
            }
        </>
    )
}

export default ProductGalleryMobile
