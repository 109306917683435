import React from 'react'
import classes from './SliderArrows.module.sass'
import cn from 'classnames'

const SliderArrows = ({customClasses, prevRef, nextRef}) => {
    return (
        <>
            <div className={cn(classes.prev, customClasses.prev, 'prev')} ref={prevRef}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.92383 24.5L9.92383 22.5L42.0007 22.5L42.0007 24.5L9.92383 24.5Z" fill="#242422" />
                    <path d="M8.91896 23.499L10.9275 23.499L11.9318 24.4989L15.9489 28.4991L13.9404 28.499L8.91896 23.499Z" fill="#242422" />
                    <path d="M8.91896 23.499L10.9275 23.499L11.9318 22.499L15.9488 18.499L13.9403 18.4991L8.91896 23.499Z" fill="#242422" />
                </svg>
            </div>
            <div className={cn(classes.next, customClasses.next, 'next')} ref={nextRef}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M38.0762 22.5L38.0762 24.5L5.99927 24.5L5.99927 22.5L38.0762 22.5Z" fill="#242422" />
                    <path d="M39.081 23.501L37.0725 23.501L36.0682 22.5011L32.0511 18.5009L34.0596 18.501L39.081 23.501Z" fill="#242422" />
                    <path d="M39.081 23.501L37.0725 23.501L36.0682 24.501L32.0512 28.501L34.0597 28.5009L39.081 23.501Z" fill="#242422" />
                </svg>
            </div>
        </>
    )
}

export default SliderArrows
