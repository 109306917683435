import React, { useState, useRef } from 'react'
import AddToList from '../components/Home/HomeCompilation/modals/AddToList'
import AddToListSuccess from '../components/Home/HomeCompilation/modals/AddToListSuccess'
import OneClick from '../components/Home/HomeCompilation/modals/OneClick'
import {
    addGoodToList,
    addCompilationGoods,
    getLists,
    sessionThunk,
    setModal,
    createList,
    deleteList,
    putOneClickOrder
} from '../redux/accountReducer'
import { connect } from 'react-redux'
import store from '../redux/reduxStore'

const mapStateToProps = state => {
    return {
        lists: state.account.lists,
        addModalOpen: state.account.addModalOpen,
        successModalOpen: state.account.successModalOpen,
        oneClickModalOpen: state.account.oneClickModalOpen,
        products: state.catalog.products,
        isAuth: state.account.isAuth
    }
}

const useModal = initialValue => {
    const [modal, setToModal] = useState(initialValue)
    const addClick = (...args) => {
        setToModal({ ...args[0] })
        store.dispatch(setModal('add'))
    }
    const addOneClick = (...args) => {
        setToModal({ ...args[0] })
        store.dispatch(setModal('oneClick'))
    }
    return {
        modal,
        setToModal,
        addClick,
        addOneClick
    }
}

export const withList = (WrappedComponent) => {
    const ListsContainer = ({
        addGoodToList,
        addCompilationGoods,
        lists,
        getLists,
        sessionThunk,
        addModalOpen,
        successModalOpen,
        oneClickModalOpen,
        setModal,
        createList,
        deleteList,
        putOneClickOrder,
        isAuth,
        ...props
    }) => {
        const addModal = useModal({})
        const clickModal = useModal({})
        const successModal = useModal({})

        const reRef = useRef()

        const submit = formData => {
            const color = addModal.modal.color
            const reg = /^#[a-z0-9]{3}([a-z0-9]{3})?$/i
            const isHex = reg.test(color)
            addModal.modal.comp_id ?
                addCompilationGoods(
                    isAuth ? formData.list.value : lists[0].id, 
                    addModal.modal.comp_id
                )
                :
                addGoodToList(
                    isAuth ? formData.list.value : lists[0].id,
                    addModal.modal.id,
                    formData.quantity,
                    isHex && color
                )
            successModal.setToModal({
                img: addModal.modal.img,
                longtitle: addModal.modal.longtitle,
                list: isAuth ? formData.list.label : lists[0].name,
                value: isAuth ? formData.list.value : lists[0].id,
            })
        }

        const submitOneClick = async data => {
            const token = await reRef.current.executeAsync()
            reRef.current.reset()
            putOneClickOrder(
                clickModal.modal.title,
                `+${data.phone.replace(/[^0-9.]/g, '')}`,
                token
            )
        }

        return (
            <>
                <AddToList
                    addModalOpen={addModalOpen}
                    setModal={setModal}
                    modalData={addModal.modal}
                    lists={lists}
                    getLists={getLists}
                    createList={createList}
                    deleteList={deleteList}
                    onSubmit={submit}
                />
                <AddToListSuccess
                    modalData={successModal.modal}
                    successModalOpen={successModalOpen}
                    setModal={setModal}
                />
                <OneClick
                    modalData={clickModal.modal}
                    onSubmit={submitOneClick}
                    oneClickModalOpen={oneClickModalOpen}
                    setModal={setModal}
                    reRef={reRef}
                />
                <WrappedComponent
                    addClick={addModal.addClick}
                    addOneClick={clickModal.addOneClick}
                    lists={lists}
                    getLists={getLists}
                    addGoodToList={addGoodToList}
                    addCompilationGoods={addCompilationGoods}
                    isAuth={isAuth}
                    {...props} />
            </>
        )
    }

    return connect(mapStateToProps, {
        addGoodToList,
        addCompilationGoods,
        getLists,
        sessionThunk,
        setModal,
        createList,
        deleteList,
        putOneClickOrder
    })(ListsContainer)
}
