import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import classes from './Search.module.sass'
import cn from 'classnames'
import { setSearch } from '../../../../redux/catalogReducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

const Search = ({ isMenu, handleSubmit, reset, setSearch }) => {
    const history = useHistory()
    const [focus, setFocus] = useState(false)

    const submit = d => {
        setSearch(d.search)
        window.location.href.indexOf('/catalog/search') > -1 ||
        history.push('/catalog/search')
        reset()
    }

    const onFocus = () => setFocus(true)

    const onBlur = (e) => {
        setTimeout(function () {
            setFocus(false)
            reset()
            e.preventDefault()
        }, 200)
    }

    return (
        <div className={cn(
            classes.Search, 
            isMenu ? classes.isMenu : classes.isHeader, 
            focus ? classes.focus : ''
        )}>
            <form onSubmit={handleSubmit(submit)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 14.3L10.7 10C12.6 7.70001 12.3 4.20001 10 2.30001C7.70001 0.400014 4.20001 0.700014 2.30001 3.00001C0.400014 5.30001 0.700014 8.80001 3.00001 10.7C5.00001 12.4 8.00001 12.4 10 10.7L14.3 15L15 14.3ZM2.00001 6.50001C2.00001 4.00001 4.00001 2.00001 6.50001 2.00001C9.00001 2.00001 11 4.00001 11 6.50001C11 9.00001 9.00001 11 6.50001 11C4.00001 11 2.00001 9.00001 2.00001 6.50001Z" fill="#FEFEFC" />
                </svg>
                <Field name='search' component='input' type='text' onFocus={onFocus} onBlur={onBlur} />
                <button type='submit'>Найти</button>
            </form>
        </div>
    )
}

export default compose(
    connect(null, { setSearch }),
    reduxForm({ form: 'search' })
)(Search)
