import classes from './AddToList.module.sass'
import React, { useEffect } from 'react'
import { Modal } from 'reactstrap'
import cn from 'classnames'
import { reduxForm, Field } from 'redux-form'
import BorderButton from '../../../common/buttons/BorderButton/BorderButton'
import QuantityField from '../../../common/fields/QuantityField'
import ListSelectField from '../../../common/fields/ListSelectField'
import { required } from '../../../common/validators'
import { useAccount } from '../../../AccountContext'

const AddToList = ({
    modalData: { img, longtitle },
    addModalOpen,
    setModal,
    lists,
    getLists,
    createList,
    deleteList,
    handleSubmit,
    reset,
    initialize
}) => {
    const context = useAccount()

    useEffect(() => {
        if (addModalOpen && lists.length < 1) {
            getLists()
        }
    }, [getLists, lists.length, addModalOpen])

    useEffect(() => {
        setTimeout(() => 
            !addModalOpen ? reset() : initialize({ quantity: 1 })
        , 500)
    }, [addModalOpen, reset, initialize])

    const minMax = value => {
        if (value < 1 && value !== '') {
            return 1
        } else if (value > 500) {
            return 500
        } else if (value === '') {
            return ''
        } else {
            return value
        }
    }

    const unitFormatter = unit => value => value !== undefined ? value + ' ' + unit : ''

    return (
        <Modal
            isOpen={addModalOpen}
            toggle={() => setModal('add')}
            centered
            className={cn(classes.modal, img || classes.compilation)}
        >
            <div className={classes.container}>
                <button onClick={() => setModal('add')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.31354 9.86275L4 15.2665L4.65167 15.9292L9.96521 10.5255L15.3483 16L16 15.3373L10.6169 9.86275L15.7301 4.66274L15.0784 4L9.96521 9.20001L4.9216 4.07077L4.26993 4.7335L9.31354 9.86275Z" fill="#8F8F8F" />
                    </svg>
                </button>
                <div className={classes.imgContainer}>
                    <img src={process.env.REACT_APP_BACKEND_URL + img} alt={longtitle} className='fitContain'/>
                </div>
                <div className={classes.textContainer}>
                    <div className={cn(classes.title, 'textSmall')}>
                        <p>{`Добавить ${img ? '' : 'подборку '}«${longtitle}» в список:`}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.select}>
                            {context.isRegister ?
                                <Field
                                    name='list'
                                    component={ListSelectField}
                                    props={{ lists, createList, deleteList }}
                                    validate={[required]}
                                /> :
                                <div className={classes.guest}>
                                    <p>Корзина</p>
                                </div>
                            }
                        </div>
                        <div className={classes.quantity}>
                            <Field
                                name='quantity'
                                component={QuantityField}
                                normalize={minMax}
                                format={unitFormatter('шт.')}
                            />
                        </div>
                        <BorderButton
                            name='Добавить'
                            type='submit'
                            style={{ width: '100%' }}
                        />
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default reduxForm({
    form: 'addToList',
    initialValues: { quantity: 1 },
    enableReinitialize: true
})(AddToList)