import React, { useState, useEffect } from 'react'
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import CatalogAsideItem from './CatalogAsideItem'
import classes from './CatalogAsideCategory.module.sass'
import cn from 'classnames'
import logo from '../../assets/img/logo-black.svg'

const CatalogAsideCategory = ({ pathEl, title, subcategories, setOpenMobileAside, pM }) => {
    const { url, params: { category } } = useRouteMatch()
    const { fromProduct } = useLocation()
    const [open, setOpen] = useState(0)

    useEffect(() => url === '/catalog' && window.scrollTo(0, 0), [url])

    useEffect(() => {
        fromProduct && category === pathEl ? setOpen('auto') : setOpen(0)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => {
        if (pM) {
            (title === 'Весь каталог' || (subcategories && subcategories.length < 1)) &&
                setOpenMobileAside(0)
        }
        setOpen(open === 0 ? 'auto' : 0)
    }

    return (
        <div className={classes.item}>
            <NavLink
                to={pathEl === null ? '/catalog' : `/catalog/${pathEl}`}
                onClick={handleClick}
                className={cn(
                    classes.category,
                    subcategories && subcategories.length < 1 ? classes.empty : null,
                    open === 'auto' && classes.active
                )}
            >
                {title === 'Sam Riseberg' ? <img src={logo} alt={title} /> : title}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                </svg>
            </NavLink>
            {subcategories &&
                <AnimateHeight
                    duration={300}
                    height={open}
                >
                    <div className={classes.sub}>
                        <ul>
                            {subcategories.map(el =>
                                <CatalogAsideItem
                                    key={el.id}
                                    title={el.name}
                                    path={el.path}
                                    category={pathEl}
                                    setOpenMobileAside={setOpenMobileAside}
                                    pM={pM}
                                />
                            )}
                        </ul>
                    </div>
                </AnimateHeight>
            }
        </div>
    )
}

export default CatalogAsideCategory
