import React, { useState, useEffect } from 'react'
import classes from './CheckoutData.module.sass'
import cn from 'classnames'
import MoneyFormat from '../../../tools/MoneyFormat'

const CheckoutDelivery = ({ register, errors, clearErrors, deliveryOpt, getDelivery }) => {
    const [check, setCheck] = useState()

    useEffect(() => {
        deliveryOpt.length > 0 && setCheck(deliveryOpt[0].id)
    }, [deliveryOpt])

    useEffect(() => clearErrors(['zip', 'city', 'street']), [check, clearErrors])

    const handleChange = (e, cost) => {
        setCheck(e.target.id)
        getDelivery(cost)
    }
    return (
        <div className={classes.deliveryData}>
            <div className={classes.checkboxes}>
                {deliveryOpt.length > 0 && deliveryOpt.map((o, i) =>
                    <div key={i} className={classes.item}>
                        <div className={classes.title}>
                            <div className='radioGroup'>
                                <input
                                    id={o.id}
                                    type='radio'
                                    name='deliveryType'
                                    value={o.id}
                                    onClick={(e) => handleChange(e, o.cost)}
                                    ref={register}
                                    readOnly
                                    checked={check === o.id}
                                />
                                <label htmlFor={o.id}><p>{o.name}</p></label>
                            </div>
                            <p><MoneyFormat value={o.cost} /></p>
                        </div>
                        {o.description &&
                            <div className={cn(classes.address, 'textSmall')}>
                                <p>{o.description}</p>
                            </div>
                        }
                    </div>
                )}



                {/* <div className={classes.item}>
                    <div className={classes.title}>
                        <div className='radioGroup'>
                            <input
                                id='delivery_1'
                                type='radio'
                                name='deliveryType'
                                value='pickup'
                                onClick={(e) => handleChange(e)}
                                ref={register}
                                readOnly
                                checked={check === 'delivery_1'}
                            />
                            <label htmlFor='delivery_1'><p>Самовывоз</p></label>
                        </div>
                        <p><MoneyFormat value='0' /></p>
                    </div>
                    <div className={cn(classes.address, 'textSmall')}>
                        <p>Санкт-Петербург, пр. Петербургский, 145, литера Б, подъезд 3, офис 1234</p>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.title}>
                        <div className='radioGroup'>
                            <input
                                id='delivery_2'
                                type='radio'
                                name='deliveryType'
                                value='tc'
                                onClick={(e) => handleChange(e)}
                                ref={register}
                                readOnly
                                checked={check === 'delivery_2'}
                            />
                            <label htmlFor='delivery_2'><p>Доставка TK</p></label>
                        </div>
                        <p><MoneyFormat value='2500' /></p>
                    </div>
                </div> */}
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <input
                    type='text'
                    name='zip'
                    placeholder='Индекс'
                    ref={register({ required: check !== 'selfpickup' })}
                />
                <span className='error textSmall'>
                    {errors.zip?.type === 'required' && <p>обязательное поле</p>}
                </span>
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <input
                    type='text'
                    name='city'
                    placeholder='Город'
                    ref={register({ required: check !== 'selfpickup' })}
                />
                <span className='error textSmall'>
                    {errors.city?.type === 'required' && <p>обязательное поле</p>}
                </span>
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <input
                    type='text'
                    name='street'
                    placeholder='Улица'
                    ref={register({ required: check !== 'selfpickup' })}
                />
                <span className='error textSmall'>
                    {errors.street?.type === 'required' && <p>обязательное поле</p>}
                </span>
            </div>
            <div className={cn(classes.formGroup, classes.inline, 'formGroup')}>
                <input type='text' name='house' placeholder='Дом' ref={register} />
                <input type='text' name='building' placeholder='Корпус' ref={register} />
                <input type='text' name='floor' placeholder='Этаж' ref={register} />
                <input type='text' name='apartment' placeholder='Кв.' ref={register} />
            </div>
            <div className={cn(classes.formGroup, 'formGroup')}>
                <input type='text' name='comment' placeholder='Комментарий к заказу' ref={register} />
            </div>
        </div>
    )
}

export default CheckoutDelivery
