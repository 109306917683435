import React, { useState, useEffect } from 'react'
import { components } from 'react-select'
import Select from 'react-select/creatable'
import { withBreakpoints } from '../../../hoc/withBreakpoints'

const ListSelectField = ({
    input,
    lists,
    createList,
    deleteList,
    meta: { touched, error },
    pM
}) => {

    const black = '#242422'
    const white = '#FEFEFC'
    const skin = '#D9917B'
    const red = '#F33C42'

    const colourStyles = {
        container: (provided) => ({ ...provided, width: pM ? '100%' : 260 }),
        control: (provided) => ({
            ...provided,
            minHeight: 40,
            backgroundColor: white,
            borderStyle: 'solid',
            borderWidth: .5,
            borderColor: touched ? ((error && red)) : black,
            borderRadius: 30,
            boxShadow: 'none',
            transition: 'border-color .3s ease',
            ':hover': {
                borderColor: skin
            }
        }),
        valueContainer: (provided) => ({ ...provided, padding: '0 16px' }),
        placeholder: (provided) => ({ ...provided, margin: 0, color: black }),
        input: (provided) => ({ ...provided, margin: 0 }),
        indicatorsContainer: (provided) => ({ ...provided, padding: '10px 16px' }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            'svg': {
                transition: 'transform .1s ease',
                transform: state.selectProps.menuIsOpen && 'rotate(-180deg)'
            }
        }),
        clearIndicator: (provided) => ({ ...provided, padding: 0 }),
        indicatorSeparator: () => ({ display: 'none' }),
        menu: (provided) => ({ ...provided, marginTop: 4, marginBottom: 4 }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? skin : white,
            color: state.isSelected ? white : black,
            overflow: 'hidden',
            padding: '8px 16px',
            transition: 'backgroundColor .3s ease, color .3s ease',
            ':hover': {
                backgroundColor: skin,
                color: white
            },
            ':active': {
                backgroundColor: white,
                color: black
            }
        })
    }

    const DownIcon = () => {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 14.25L5.625 9.875L6.5 9L10 12.5L13.5 9L14.375 9.875L10 14.25Z" fill="#242422" />
            </svg>
        )
    }
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <DownIcon />
            </components.DropdownIndicator>
        )
    }

    const CloseIcon = () => {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="7" fill="#242422" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.54236 9.9085L6 13.511L6.43445 13.9528L9.97681 10.3503L13.5656 14L14 13.5582L10.4113 9.9085L13.82 6.44182L13.3856 6L9.97681 9.46667L6.6144 6.04718L6.17995 6.489L9.54236 9.9085Z" fill="#FEFEFC" />
            </svg>
        )
    }
    const ClearIndicator = props => {
        return (
            <components.ClearIndicator {...props}>
                <CloseIcon />
            </components.ClearIndicator>
        )
    }

    const [value, setValue] = useState()
    const [options, setOptions] = useState([])
    const [current, setCurrent] = useState()
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        const initOptions = lists.map(list => (
            { value: list.id, label: list.name }
        ))
        const newList = initOptions.filter(o => options &&
            !options.some(v => v.value === o.value))
        if (newList.length === 1 && changed) {
            setCurrent(newList[0])
            setValue(newList[0])
        }
        setOptions(initOptions)
    }, [lists.length]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = e => {
        changed || setChanged(true)
        e ? setCurrent(e) :
            deleteList(current.value)
        setValue(e)
    }

    useEffect(() => input.onChange(value), [input, value])

    const handleCreate = e => {
        changed || setChanged(true)
        createList(e)
    }

    return (
        <>
            <Select
                {...input}
                name={input.name}
                // menuIsOpen
                isClearable
                components={{ DropdownIndicator, ClearIndicator }}
                placeholder={<div className='create'><p>Создать список</p></div>}
                formatCreateLabel={() => 'Добавить'}
                noOptionsMessage={() => 'Введите название списка'}
                onChange={handleChange}
                onCreateOption={handleCreate}
                onBlur={() => input.onBlur(value)}
                options={options}
                value={value}
                cacheOptions
                styles={colourStyles}
            />
            {touched && ((error && <span className='errorLabel'>{error}</span>))}
        </>
    )
}

export default withBreakpoints(ListSelectField)