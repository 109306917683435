import React from 'react'
import classes from './ProductPrice.module.sass'
import cn from 'classnames'
import MoneyFormat from '../../../tools/MoneyFormat'

const ProductPrice = ({ parentClasses, oldPrice, price, catalogProduct }) => {
    return (
        <div className={cn(classes.price, parentClasses.price)}>
            {oldPrice && price < oldPrice &&
                <div className={cn(classes.oldPrice, parentClasses.oldPrice)}>
                    <div className={cn(classes.sale, parentClasses.sale, 'textLabel')}>
                        <p>Sale</p>
                    </div>
                    <div className={cn(classes.old, parentClasses.old)}>
                        <p><MoneyFormat value={oldPrice} /></p>
                    </div>
                </div>
            }
            <div className={cn(
                classes.current, 
                parentClasses.current, 
                catalogProduct && classes.catalogProduct
            )}>
                <h3><MoneyFormat value={price} /></h3>
            </div>
        </div>
    )
}

export default ProductPrice
