import React from 'react'
import { Link } from 'react-router-dom'
import classes from './AccountProduct.module.sass'
import cn from 'classnames'
import ProductColor from '../../../common/Product/ProductColor'
import MoneyFormat from '../../../../tools/MoneyFormat'
import AccountQuantity from './AccountQuantity'

const AccountProduct = ({
    listId,
    entryId,
    isAccount,
    basket,
    defaultColor,
    quantity,
    index,
    product: {
        id,
        photos: gallery,
        title,
        category_path: category,
        subcategory_path: subcategory,
        good_path: path,
        props: params,
        price,
        old_price: oldPrice,
		designer_name
    }
}) => {
    return (
        <div className={classes.item}>
            <div className={classes.leftSide}>
                <Link
                    to={`/catalog/${!category ? 'all' : category}/${!subcategory ? 'all' : subcategory}/${path}`}
                    className={classes.imgContainer}
                >
                    {gallery && gallery.length > 0 &&
                        <img src={process.env.REACT_APP_BACKEND_URL + gallery[0].thumb} alt={gallery[0].alt} className='fitContain' />
                    }
                </Link>
                <div className={classes.titleContainer}>
                    <div className={cn(classes.brand, 'textSmall')}>
                        <p>{designer_name && designer_name}</p>
                    </div>
                    <Link to={`/catalog/${!category ? 'all' : category}/${!subcategory ? 'all' : subcategory}/${path}`}>
                        <h3>{title}</h3>
                    </Link>
                    <div className={classes.params}>
                        {params && params.filter((_, i) => i === 0).map(el =>
                            el.id === 'color' ?
                                <div key={el.id} className={cn(classes.param, classes.colors)}>
                                    <ProductColor
                                        index={index}
                                        productId={id}
                                        listId={listId}
                                        entryId={entryId}
                                        isAccount={isAccount}
                                        basket={basket}
                                        colors={el.value}
                                        quantity={quantity}
                                        defaultColor={defaultColor}
                                        size={8}
                                        accountProduct
                                    />
                                </div> :
                                <div key={el.id} className={cn(classes.param, 'textSmall')}>
                                    <p>
                                        {el.id === 'size' ?
                                            `${el.value[0]}x${el.value[1]}x${el.value[2]} ${el.value[3]}` :
                                            `${el.name}: ${el.value}`}
                                    </p>
                                </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.rightSide}>
                <div className={classes.priceContainer}>
                    <div className='textSmall'>
                        <p><MoneyFormat value={oldPrice * quantity} /></p>
                    </div>
                    <h3><MoneyFormat value={price * quantity} /></h3>
                </div>
                <div className={classes.quantityContainer}>
                    <AccountQuantity
                        value={quantity}
                        isAccount={isAccount}
                        basket={basket}
                        listId={listId}
                        color={defaultColor}
                        entryId={entryId}
                    />
                </div>
            </div>
        </div>
    )
}

export default AccountProduct
