import React from 'react'
import classes from './AccountEmpty.module.sass'
import BorderButton from '../../../common/buttons/BorderButton/BorderButton'

const AccountEmpty = ({ orders, list, callModal, checkout }) => {
    return (
        !checkout ?
            !orders && !list ?
                <div className={classes.empty}>
                    <h3>У вас пока нет ни одного списка</h3>
                    <BorderButton name='Создать' onClick={() => callModal('add_list')} />
                </div>
                : orders ?
                    <div className={classes.empty}>
                        <h3>Вы пока не оплачивали заказы. Выберите готовый список и нажмите Оформить заказ.</h3>
                        <BorderButton name='Мои списки' to='/account/lists/' />
                    </div>
                    : <div className={classes.empty}>
                        <h3>В этом списке пока нет товаров</h3>
                        <BorderButton name='Перейти в каталог' to='/catalog' />
                    </div>
            :
            <div className={classes.empty}>
                <h3>В этом списке пока нет товаров</h3>
                <BorderButton name='Перейти в каталог' to='/catalog' />
            </div>
    )
}

export default AccountEmpty
