import React from 'react'
import classes from './BorderButton.module.sass'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { withBreakpoints } from '../../../../hoc/withBreakpoints'

const BorderButton = ({ 
    name, 
    type, 
    onClick, 
    count, 
    icon, 
    style, 
    id, 
    to, 
    disabled,
    TM
}) => {
    return (
        to ?
            <Link
                to={to}
                className={cn(
                    classes.button, 
                    TM && classes.touch,
                    { 'disabledBtn': disabled }, 
                    'textButton'
                )}
            >
                {name}
            </Link> :
            <button
                onClick={onClick}
                type={type}
                className={cn(
                    classes.button, 
                    TM && classes.touch,
                    { 'disabledBtn': disabled }, 
                    'textButton'
                )}
                style={style}
                id={id}
            >
                {name}
                {(count === 0 || count) && <div className={cn(classes.count, 'textButton')}><p>{count}</p></div>}
                {icon &&
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 4.99995H3.39C4.71807 2.95891 7.23016 2.03412 9.56462 2.72684C11.8991 3.41956 13.5002 5.56487 13.5 7.99995H14.5C14.5028 5.26021 12.7874 2.81285 10.2111 1.88088C7.63474 0.948914 4.75062 1.73245 3 3.83995V1.99995H2V5.99995H6V4.99995ZM10 10.9999H12.61C11.2819 13.041 8.76985 13.9658 6.43539 13.2731C4.10092 12.5803 2.49985 10.435 2.5 7.99995H1.5C1.49718 10.7397 3.21256 13.187 5.78892 14.119C8.36527 15.051 11.2494 14.2674 13 12.1599V13.9999H14V9.99995H10V10.9999Z" fill="#242422" />
                    </svg>
                }
            </button>
    )
}

export default withBreakpoints(BorderButton)
