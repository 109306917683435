import React from 'react'

const FormPassField = ({register, errors}) => {
    return (
        <>
            <input
                type='password'
                name='password'
                placeholder='Пароль'
                ref={register({
                    required: true
                })}
            />
            <span className='error textSmall'>
                {errors.password?.type === 'required' && <p>обязательное поле</p>}
            </span>
        </>
    )
}

export default FormPassField
