import React, { useState, useEffect } from 'react'

const FilterInput = ({ i, el, propId, register, clear, isElChecked, reset, getChangeEvent }) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        clear && setChecked(false)
    }, [clear])

    useEffect(() => getChangeEvent(), [checked, getChangeEvent])

    useEffect(() => {
        isElChecked(checked, el)
    }, [checked, el, isElChecked])

    useEffect(() => {
        reset && setChecked(false)
    }, [reset])

    return (
        <li>
            <input
                type='checkbox'
                id={`${propId}_${el}`}
                name={propId}
                value={el}
                ref={register}
                onClick={() => setChecked(prev => !prev)}
                checked={checked}
                readOnly
            />
            <label htmlFor={`${el}_${i}`}>{el}</label>
        </li>
    )
}

export default FilterInput
