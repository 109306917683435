import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import classes from './HomeCompilation.module.sass'
import cn from 'classnames'
import { withBreakpoints } from '../../../hoc/withBreakpoints'
import HomeCompilationItem from './HomeCompilationItem'
import { compose } from 'redux'
import { withList } from '../../../hoc/withList'

const HomeCompilation = ({
    goods,
    addOneClick,
    addClick,
    isAuth,
    lists,
    getLists,
    addGoodToList,
    getHomeInCart,
    pT
}) => {
    useEffect(() => {
        !isAuth && lists && lists.length < 1 && getLists()
    }, [lists, getLists, isAuth])

    return (
        <Container>
            <Row className={cn(classes.row, 'justify-content-center')}>
                {goods.filter((_, i) => i < (pT ? 3 : 4)).map(p =>
                    <HomeCompilationItem
                        key={p.id}
                        p={p}
                        addOneClick={addOneClick}
                        addClick={addClick}
                        isAuth={isAuth}
                        lists={lists}
                        goodInCart={p.inCart}
                        getInCart={getHomeInCart}
                        addGoodToList={addGoodToList}
                        compLocation='homepage'
                    />
                )}
            </Row>
        </Container>
    )
}

export default compose(withList, withBreakpoints)(HomeCompilation)
