import React from 'react'
import classes from './CompilationPrevNext.module.sass'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CompilationPrevNext = ({name, title, path, img, shortDescription}) => {
    return (
        <Col className='px-xs-0 px-md-4'>
            <h2>{name}</h2>
            <Link
                to={path}
                className={classes.item}
            >
                <div className={classes.image}>
                    <img src={process.env.REACT_APP_BACKEND_URL + img} alt={title} />
                </div>
                <div className={classes.text}>
                    <h3>{title}</h3>
                    <div className="textSmall">
                        <p>{shortDescription}</p>
                    </div>
                </div>
            </Link>
        </Col>
    )
}

export default CompilationPrevNext
