import React from 'react'
import classes from './CatalogAside.module.sass'
import CatalogAsideCategory from './CatalogAsideCategory'

const CatalogAside = ({ categories, setOpenMobileAside, pM }) => {
    return (
        <aside className={classes.aside}>
            {categories.filter(el => el.path !== 'sale').map(el =>
                <CatalogAsideCategory
                    key={el.id}
                    pathEl={el.path}
                    title={el.name}
                    subcategories={el.subcategories}
                    setOpenMobileAside={setOpenMobileAside}
                    pM={pM}
                />
            )}
        </aside>
    )
}

export default CatalogAside