import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'react-bootstrap'
import classes from './Filters.module.sass'
import cn from 'classnames'
import NoBorderButton from '../../common/buttons/NoBorderButton/NoBorderButton'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'
import FilterSelect from './FilterSelect/FilterSelect'
import FilterColor from './FilterColor/FilterColor'
import FilterRange from './FilterRange/FilterRange'

const Filters = ({
    classActive,
    filters,
    closeFilters,
    totalCount,
    category,
    subcategory,
    getProducts,
    itemsCount,
    order,
    getAllProps,
    getSaleProps,
    getCategoryProps,
    getSubcategoryProps,
    pM
}) => {
    const { register, handleSubmit, reset, getValues } = useForm()
    const [resetEvent, setResetEvent] = useState(false)
    const [changesData, setChangesData] = useState({})

    const calcData = useMemo(() => {
        const minSize = 1
        const maxSize = 1000
        const def = 1
        const diff = 20
        const regexp = /#/g
        let count = 0
        let sizeDeff = 0
        const calc = changesData.data && Object.entries(changesData.data).reduce((acc, [key, value]) => {
            const numVal = Number(value)
            if (value.length < 1 || !value) { return acc } else {
                value && count++
                if (Array.isArray(value) && value.length > 1) {
                    return [
                        ...acc, 
                        ...value.map(v => {
                            return `props[${key}]=${v.toString().replace(regexp, '%23')}`
                        })
                    ]
                } else if (key === 'width' || key === 'height' || key === 'length') {
                    numVal === def && sizeDeff++
                    return [...acc, ...new Array(2).fill('').map((_, i) => {
                        return `props[${key}]=${i === 0 ?
                            numVal > diff ? numVal - diff : minSize :
                            numVal <= maxSize - diff ? numVal + diff : maxSize
                            }`
                    })]
                } else {
                    return [...acc, `props[${key}]=${value.toString().replace(regexp, '%23')}`]
                }
            }
        }, []).join('&')

        return { type: changesData.type, data: count !== sizeDeff && calc }
    }, [changesData])

    const getChangeEvent = useCallback(() => {
        setChangesData({ type: 'changes', data: getValues() })
    }, [getValues])

    const filtersOnSubmit = data => setChangesData({ type: 'submit', data })

    useEffect(() => {
        let timer = calcData.type && setTimeout(() => {
            getProducts(itemsCount, 0, order, calcData)
        }, 1000)
        return () => clearTimeout(timer)
    }, [calcData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        order && getProducts(itemsCount, 0, order, calcData)
    }, [order]) // eslint-disable-line react-hooks/exhaustive-deps

    const getProps = useCallback(() => {
        if (category === 'sale') {
            getSaleProps()
        } else if (category && !subcategory) {
            getCategoryProps(category)
        } else if (category && subcategory) {
            getSubcategoryProps(category, subcategory)
        } else {
            getAllProps()
        }
    }, [
        category,
        subcategory,
        getSaleProps,
        getAllProps,
        getCategoryProps,
        getSubcategoryProps
    ])

    useEffect(() => {
        classActive && getProps()
    }, [classActive, getProps])

    const handleReset = () => {
        reset()
        setResetEvent(true)
        setChangesData({ type: 'changes', data: getValues() })
        setTimeout(() => {
            setResetEvent(false)
            pM && window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }, 100)
    }

    const filterSwitch = el => {
        switch (el.kind) {
            case 'checkbox':
                return <FilterSelect
                    el={el}
                    register={register}
                    reset={resetEvent}
                    getChangeEvent={getChangeEvent}
                />
            case 'color':
                return <FilterColor
                    el={el}
                    register={register}
                    getChangeEvent={getChangeEvent}
                />
            case 'range':
                return <FilterRange
                    el={el}
                    register={register}
                    reset={resetEvent}
                    getChangeEvent={getChangeEvent}
                />
            default: return false
        }
    }

    return (
        <form
            onSubmit={handleSubmit(filtersOnSubmit)}
            className={cn(classes.filters, classActive && classes.active)}
        >
            <div className={classes.buttons}>
                <Row className={classes.row}>
                    <Col xs={12} md={6} lg={4} className={cn(
                        classes.col, 'order-2 order-md-1'
                    )}>
                        <BorderButton
                            name='Показать'
                            type='submit'
                            onClick={closeFilters}
                            count={totalCount}
                            disabled={totalCount === 0}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className={cn(
                        classes.col, 'order-1 order-md-2'
                    )}>
                        <NoBorderButton
                            name='Очистить фильтр'
                            type='button'
                            onClick={handleReset}
                        />
                    </Col>
                </Row>
            </div>
            <div className={classes.groups}>
                {filters && filters.filter((v, i, a) =>
                    a.findIndex(t => (t.group === v.group)) === i).map((x, i) =>
                        <div key={i} className={classes.group}>
                            <Row className={classes.row}>
                                {filters && filters.filter(el => el.group === x.group).map(el =>
                                    <Col key={el.id} xs={12} md={6} lg={4} className={classes.col}>
                                        {filterSwitch(el)}
                                    </Col>
                                )}
                            </Row>
                        </div>
                    )}
            </div>
        </form>
    )
}

export default Filters
