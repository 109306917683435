const CALL_MODAL = 'modals/CALL_MODAL'
const SET_DEFAULT_VALUE_INDEX = 'modals/SET_DEFAULT_VALUE_INDEX'

const initialState = {
    modals: [
        {
            id: 'registration',
            title: 'Регистрация',
            open: false
        },
        {
            id: 'enter',
            title: 'Вход',
            open: false
        },
        {
            id: 'phone',
            title: 'Номер телефона',
            open: false
        },
        {
            id: 'error',
            title: 'Телефон с таким номером не зарегистрирован',
            open: false
        },
        {
            id: 'sms',
            title: 'Пароль из СМС',
            open: false
        },
        {
            id: 'app_success',
            title: 'Спасибо за заявку! Мы свяжемся с вами в ближайшее время.',
            open: false
        },
        {
            id: 'app_error',
            title: 'К сожалению, произошёл сбой и заяка не была отправлена.',
            open: false
        },
        {
            id: 'one_success',
            title: 'Спасибо за заказ! Мы свяжемся с вами в ближайшее время.',
            open: false
        },
        {
            id: 'one_error',
            title: 'К сожалению, произошёл сбой и заказ не был оформлен.',
            open: false
        },
        {
            id: 'save_pdf',
            title: 'Сохранить список в PDF?',
            open: false
        },
        {
            id: 'send_pdf',
            title: 'Отправить список на почту в формате PDF?',
            email: null,
            open: false
        },
        {
            id: 'send_pdf_success',
            title: 'Список уже у вас на почте!',
            description: 'Не забудьте проверить папку Спам, к сожалению иногда письма попадают в неё.',
            open: false
        },
        {
            id: 'send_pdf_error',
            title: 'Что-то пошло не так :((',
            description: 'Проверьте правильность написания указанной почты и соединение с интернетом',
            open: false
        },
        {
            id: 'save_order_pdf',
            title: 'Сохранить заказ в PDF?',
            open: false
        },
        {
            id: 'send_order_pdf',
            title: 'Отправить заказ на почту в формате PDF?',
            email: null,
            open: false
        },
        {
            id: 'send_order_pdf_success',
            title: 'Заказ уже у вас на почте!',
            description: 'Не забудьте проверить папку Спам, к сожалению иногда письма попадают в неё.',
            open: false
        },
        {
            id: 'combine',
            title: 'Объединить выбранные списки?',
            open: false,
            listsId: []
        },
        {
            id: 'add_list',
            title: 'Введите название списка',
            open: false
        },
        {
            id: 'designers',
            title: 'Давайте работать вместе',
            img: '/img/designers.png',
            open: false
        },
        {
            id: 'payment_successful',
            title: 'Спасибо за заказ!',
            description: 'Статус исполнения заказа смотрите в Личном кабинете во вкладке «Оплаченные заказы»',
            open: false
        },
        {
            id: 'payment_error',
            title: 'Не удалось провести оплату',
            description: 'Описание причины отказа системы оплаты. Скорее всего может быть разным от случая к случаю, и тянуться сюда от системы оплаты.',
            open: false
        },
        {
            id: 'delete_list',
            title: 'Хотите удалить список?',
            data: null,
            open: false
        },
        {
            id: 'delete_order',
            title: 'Хотите удалить заказ?',
            data: null,
            open: false
        },
        {
            id: 'order_request',
            title: 'Вы можете оплатить любой заказ запросив счёт',
            open: false
        },
        {
            id: 'autorization_error',
            title: 'Ошибка входа',
            open: false
        },
        {
            id: 'basket_first_visit',
            title: 'Для удобства, разделите товары по спискам.',
            open: false
        }
    ],
    defaultValueIndex: 1
}

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFAULT_VALUE_INDEX: {
            return {
                ...state,
                defaultValueIndex: state.defaultValueIndex + 1
            }
        }
        case CALL_MODAL: {
            return {
                ...state,
                modals: state.modals.reduce((acc, m) => (
                    m.id !== action.id ?
                        [...acc, { ...m, open: false }] :
                        [...acc, { 
                            ...m, 
                            open: !m.open,
                            listsId: action.listsId,
                            data: action.data
                        }]
                ), [])
            }
        }
        default:
            return state
    }
}

export const callModal = (id, listsId, data) => ({ type: CALL_MODAL, id, listsId, data })
export const setDefaultValueIndex = () => ({ type: SET_DEFAULT_VALUE_INDEX })

export default modalsReducer