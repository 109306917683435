import * as axios from 'axios'
import store from '../redux/reduxStore'
import { setFetching } from '../redux/staticReducer'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-SessionID': localStorage.sid
    }
})

let numOfRequests = 0
instance.interceptors.request.use(config => {
    const token = localStorage.sid
    config.headers['X-SessionID'] = token || ''
    numOfRequests++
    numOfRequests === 1 && store.dispatch(setFetching(true))
    return config
}, error => {
    return Promise.reject(error)
})
instance.interceptors.response.use(response => {
    numOfRequests--
    if (numOfRequests === 0) {
        store.dispatch(setFetching(false))
    }
    return response
}, error => {
    numOfRequests--
    if (numOfRequests === 0) {
        store.dispatch(setFetching(false))
    }
    return Promise.reject(error)
})

const isColor = c => c ? [{ id: 'color', value: c }] : []

const clone = obj => Object.assign({}, obj)
const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object)
    const targetKey = clonedObj[key]
    delete clonedObj[key]
    clonedObj[newKey] = targetKey
    return clonedObj
}

export const authAPI = {
    session() {
        return (
            instance.post('/sessions')
                .then(response => response.data)
        )
    },
    login(phone, password, captcha_token) {
        return (
            instance.post('/sessions/login', { phone, password, captcha_token })
                .then(response => response.data)
        )
    },
    logout() {
        return (
            instance.post('/sessions/logout')
                .then(response => response.data)
        )
    }
}

export const accountAPI = {
    register(name, phone, email, captcha_token) {
        return (
            instance.post('/accounts/register', {
                name, phone, email, captcha_token
            }).then(response => response.data)
        )
    },
    registerConfirm(phone, code) {
        return (
            instance.post('/accounts/confirm_register', { phone, code })
                .then(response => response.data)
        )
    },
    getAccountInfo(account_id) {
        return (
            instance.get(`/accounts/${account_id}`)
                .then(response => response.data)
        )
    },
    updateAccountInfo(account_id, name, phone, email, discount_percent, disabled) {
        return (
            instance.post(`/accounts/${account_id}`, {
                name, phone, email, discount_percent, disabled
            }).then(response => response.data)
        )
    },
    reset(phone, captcha_token) {
        return (
            instance.post('/accounts/reset', { phone, captcha_token })
                .then(response => response.data)
        )
    },
    resetConfirm(phone, code) {
        return (
            instance.post('/accounts/confirm_reset', { phone, code })
                .then(response => response.data)
        )
    }
}

export const staticAPI = {
    getOffer() {
        return (
            instance.get('/main')
                .then(response => response.data)
        )
    },
    getStatic(id) {
        return (
            instance.get(`/pages/${id}`)
                .then(response => response.data)
        )
    }
}

export const homeAPI = {
    getHome() {
        return (
            instance.get('/main')
                .then(response => response.data)
        )
    }
}

export const oneClickAPI = {
    postOneClickOrder(good_name, phone, captcha_token) {
        return (
            instance.post('/oneclick_orders', {
                good_name, phone, captcha_token
            }).then(response => response.data)
        )
    }
}

export const designersAPI = {
    putDesigner(studio_name, phone, portfolio_url, captcha_token) {
        return (
            instance.put('/designer_partnerships', {
                studio_name, phone, portfolio_url, captcha_token
            }).then(response => response.data)
        )
    }
}

export const catalogAPI = {
    getCategories() {
        return (
            instance.get('/catalog/categories')
                .then(response => response.data)
        )
    },
    getAllProducts(count, offset, sort, props) {
        return (
            instance.get(`/catalog/goods?count=${count}&offset=${offset}&sort=${sort}${props ? `&${props}` : ''}`)
                .then(response => response.data)
        )
    },
    getSaleProducts(count, offset, sort, props) {
        return (
            instance.get(
                `/catalog/categories/sale/goods?count=${count}&offset=${offset}&sort=${sort}${props ? `&${props}` : ''}`
            ).then(response => response.data)
        )
    },
    getSearch(count, offset, sort, props, query) {
        return (
            instance.get(`/catalog/search?query=${query}&count=${count}&offset=${offset}&sort=${sort}${props ? `&${props}` : ''}`)
                .then(response => response.data)
        )
    },
    getCategoryProducts(count, offset, sort, props, c) {
        return (
            instance.get(
                `/catalog/categories/${c}/goods?count=${count}&offset=${offset}&sort=${sort}${props ? `&${props}` : ''}`
            ).then(response => response.data)
        )
    },
    getSubcategoryProducts(count, offset, sort, props, c, s) {
        return (
            instance.get(
                `/catalog/categories/${c}/subcategories/${s}/goods?count=${count}&offset=${offset}&sort=${sort}${props ? `&${props}` : ''}`
            ).then(response => response.data)
        )
    },
    getProduct(c, s, g) {
        return (
            instance.get(`/catalog/categories/${c}/subcategories/${s}/goods/${g}`)
                .then(response => response.data)
        )
    },
    getProductById(id) {
        return (
            instance.get(`/catalog/goods_by_id/${id}`)
                .then(response => response.data)
        )
    },
    getAllProps() {
        return (
            instance.get(`/catalog/properties`)
                .then(response => response.data)
        )
    },
    getSaleProps() {
        return (
            instance.get(
                `/catalog/categories/sale/properties`
            ).then(response => response.data)
        )
    },
    getCategoryProps(c) {
        return (
            instance.get(
                `/catalog/categories/${c}/properties`
            ).then(response => response.data)
        )
    },
    getSubcategoryProps(c, s) {
        return (
            instance.get(
                `/catalog/categories/${c}/subcategories/${s}/properties`
            ).then(response => response.data)
        )
    },
    getListStatus() {
        return (
            instance.get('/cart_status')
                .then(response => response.data)
        )
    }
}

export const compilationsAPI = {
    getCompilations(count, offset) {
        return (
            instance.get(`/compilations?count=${count}&offset=${offset}`)
                .then(response => response.data)
        )
    },
    getCompilationItem(id) {
        return (
            instance.get(`/compilations/${id}`)
                .then(response => response.data)
        )
    }
}

export const listsAPI = {
    getLists() {
        return (
            instance.get('/lists')
                .then(response => response.data)
        )
    },
    createList(name) {
        return (
            instance.post('/lists', { name })
                .then(response => response.data)
        )
    },
    renameList(id, name, hidden) {
        return (
            instance.patch(`/lists/${id}`, { name, hidden })
                .then(response => response.data)
        )
    },
    deleteList(id) {
        return (
            instance.delete(`/lists/${id}`)
                .then(response => response.data)
        )
    },
    addGoodToList(list, good_id, amount, color) {
        return (
            instance.post(`/lists/${list}`, {
                good_id,
                amount,
                props: isColor(color)
            }).then(response => response.data)
        )
    },
    addCompilationGoods(list_id, comp_id) {
        return (
            instance.post(`/lists/${list_id}/compilation`, { comp_id })
                .then(response => response.data)
        )
    },
    getGoodsOfList(list_id) {
        return (
            instance.get(`/lists/${list_id}`)
                .then(response => response.data)
        )
    },
    deleteGood(list_id, entry_id) {
        return (
            instance.delete(`/lists/${list_id}/${entry_id}`)
                .then(response => response.data)
        )
    },
    updateGood(list_id, entry_id, amount, color) {
        return (
            instance.patch(`/lists/${list_id}/${entry_id}`, {
                amount,
                props: isColor(color)
            })
                .then(response => response.data)
        )
    },
    isGoodInCart(id) {
        return (
            instance.get(`/in_cart?good_id=${id}`)
                .then(response => response.data)
        )
    },
    isCompilationInCart(id) {
        return (
            instance.get(`/in_cart?compilation=${id}`)
                .then(response => response.data)
        )
    },
    downloadPdf(id, no_discount) {
        return (
            instance.get(
                `/lists/${id}/pdf?no_personal_discount=${no_discount}`,
                { responseType: 'arraybuffer' }
            ).then(response => response.data)
        )
    },
    sendPdf(id, to, captcha_token, no_personal_discount) {
        return (
            instance.post(`/lists/${id}/email`, { to, no_personal_discount, captcha_token })
                .then(response => response.data)
        )
    },
    mergeLists(name, list_ids) {
        return (
            instance.post('/lists/merge', { name, list_ids })
                .then(response => response.data)
        )
    }
}

export const ordersAPI = {
    getOrders(id) {
        return (
            instance.get(`/orders?account_id=${id}`)
                .then(response => response.data)
        )
    },
    ordersPrepare(list_id, type) {
        return (
            instance.get(`/orders/prepare?${type}=${list_id}`)
                .then(response => response.data)
        )
    },
    createOrder(list_id, name, phone, email, receiver_name, receiver_phone,
        method, index, city, street, house, housing, floor, apartment, comment, type) {
            let obj = {
                list_id,
                payer: {
                    name,
                    phone,
                    email,
                    receiver_name,
                    receiver_phone
                },
                delivery: {
                    method,
                    address: {
                        index,
                        city,
                        street,
                        house,
                        housing,
                        floor,
                        apartment,
                        comment
                    }
                }
            }
            if (type === 'good_id') {
                obj = renameKey(obj, 'list_id', 'good_id')
            }
        return (
            instance.post('/orders', obj).then(response => response.data)
        )
    },
    deleteOrder(order_id) {
        return (
            instance.post(`/orders/${order_id}/hide`)
                .then(response => response.data)
        )
    },
    downloadOrderPdf(order_id, no_personal_discount) {
        return (
            instance.get(
                `/orders/${order_id}/pdf?no_personal_discount=${no_personal_discount}`,
                { responseType: 'arraybuffer' }
            ).then(response => response.data)
        )
    },
    sendOrderPdf(order_id, to, no_personal_discount, captcha_token) {
        return (
            instance.post(`/orders/${order_id}/email`, { to, no_personal_discount, captcha_token })
                .then(response => response.data)
        )
    },
    getShippedPdf(order_id) {
        return (
            instance.get(`/orders/${order_id}/invoice`, { responseType: 'arraybuffer' })
                .then(response => response.data)
        )
    }
}