import React from 'react'

const FormEmailField = ({ register, errors }) => {
    return (
        <>
            <input
                type='text'
                name='email'
                placeholder='Почта'
                ref={register({
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}
            />
            <span className='error textSmall'>
                {errors.email?.type === 'required' && <p>обязательное поле</p>}
                {errors.email?.type === 'pattern' && <p>введите email</p>}
            </span>
        </>
    )
}

export default FormEmailField
