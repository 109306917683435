import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunkMiddleWare from 'redux-thunk'
import homeReducer from './homeReducer'
import catalogReducer from './catalogReducer'
import compilationReducer from './compilationReducer'
import staticReducer from './staticReducer'
import modalsReducer from './modalsReducer'
import accountReducer from './accountReducer'

const reducers = combineReducers({
      home: homeReducer,
      catalog: catalogReducer,
      compilation: compilationReducer,
      account: accountReducer,
      modals: modalsReducer,
      staticContent: staticReducer,
      form: formReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleWare)))

window.__store__ = store

export default store