import React, { useState } from 'react'
import AccountEmpty from '../../Account/AccountContent/AccountProducts/AccountEmpty'
import CombineButton from '../../common/buttons/CombineButton/CombineButton'
import classes from './CheckoutOrder.module.sass'
import cn from 'classnames'
import CheckoutProduct from './CheckoutProduct'
import AnimateHeight from 'react-animate-height'

const CheckoutOrder = ({
    lists,
    list,
    oneClickList,
    isOneClick,
    isAuth,
    deleteGood,
    pTM,
    pM
}) => {

    const [height, setHeight] = useState(pTM ? 0 : 'auto')

    const handleClick = () =>
        pTM && setHeight(prev => prev === 0 ? 'auto' : 0)

    const checkoutProducts = l =>

        l && l.items &&
            l.items.length > 0 ?
            <AnimateHeight
                height={height}
                duration={300}
            >
                {l.items.map(it =>
                    <CheckoutProduct
                        key={it.entry_id}
                        listId={l.id}
                        entryId={it.entry_id}
                        quantity={it.amount}
                        product={it.good}
                        deleteGood={deleteGood}
                        pM={pM}
                    />
                )}
            </AnimateHeight>
            : <AccountEmpty checkout />

    return (
        <section className={classes.order}>
            <h2>Ваш заказ:</h2>
            <div className={classes.productsContainer}>
                {isOneClick && oneClickList.length > 0 ?
                    <div className={classes.products}>
                        <CheckoutProduct
                            quantity='1'
                            product={oneClickList[0]}
                            oneClick
                            pM={pM}
                        />
                    </div>
                    :
                    isAuth ?
                        lists && lists.length > 0 && lists.filter(l => l.id === list).map(l =>
                            <React.Fragment key={l.id}>
                                <div className={cn(
                                    classes.listTitle,
                                    height === 'auto' && classes.active,
                                    'textCaps'
                                )}>
                                    <p onClick={handleClick}>
                                        {l.name}
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                                        </svg>
                                    </p>
                                </div>
                                <div className={classes.products}>
                                    {checkoutProducts(l)}
                                </div>
                            </React.Fragment>
                        )
                        :
                        <>
                            <div className={cn(
                                classes.listTitle,
                                height === 'auto' && classes.active,
                                'textCaps'
                            )}>
                                <p onClick={handleClick}>
                                    {lists.length > 0 && lists[0].name}
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 8L14.375 12.375L13.5 13.25L10 9.75L6.5 13.25L5.625 12.375L10 8Z" fill="#242422" />
                                    </svg>
                                </p>
                            </div>
                            <div className={classes.products}>
                                {checkoutProducts(lists && lists[0])}
                            </div>
                        </>

                }
                {isAuth && !isOneClick && <div className={classes.combine}>
                    <CombineButton checkout />
                </div>}
            </div>
        </section>
    )
}

export default CheckoutOrder
