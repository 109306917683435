import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classes from './Menu.module.sass'
import logo from '../../../../assets/img/logo-white.svg'
import Social from '../../Social/Social'
import Search from '../Search/Search'
import AccountLink from '../AccountLink/AccountLink'
import Mailto from '../../Mailto'
import Tel from '../../Tel'
import cn from 'classnames'

const DesktopMenu = ({ setMenuState, setMenuCol, setLastCol, perCol, phone, email }) => {
    const handleClick = () => setMenuState(false)

    return (
        <Container className='h-100'>
            <div className={classes.wrapper}>
                <Row className='h-100'>
                    <Col md={3} className='col'>
                        <div className={classes.firstCol}>
                            <div className={classes.logo}>
                                <img src={logo} alt='' />
                            </div>
                            <AccountLink onClick={handleClick} dark />
                            <ul className='textButton'>
                                <li>
                                    <Link to='/compilation' onClick={handleClick}>Подборки</Link>
                                </li>
                                <li>
                                    <Link to='/designers' onClick={handleClick}>Дизайнерам</Link>
                                </li>
                                <li>
                                    <Link to='/about' onClick={handleClick}>Об авторе</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={9} className='col h-100'>
                        <div className={classes.catalog}>
                            <div className={cn(classes.title, 'textCaps')}>
                                <Link to='/catalog' onClick={handleClick}>Каталог</Link>
                            </div>
                            <div className={classes.cols}>
                                {new Array(4).fill('').map((_, i) =>
                                    <div key={i} className={classes.col}>
                                        {i === 3 &&
                                            <div className={classes.upper}>
                                                <Search isMenu />
                                            </div>
                                        }
                                        <ul className='textButton'>
                                            {i < 3 ? setMenuCol(i * perCol) : setLastCol()}
                                        </ul>
                                        {i === 1 &&
                                            <div className={cn(classes.lower, 'textSmall')}>
                                                <Tel to={phone}>{phone}</Tel>
                                            </div>
                                        }
                                        {i === 3 &&
                                            <div className={classes.lower}>
                                                <Social />
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className={classes.lowerSide}>
                    <Row>
                        <Col md={3} className={cn(classes.col, 'col')}>
                            <div className='textSmall'>
                                <Link to='/privacy_policy' onClick={handleClick}>Политика конфиденциальности</Link>
                            </div>
                        </Col>
                        <Col md={9} className={cn(classes.col, 'col')}>
                            <div className={cn(classes.email, 'textSmall')}>
                                <Mailto to={email}>{email}</Mailto>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default DesktopMenu
