import React, { useRef, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classes from './AccountListItem.module.sass'
import cn from 'classnames'
import { Transition } from 'react-transition-group'
import AutosizeInput from 'react-input-autosize'
import { useForm, Controller } from 'react-hook-form'

const AccountListItem = ({
    lists,
    id,
    title,
    value,
    date,
    baseUrl,
    isAccount,
    renameList,
    hidden,
    basket,
    callModal,
    renameSuccess,
    setRenameSuccess,
    payment,
    tracking,
    TM,
    pM
}) => {
    const { control, handleSubmit, watch, errors, reset, setValue } = useForm()
    const { pathname } = useLocation()
    const inputRef = useRef()
    const itemRef = useRef()
    const [active, setActive] = useState(false)
    const [hover, setHover] = useState(false)
    const [edit, setEdit] = useState(false)

    const currentLists = baseUrl === '/account/lists'

    const isCurrent = val => val !== title
    const isExist = val => {
        if (val !== title && lists.some(list => list.name === val))
            return false
        return true
    }
    const onSubmit = d => renameList(id, d.list, hidden)

    useEffect(() => {
        if (renameSuccess) {
            setEdit(false)
            setRenameSuccess()
        }
    }, [renameSuccess, setRenameSuccess])

    useEffect(() => reset(), [currentLists, reset])

    useEffect(() => {
        if (pathname === `${baseUrl}/${value}${payment ? `/${payment}` : ''}`) {
            setActive(true)
            pM && setHover(true)
        } else {
            setActive(false)
            pM && setHover(false)
        }
    }, [payment, pathname, baseUrl, value, pM])

    useEffect(() => setValue('list', title), [edit, title, setValue])

    useEffect(() => {
        active || setEdit(false)
    }, [active])

    useEffect(() => {
        const ref = inputRef.current
        if (edit) {
            ref.focus()
        } else {
            ref.blur()
            !pM && setHover(false)
        }
    }, [edit, setValue, title, pM])

    useEffect(() => {
        const handleClickOutside = e => {
            if (itemRef.current && !itemRef.current.contains(e.target)) {
                reset()
                setEdit(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [itemRef, reset])

    const handleClick = e => {
        setTimeout(() => {
            e.target.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' })
        }, 400)
    }

    const handleClickEdit = () => {
        const input = watch('list')
        setEdit(prev => {
            if (prev) {
                if (input !== title || input === '') {
                    handleSubmit(onSubmit)()
                    inputRef.current.focus()
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        })
    }

    const removable = tracking === 'Доставлен' || tracking === 'Отменён'

    const handleSavePdf = () => {
        (isAccount || basket) ?
            callModal('save_pdf') :
            callModal('save_order_pdf')
    }

    const handleSendPdf = () => {
        (isAccount || basket) ?
            callModal('send_pdf') :
            callModal('send_order_pdf')
    }

    const handleRemoveItem = () => {
        isAccount ?
            callModal('delete_list', false, id) :
            callModal('delete_order', false, id)
    }

    const formatedDate = d => {
        const dat = new Date(d)
        const m = (dat.getMonth() + 1) < 10 ? `0${dat.getMonth() + 1}` : dat.getMonth() + 1
        return `${dat.getDate()}.${m}.${dat.getFullYear()}`
    }

    const hoverStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 }
    }
    return (
        <>
            <div
                onMouseOver={!pM && active ? () => setHover(true) : undefined}
                onMouseOut={!pM && active ? () => edit || setHover(false) : undefined}
                className={cn(
                    classes.item,
                    active ? classes.active : null,
                    edit ? classes.edit : null,
                    currentLists ? null : classes.orders
                )}
                ref={itemRef}
            >
                <NavLink
                    to={`${baseUrl}/${value}`}
                    onClick={e => pM && handleClick(e)}
                    className={classes.link}
                >
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.wrapper}>
                        <Controller
                            name='list'
                            defaultValue={title || ''}
                            render={({ onChange, value, name }) => (
                                <AutosizeInput
                                    type='text'
                                    name={name}
                                    value={value || ''}
                                    onChange={onChange}
                                    onKeyDown={e =>
                                        e.key === 'Enter' &&
                                        e.target.value === title && setEdit(false)
                                    }
                                    ref={inputRef}
                                />
                            )}
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 20,
                                validate: {
                                    sameName: isExist,
                                    currentName: isCurrent
                                }
                            }}
                        />
                        <span className={cn(classes.error, 'textSmall')}>
                            {errors.list?.type === 'required' && <p>введите название списка</p>}
                            {errors.list?.type === 'sameName' && <p>это название уже занято</p>}
                            {errors.list?.type === 'maxLength' && <p>слишком длинное название</p>}
                        </span>
                    </form>
                </NavLink>
                {date &&
                    <div className={cn(
                        classes.date,
                        active && classes.active,
                        edit && classes.edit,
                        'textSmall'
                    )}>
                        <p>{formatedDate(date)}</p>
                    </div>
                }
                <Transition in={hover} timeout={300} mountOnEnter unmountOnExit>
                    {state => (
                        <div 
                            className={cn(classes.buttons, TM && classes.touch)} 
                            style={{
                            transition: 'opacity .3s ease',
                            opacity: 0,
                            ...hoverStyles[state]
                        }}
                        >
                            {basket ||
                                <>
                                    {isAccount &&
                                        <button onClick={handleClickEdit} className={edit ? classes.active : null}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.7 5.5C13.1 5.1 13.1 4.5 12.7 4.1L10.9 2.3C10.5 1.9 9.9 1.9 9.5 2.3L2 9.8V13H5.2L12.7 5.5ZM3 12V10.2L8 5.2L9.8 7L4.8 12H3Z" fill="#242422" />
                                            </svg>
                                            <div className={classes.confirm}>
                                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.99999 6.60001L1.49999 3.10001L0.799988 3.80001L4.29999 7.30001L4.99999 8.00001L12.1 0.900012L11.4 0.200012L4.99999 6.60001Z" fill="#FEFEFC" />
                                                </svg>
                                            </div>
                                        </button>
                                    }
                                    {(isAccount || removable) &&
                                        <button onClick={handleRemoveItem}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10 1H6V2H10V1ZM2 3V4H3V14C3 14.6 3.4 15 4 15H12C12.6 15 13 14.6 13 14V4H14V3H2ZM4 14V4H12V14H4ZM6 6H7V12H6V6ZM10 6H9V12H10V6Z" fill="#242422" />
                                            </svg>
                                        </button>
                                    }
                                </>
                            }
                            <button onClick={handleSavePdf}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13 7L12.3 6.3L8.5 10.1V1H7.5V10.1L3.7 6.3L3 7L8 12L13 7ZM13 12V14H3V12H2V14C2 14.6 2.4 15 3 15H13C13.6 15 14 14.6 14 14V12H13Z" fill="#242422" />
                                </svg>
                            </button>
                            <button onClick={handleSendPdf}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13 14H3C2.4 14 2 13.6 2 13V3C2 2.4 2.4 2 3 2H8V3H3V13H13V8H14V13C14 13.6 13.6 14 13 14ZM10 1V2H13.3L9 6.3L9.7 7L14 2.7V6H15V1H10Z" fill="#242422" />
                                </svg>
                            </button>
                        </div>
                    )}
                </Transition>
            </div>
        </>
    )
}

export default AccountListItem
