import React, { useState } from 'react'
import { Modal } from 'reactstrap'

const DetailsImage = ({ image, classes }) => {
    const [modal, setModal] = useState(false)
    return (
        <>
            <Modal
                isOpen={modal}
                toggle={() => setModal(!modal)}
                centered
                className={classes.modal}>
                <img src={process.env.REACT_APP_BACKEND_URL + image.img} alt={image.alt} />
            </Modal>
            <div
                className={classes.imgContainer}
                onClick={() => setModal(true)}
            >
                <img src={process.env.REACT_APP_BACKEND_URL + image.thumb} alt={image.alt} className='fitContain' />
            </div>
        </>
    )
}

export default DetailsImage
