import React, { useState, useEffect } from 'react'
import classes from './Designers.module.sass'
import cn from 'classnames'
import { Col, Row, Container } from 'react-bootstrap'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import { connect } from 'react-redux'
import BorderButton from '../common/buttons/BorderButton/BorderButton'
import DesignersModal from './Modal/DesignersModal'
import { callModal } from '../../redux/modalsReducer'
import { putDesigner, getStatic } from '../../redux/staticReducer'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import { compose } from 'redux'
import BasketLink from '../Catalog/BasketLink/BasketLink'

const Designers = ({ 
    staticContent, 
    rules, 
    modals, 
    callModal, 
    putDesigner, 
    getStatic, 
    isAuth,
    hasGoods,
    fetching,
    TM, 
    pM }) => {

    const [even, setEven] = useState()

    useEffect(() => getStatic('partnersPage'), [getStatic])

    useEffect(() => {
        TM || (staticContent.advantages && setEven(staticContent.advantages.length % 2 === 0))
    }, [staticContent.advantages, TM])

    const image = () => {
        return <div className={classes.imgContainer}>
            <img src={process.env.REACT_APP_BACKEND_URL + staticContent.image} alt={staticContent.title} />
        </div>
    }

    return (
        <>
            <DesignersModal
                modal={modals.find(m => m.id === 'designers')}
                callModal={callModal}
                putDesigner={putDesigner}
                rules={rules}
            />
            <Breadcrumbs />
            <section className={cn(classes.designers, 'preFooterDec', 'fetched', fetching && 'loading')}>
                <div className={classes.image}>
                    {pM || image()}
                    <Container>
                        <Row>
                            <Col xs={12} md={4} xl={3} className='col'>
                                <h1>{staticContent.title}</h1>
                                <div
                                    className={classes.description}
                                    dangerouslySetInnerHTML={{ __html: staticContent.content }}
                                ></div>
                                {pM && image()}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <div className={classes.text}>
                        <div className={classes.advantages}>
                            <Row>
                                {staticContent.advantages && staticContent.advantages.map((el, i) =>
                                    <Col key={i} xs={12} md={8} xl={6} className='col'>
                                        <div className={classes.item}>
                                            <div className={classes.point}>
                                                <h2>{i + 1}</h2>
                                            </div>
                                            <div
                                                className={classes.description}
                                                dangerouslySetInnerHTML={{ __html: el.content }}
                                            ></div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <div className={cn(classes.button, even && classes.even)}>
                            <BorderButton
                                name='Получить специальные условия'
                                onClick={() => callModal('designers')}
                            />
                        </div>
                    </div>
                </Container>
                <BasketLink
                    enabled={!isAuth}
                    notEmpty={hasGoods}
                />
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        modals: state.modals.modals,
        staticContent: state.staticContent.staticPage,
        rules: state.staticContent.rules,
        isAuth: state.account.isAuth,
        hasGoods: state.catalog.hasGoods,
        fetching: state.staticContent.fetching
    }
}

export default compose(
    withBreakpoints,
    connect(mapStateToProps, { callModal, putDesigner, getStatic })
)(Designers)
