import React, { useState } from 'react'
import classes from './AddPlusButton.module.sass'
import cn from 'classnames'
import { withBreakpoints } from '../../../../hoc/withBreakpoints'

const PlusIconInner = ({ isAuth }) => {
    return (
        <div className={classes.add}>
            <p>{isAuth ? 'Добавить в список' : 'Добавить в корзину'}</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7.44531" y="3" width="1.11111" height="10" fill="#242422" />
                <rect x="13" y="7.44531" width="1.11111" height="10" transform="rotate(90 13 7.44531)" fill="#242422" />
            </svg>
        </div>
    )
}

const AddedToCart = () => {
    return (
        <div className={classes.added}>
            <p>Добавлен</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.00078 10.5992L2.50078 7.09922L1.80078 7.79922L5.30078 11.2992L6.00078 11.9992L13.1008 4.89922L12.4008 4.19922L6.00078 10.5992Z" fill="#242422" />
            </svg>
        </div>
    )
}

const AddPlusButton = ({ isAuth, goodInCart, onClick, TM, style }) => {
    const [btnHover, setHover] = useState(false)

    return (
        <div
            role='button'
            onClick={onClick}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className={cn(
                classes.button,
                TM && classes.touch,
                btnHover && classes.hover,
                goodInCart && classes.noneEvents,
                'textButton'
            )}
            style={style}
        >
            {goodInCart ? <AddedToCart /> : <PlusIconInner isAuth={isAuth} />}
        </div>
    )
}

export default withBreakpoints(AddPlusButton)