import React from 'react'
import classes from './Compilation.module.sass'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import cn from 'classnames'

const CompilationGrid = ({ compilation }) => {
    return (
        <Row className={cn(classes.row, 'mx-xs-0 mx-md-n2 mx-xl-n4')}>
            {compilation.map((el, i) =>
                <Col className={cn(classes.col, 'px-xs-0 px-md-2 px-xl-4')} key={i}>
                    <Link
                        to={{ pathname: `compilation/${el.path}`, compilationId: i }}
                        className={classes.item}
                    >
                        <div className={classes.imgContainer}>
                            <img src={process.env.REACT_APP_BACKEND_URL + el.photo} alt={el.title} />
                        </div>
                        <div className={cn(classes.textContainer, 'textSmall')}>
                            <h3>{el.title}</h3>
                            <p>{el.short_description}</p>
                        </div>
                    </Link>
                </Col>
            )}
        </Row>
    )
}

export default CompilationGrid