import {
    authAPI,
    accountAPI,
    listsAPI,
    oneClickAPI,
    ordersAPI,
    catalogAPI
} from '../api/api'
import { callModal } from './modalsReducer'
import { getListStatus, getProductInCart } from './catalogReducer'
import { getHomeInCart } from './homeReducer'

const SET_USER = 'account/SET_USER'
const CLEAR_LISTS = 'account/CLEAR_LISTS'
const ADD_LIST = 'account/ADD_LIST'
const SET_RENAME_LIST = 'account/SET_RENAME_LIST'
const REMOVE_LIST = 'account/REMOVE_LIST'
const ADD_TO_LIST = 'account/ADD_TO_LIST'
const SET_UPDATE_GOOD = 'account/SET_UPDATE_GOOD'
const REMOVE_ITEM = 'account/REMOVE_ITEM'
const SET_LISTS = 'account/SET_LISTS'
const SET_ORDERS_PREPARE = 'account/SET_ORDERS_PREPARE'
const SET_ORDERS = 'account/SET_ORDERS'
const CLEAR_ORDERS = 'account/CLEAR_ORDERS'
const SET_CREATED_ORDER = 'account/SET_CREATED_ORDER'
const SET_CONFIRMED_PHONE = 'account/SET_CONFIRMED_PHONE'
const SET_RESET_PHONE = 'account/SET_RESET_PHONE'
const SET_AUTH = 'account/SET_AUTH'
const SET_ACCOUNT_INFO = 'account/SET_ACCOUNT_INFO'
const CLEAR_ACCOUNT_INFO = 'account/CLEAR_ACCOUNT_INFO'
const SET_MODAL = 'account/SET_MODAL'
const SET_MERGED_LIST = 'account/SET_MERGED_LIST'
const SET_REDIRECT = 'account/SET_REDIRECT'
const SET_RENAME_SUCCESS = 'account/SET_RENAME_SUCCESS'
const SET_IS_GOOD_IN_CART = 'account/SET_IS_GOOD_IN_CART'
const SET_IS_COMPILATION_IN_CART = 'account/SET_IS_COMPILATION_IN_CART'
const SET_ONE_CLICK = 'account/SET_ONE_CLICK'

const initialState = {
    id: null,
    name: undefined,
    phone: undefined,
    email: undefined,
    discount_percent: 0,
    admin: false,
    disabled: false,
    isAuth: false,
    lists: [],
    paid: [],
    oneClickList: [],
    orderInfo: {
        volume: 0,
        weight: 0,
        delivery: [],
        goods_cost: 0,
        discount: 0
    },
    payURL: null,
    confirmedEmail: null,
    confirmedPhone: null,
    resetPhone: null,
    maxLists: 10,
    maxQuantity: 500,
    addModalOpen: false,
    successModalOpen: false,
    oneClickModalOpen: false,
    redirect: null,
    renameSuccess: false,
    goodInCart: false,
    compilationInCart: false
}

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_GOOD_IN_CART: {
            return {
                ...state,
                goodInCart: action.good
            }
        }
        case SET_ONE_CLICK: {
            return {
                ...state,
                oneClickList: [action.good]
            }
        }
        case SET_IS_COMPILATION_IN_CART: {
            return {
                ...state,
                compilationInCart: action.c
            }
        }
        case SET_LISTS: {
            return {
                ...state,
                lists: action.lists
            }
        }
        case SET_ORDERS_PREPARE: {
            return {
                ...state,
                orderInfo: {
                    ...state.orderInfo,
                    volume: action.volume,
                    weight: action.weight,
                    delivery: action.delivery,
                    goods_cost: action.cost,
                    discount: action.discount
                }
            }
        }
        case SET_ORDERS: {
            return {
                ...state,
                paid: action.orders
            }
        }
        case CLEAR_ORDERS: {
            return {
                ...state,
                paid: []
            }
        }
        case SET_CREATED_ORDER: {
            return {
                ...state,
                payURL: action.url || null
            }
        }
        case SET_AUTH: {
            return {
                ...state,
                isAuth: action.auth
            }
        }
        case SET_CONFIRMED_PHONE: {
            return {
                ...state,
                confirmedPhone: action.phone || null
            }
        }
        case SET_RESET_PHONE: {
            return {
                ...state,
                resetPhone: action.phone || null
            }
        }
        case SET_ACCOUNT_INFO: {
            return {
                ...state,
                id: action.id,
                name: action.name,
                phone: action.phone,
                email: action.email,
                discount_percent: action.discount_percent,
                admin: action.admin,
                disabled: action.disabled
            }
        }
        case CLEAR_ACCOUNT_INFO: {
            return {
                ...state,
                id: null,
                name: null,
                phone: null,
                email: null,
                discount_percent: 0,
                admin: false,
                disabled: false
            }
        }
        case SET_MODAL: {
            return {
                ...state,
                addModalOpen: action.modal === 'add' && !state.addModalOpen,
                successModalOpen: action.modal === 'success' && !state.successModalOpen,
                oneClickModalOpen: action.modal === 'oneClick' && !state.oneClickModalOpen
            }
        }
        case SET_USER: {
            return {
                ...state,
                name: action.name,
                phone: action.phone,
                email: action.email
            }
        }
        case CLEAR_LISTS: {
            return {
                ...state,
                lists: []
            }
        }
        case ADD_LIST: {
            return {
                ...state,
                lists: [...state.lists, action.list]
            }
        }
        case SET_RENAME_LIST: {
            return {
                ...state,
                lists: state.lists.map(el =>
                    el.id === action.id ?
                        {
                            ...el,
                            name: action.name,
                            hidden: action.hidden
                        } :
                        { ...el }
                )
            }
        }
        case REMOVE_LIST: {
            return {
                ...state,
                lists: state.lists.filter(l => l.id !== action.id)
            }
        }
        case SET_MERGED_LIST: {
            return {
                ...state,
                lists: [
                    ...state.lists.filter(f => !action.merged.includes(f.id)),
                    action.list
                ]
            }
        }
        case ADD_TO_LIST: {
            return {
                ...state,
                lists: state.lists.find(f => f.id === action.id) ?
                    state.lists.map(el =>
                        el.id === action.id ?
                            {
                                ...el,
                                items: action.items
                            } :
                            { ...el }
                    ) :
                    [
                        ...state.lists,
                        {
                            id: action.id,
                            name: action.name,
                            items: action.items,
                            hidden: action.hidden
                        }
                    ]
            }
        }
        case SET_UPDATE_GOOD: {
            return {
                ...state,
                lists: state.lists.map(l =>
                    l.id === action.list ?
                        {
                            ...l,
                            items: l.items.map(i =>
                                i.entry_id === action.item ?
                                    {
                                        ...i,
                                        amount: i.amount === action.amount ? i.amount : action.amount,
                                        props: i.props.map(p => (
                                            p.id === 'color' ?
                                                {
                                                    ...p,
                                                    value: p.value === action.color ? p.value : action.color
                                                }
                                                :
                                                { ...p }
                                        ))
                                    } : { ...i }
                            )
                        } : { ...l }
                )
            }
        }
        case REMOVE_ITEM: {
            return {
                ...state,
                lists: state.lists.map(l =>
                    l.id === action.list ?
                        {
                            ...l,
                            items: l.items.filter(i => i.entry_id !== action.item)
                        } :
                        { ...l }
                )
            }
        }
        case SET_REDIRECT: {
            return {
                ...state,
                redirect: action.id
            }
        }
        case SET_RENAME_SUCCESS: {
            return {
                ...state,
                renameSuccess: !state.renameSuccess
            }
        }
        default:
            return state
    }
}

export const setUser = (name, phone, email) => ({ type: SET_USER, name, phone, email })
export const removeItem = (list, item) => ({ type: REMOVE_ITEM, list, item })
export const setLists = lists => ({ type: SET_LISTS, lists })
export const setOrdersPrepare = (volume, weight, delivery, cost, discount) =>
    ({ type: SET_ORDERS_PREPARE, volume, weight, delivery, cost, discount })
export const setOrders = orders => ({ type: SET_ORDERS, orders })
export const clearOrders = () => ({ type: CLEAR_ORDERS })
export const setCreatedOrder = (url, order) => ({ type: SET_CREATED_ORDER, url, order })
export const setConfirmedPhone = phone => ({ type: SET_CONFIRMED_PHONE, phone })
export const setResetPhone = phone => ({ type: SET_RESET_PHONE, phone })
export const setAuth = auth => ({ type: SET_AUTH, auth })
export const setAccountInfo = (id, name, phone, email, discount_percent, admin, disabled) =>
    ({ type: SET_ACCOUNT_INFO, id, name, phone, email, discount_percent, admin, disabled })
export const clearAccountInfo = () => ({ type: CLEAR_ACCOUNT_INFO })
export const clearLists = () => ({ type: CLEAR_LISTS })
export const addList = list => ({ type: ADD_LIST, list })
export const setRenameList = (id, name, hidden) => ({ type: SET_RENAME_LIST, id, name, hidden })
export const removeList = id => ({ type: REMOVE_LIST, id })
export const addToList = (id, name, items, hidden) => ({ type: ADD_TO_LIST, id, name, items, hidden })
export const setUpdateGood = (list, item, amount, color) =>
    ({ type: SET_UPDATE_GOOD, list, item, amount, color })
export const setModal = modal => ({ type: SET_MODAL, modal })
export const setMergedList = (list, merged) => ({ type: SET_MERGED_LIST, list, merged })
export const setRedirect = (id = null) => ({ type: SET_REDIRECT, id })
export const setRenameSuccess = () => ({ type: SET_RENAME_SUCCESS })
export const setOneClick = good => ({ type: SET_ONE_CLICK, good })
const setIsGoodInCart = good => ({ type: SET_IS_GOOD_IN_CART, good })
const setIsCompilationInCart = c => ({ type: SET_IS_COMPILATION_IN_CART, c })

const getPdf = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
}

export const sessionThunk = () => async () => {
    if (!localStorage.sid) {
        try {
            const data = await authAPI.session()
            localStorage.setItem('sid', data.sid)
        } catch (error) {
            console.log(error)
        }
    }
}

export const getLists = () => async dispatch => {
    // await dispatch(sessionThunk())
    try {
        const data = await listsAPI.getLists()
        dispatch(setLists(data.lists))
    } catch (error) {
        console.log(error)
    }
}

export const getAccountInfo = (account_id = 'self') => async dispatch => {
    try {
        const data = await accountAPI.getAccountInfo(account_id)
        dispatch(setAccountInfo(
            data.id,
            data.name,
            data.phone,
            data.email,
            data.discount_percent,
            data.admin,
            data.disabled
        ))
        dispatch(setAuth(true))
    } catch (error) {
        // console.log(error)
    }
}

export const updateAccountInfo = (
    name, phone, email, discount_percent, disabled, account_id = 'self'
) => async dispatch => {
    try {
        await accountAPI.updateAccountInfo(
            account_id, name, phone, email, discount_percent, disabled
        )
        dispatch(getAccountInfo())
    } catch (error) {
        console.log(error)
    }
}

export const loginThunk = (phone, password, captcha_token) => async dispatch => {
    // await dispatch(sessionThunk())
    try {
        await authAPI.login(phone, password, captcha_token)
        dispatch(clearLists())
        await dispatch(getAccountInfo())
        dispatch(setAuth(true))
        dispatch(callModal('enter'))
    } catch (error) {
        dispatch(callModal('autorization_error'))
        console.log(error)
    }
}

export const logoutThunk = () => async dispatch => {
    try {
        await authAPI.logout()
        dispatch(clearLists())
        dispatch(setAuth(false))
        dispatch(clearAccountInfo())
    } catch (error) {
        console.log(error)
    }
}

export const registerThunk = (name, phone, email, captcha_token) => async dispatch => {
    try {
        await accountAPI.register(name, phone, email, captcha_token)
        dispatch(setConfirmedPhone(phone))
        dispatch(callModal('sms'))
    } catch (error) {
        console.log(error)
    }
}

export const registerConfirmThunk = (phone, code) => async dispatch => {
    // await dispatch(sessionThunk())
    try {
        await accountAPI.registerConfirm(phone, code)
        dispatch(clearLists())
        await dispatch(getAccountInfo())
        dispatch(setAuth(true))
        dispatch(callModal('sms'))
        dispatch(setConfirmedPhone())
    } catch (error) {
        console.log(error)
    }
}

export const resetThunk = (phone, captcha_token) => async dispatch => {
    try {
        await accountAPI.reset(phone, captcha_token)
        dispatch(setResetPhone(phone))
        dispatch(callModal('sms'))
    } catch (error) {
        dispatch(callModal('error'))
        console.log(error)
    }
}

export const resetConfirmThunk = (phone, code) => async dispatch => {
    try {
        await accountAPI.resetConfirm(phone, code)
        dispatch(callModal('sms'))
        dispatch(setResetPhone())
    } catch (error) {
        console.log(error)
    }
}

export const getGoodsOfList = list_id => async dispatch => {
    try {
        const data = await listsAPI.getGoodsOfList(list_id)
        dispatch(addToList(
            data.list.id,
            data.list.name,
            data.list.items,
            data.list.hidden
        ))
    } catch (error) {
        console.log(error)
    }
}

export const isGoodInCart = id => async dispatch => {
    try {
        const data = await listsAPI.isGoodInCart(id)
        dispatch(setIsGoodInCart(data.in_cart))
    } catch (error) {
        console.log(error)
    }
}

export const isCompilationInCart = id => async dispatch => {
    try {
        const data = await listsAPI.isCompilationInCart(id)
        dispatch(setIsCompilationInCart(data.in_cart))
    } catch (error) {
        console.log(error)
    }
}

export const addGoodToList = (list, good_id, amount, color, isAuth = true, loc) => async dispatch => {
    try {
        await listsAPI.addGoodToList(list, good_id, amount, color)
        isAuth && dispatch(setModal('success'))
        if (!isAuth) {
            if (loc === 'homepage') {
                return dispatch(getHomeInCart(good_id))
            }
            if (loc === 'product_card') {
                return dispatch(getProductInCart(good_id))
            }
            dispatch(isGoodInCart(good_id))
        }
        dispatch(getListStatus())
    } catch (error) {
        console.log(error)
    }
}

export const addCompilationGoods = (list_id, comp_id, isAuth = true) => async dispatch => {
    try {
        await listsAPI.addCompilationGoods(list_id, comp_id)
        isAuth && dispatch(setModal('success'))
        !isAuth && dispatch(isCompilationInCart(comp_id))
        dispatch(getListStatus())
    } catch (error) {
        console.log(error)
    }
}

export const updateGood = (list_id, entry_id, amount, color) => async dispatch => {
    try {
        await listsAPI.updateGood(list_id, entry_id, amount, color)
        dispatch(setUpdateGood(list_id, entry_id, amount, color))
    } catch (error) {
        console.log(error)
    }
}

export const deleteGood = (list_id, entry_id) => async dispatch => {
    try {
        await listsAPI.deleteGood(list_id, entry_id)
        dispatch(removeItem(list_id, entry_id))
    } catch (error) {
        console.log(error)
    }
}

export const createList = (name, type) => async dispatch => {
    try {
        const data = await listsAPI.createList(name)
        dispatch(addList(data.list))
        if (type === 'account') {
            dispatch(callModal('add_list'))
            dispatch(setRedirect(data.list.id))
        }
    } catch (error) {
        console.log(error)
    }
}

export const renameList = (id, name, hidden) => async dispatch => {
    try {
        await listsAPI.renameList(id, name, hidden)
        dispatch(setRenameList(id, name, hidden))
        dispatch(setRenameSuccess())
    } catch (error) {
        console.log(error)
    }
}

export const deleteList = id => async dispatch => {
    try {
        await listsAPI.deleteList(id)
        dispatch(removeList(id))
        dispatch(callModal('delete_list'))
    } catch (error) {
        console.log(error)
    }
}

export const downloadPdf = (id, no_discount = false) => async dispatch => {
    try {
        const data = await listsAPI.downloadPdf(id, no_discount)
        getPdf(data, 'list.pdf')
        dispatch(callModal('save_pdf'))
    } catch (error) {
        console.log(error)
    }
}

export const sendPdf = (id, to, captcha_token, no_personal_discount = false) => async dispatch => {
    try {
        await listsAPI.sendPdf(id, to, captcha_token, no_personal_discount)
        dispatch(callModal('send_pdf_success'))
    } catch (error) {
        console.log(error)
        dispatch(callModal('send_pdf_error'))
    }
}

export const mergeLists = (name, list_ids) => async dispatch => {
    try {
        const data = await listsAPI.mergeLists(name, list_ids)
        dispatch(setRedirect(data.list.id))
        dispatch(setMergedList(data.list, list_ids))
        dispatch(callModal('combine'))
    } catch (error) {
        console.log(error)
    }
}

export const putOneClickOrder = (good_name, phone, captcha_token) => async dispatch => {
    try {
        await oneClickAPI.postOneClickOrder(good_name, phone, captcha_token)
        dispatch(setModal('oneClick'))
        dispatch(callModal('one_success'))
    } catch (error) {
        dispatch(callModal('one_error'))
        console.log(error)
    }
}

export const getProductById = id => async dispatch => {
    try {
        const data = await catalogAPI.getProductById(id)
        dispatch(setOneClick(data.good))
    } catch (error) {
        console.log(error)
    }
}

export const getOrders = (id = 'self') => async dispatch => {
    try {
        const data = await ordersAPI.getOrders(id)
        dispatch(setOrders(data.orders))
    } catch (error) {
        console.log(error)
    }
}

export const ordersPrepare = (list_id, type = 'list_id') => async dispatch => {
    try {
        const data = await ordersAPI.ordersPrepare(list_id, type)
        dispatch(setOrdersPrepare(
            data.volume,
            data.weight,
            data.delivery,
            data.goods_cost,
            data.discount
        ))
    } catch (error) {
        console.log(error)
    }
}

export const createOrder = (list_id, name, phone, email, receiver_name, receiver_phone,
    method, index, city, street, house, housing, floor, apartment, comment, type = 'list_id') => async dispatch => {
        try {
            const data = await ordersAPI.createOrder(list_id, name, phone, email, receiver_name,
                receiver_phone, method, index, city, street, house, housing, floor, apartment, comment, type)
            dispatch(setCreatedOrder(
                data.confirmation_url,
                data.order
            ))
        } catch (error) {
            console.log(error)
        }
    }

export const deleteOrder = order_id => async dispatch => {
    try {
        await ordersAPI.deleteOrder(order_id)
        dispatch(callModal('delete_order'))
    } catch (error) {
        console.log(error)
    }
}

export const downloadOrderPdf = (order_id, no_personal_discount = false) => async dispatch => {
    try {
        const data = await ordersAPI.downloadOrderPdf(order_id, no_personal_discount)
        getPdf(data, 'order.pdf')
        dispatch(callModal('save_order_pdf'))
    } catch (error) {
        console.log(error)
    }
}

export const sendOrderPdf = (order_id, to, no_personal_discount = false, captcha_token) => async dispatch => {
    try {
        await ordersAPI.sendOrderPdf(order_id, to, no_personal_discount, captcha_token)
        dispatch(callModal('send_order_pdf_success'))
    } catch (error) {
        console.log(error)
        dispatch(callModal('send_pdf_error'))
    }
}

export const getShippedPdf = order_id => async () => {
    try {
        const data = await ordersAPI.getShippedPdf(order_id)
        getPdf(data, 'shipped.pdf')
    } catch (error) {
        console.log(error)
    }
}

export default accountReducer