import React, { useEffect, useContext, createContext } from 'react'
import { connect } from 'react-redux'
import { getAccountInfo } from '../redux/accountReducer'

const AccountContext = createContext()

export const useAccount = () => {
    return useContext(AccountContext)
}

const AccountProvider = ({ isAuth, getAccountInfo, children }) => {

    useEffect(() => {
        localStorage.sid && getAccountInfo()
    }, [getAccountInfo])

    return (
        <>
            <AccountContext.Provider value={{
                isRegister: isAuth
            }}>
                {children}
            </AccountContext.Provider>
        </>
    )
}

const mapStateToProps = state => {
    return {
        isAuth: state.account.isAuth
    }
}

export default connect(mapStateToProps, { getAccountInfo })(AccountProvider)