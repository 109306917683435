import React from 'react'
import { connect } from 'react-redux'
import Howto from './Howto'
import { getStatic } from '../../redux/staticReducer'

const StaticContainer = ({ staticPageContent, getStatic, isAuth, hasGoods, fetching }) => {
    return (
        <Howto
            content={staticPageContent.desc}
            getStatic={getStatic}
            isAuth={isAuth}
            hasGoods={hasGoods}
            fetching={fetching}
        />
    )
}

const mapStateToProps = state => {
    return {
        staticPageContent: state.staticContent.staticPage,
        isAuth: state.account.isAuth,
        hasGoods: state.catalog.hasGoods,
        fetching: state.staticContent.fetching
    }
}

export default connect(mapStateToProps, { getStatic })(StaticContainer)
