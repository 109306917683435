import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import classes from './CheckoutData.module.sass'
import cn from 'classnames'
import CheckoutPayer from '../CheckoutData/CheckoutPayer'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'
import MoneyFormat from '../../../tools/MoneyFormat'
import CheckoutDelivery from './CheckoutDelivery'
import NoBorderButton from '../../common/buttons/NoBorderButton/NoBorderButton'

const CheckoutData = ({
    list,
    lists,
    ordersPrepare,
    createOrder,
    callModal,
    isAuth,
    isOneClick,
    orderInfo: {
        volume,
        weight,
        delivery: deliveryOpt,
        goods_cost,
        discount
    }
}) => {

    const [delivery, setDelivery] = useState(0)
    const { handleSubmit, register, unregister, errors, clearErrors, control } = useForm()

    const onSubmit = d => {
        const replacePhone = p => `+${p.replace(/[^0-9.]/g, '')}`
        createOrder(
            isOneClick ? list.split('good_id_')[1] : list,
            d.name,
            d.phone ? replacePhone(d.phone) : '',
            d.email,
            d.thirdName,
            d.thirdPhone ? replacePhone(d.thirdPhone) : '',
            d.deliveryType,
            d.zip,
            d.city,
            d.street,
            d.house,
            d.building,
            d.floor,
            d.apartment,
            d.comment,
            isOneClick ? 'good_id' : 'list_id'
        )
    }

    useEffect(() => {
        list && ordersPrepare(isOneClick ? 
            list.split('good_id_')[1] : list, isOneClick ? 'good_id' : 'list_id')
    }, [list, lists, ordersPrepare, isAuth, isOneClick])

    const getDelivery = useCallback((d) => setDelivery(d), [])

    return (
        <section className={classes.data}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.wrapper}>

                    <div className={classes.payer}>
                        <h2>Данные плательщика:</h2>
                        <CheckoutPayer
                            register={register}
                            unregister={unregister}
                            errors={errors}
                            clearErrors={clearErrors}
                            control={control}
                        />
                    </div>
                    <div className={classes.flexBlock}>
                        <div className={classes.delivery}>
                            <h2>Способ доставки:</h2>
                            <div className={cn(classes.params, 'textSmall')}>
                                <p>{`Объём заказа — ${Math.ceil(volume * 10) / 10} куб. м.`}</p>
                                <p>{`Вес заказа — ${Math.ceil(weight * 10) / 10} кг.`}</p>
                            </div>
                            <CheckoutDelivery
                                register={register}
                                errors={errors}
                                clearErrors={clearErrors}
                                deliveryOpt={deliveryOpt}
                                getDelivery={getDelivery}
                            />
                        </div>
                        <div className={classes.payment}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Товары:</td>
                                        <td><MoneyFormat value={goods_cost} /></td>
                                    </tr>
                                    <tr>
                                        <td>Доставка:</td>
                                        <td><MoneyFormat value={delivery} /></td>
                                    </tr>
                                    <tr>
                                        <td>Скидка:</td>
                                        <td><MoneyFormat value={discount} /></td>
                                    </tr>
                                    <tr>
                                        <td>Итого:</td>
                                        <td><h3><MoneyFormat value={goods_cost + delivery - discount} /></h3></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={classes.button}>
                    <BorderButton
                        name='Оплатить'
                        type='submit'
                    />
                    {isAuth &&
                        <NoBorderButton
                            name='Хотите оплатить по счёту?'
                            type='button'
                            onClick={() => callModal('order_request')}
                        />
                    }
                </div>
            </form>
        </section>
    )
}

export default CheckoutData
