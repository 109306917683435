import React, { useEffect, useCallback } from 'react'
import classes from './Compilation.module.sass'
import cn from 'classnames'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import { Col, Container, Row } from 'react-bootstrap'
import CompilationGridContainer from './CompilationGridContainer'
import Offer from '../common/Offer/Offer'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import CompilationItem from './CompilationItem'
import { connect } from 'react-redux'
import {
    getCompilations,
    getCompilationItem,
    clearCompilations
} from '../../redux/compilationReducer'
import { isCompilationInCart } from '../../redux/accountReducer'
import BasketLink from '../Catalog/BasketLink/BasketLink'

const Compilation = ({
    compilation,
    totalCount,
    compilationItem,
    itemsToShow,
    getCompilations,
    clearCompilations,
    getCompilationItem,
    ad,
    isAuth,
    hasGoods,
    compilationInCart,
    isCompilationInCart,
    fetching
}) => {
    const { path } = useRouteMatch()

    const getComp = useCallback((count = itemsToShow, offset) => {
        offset || clearCompilations()
        getCompilations(count, offset)
    }, [
        clearCompilations,
        getCompilations,
        itemsToShow
    ])

    useEffect(() => getComp(), [getComp])

    const handleClickMore = () =>
        getComp(itemsToShow, compilation.length)

    return (
        <>
            <div className={cn('fetched', fetching && 'loading')}>
                <Breadcrumbs />
            </div>
            <Switch>
                <Route path={`${path}/:id`}>
                    <section className={cn(
                        classes.compilationItem,
                        'fetched',
                        fetching && 'loading'
                    )}>
                        <CompilationItem
                            compilation={compilation}
                            compilationItem={compilationItem}
                            getCompilationItem={getCompilationItem}
                            compilationInCart={compilationInCart}
                            isCompilationInCart={isCompilationInCart}
                        />
                    </section>
                </Route>
                <Route path={path}>
                    <div className={cn('fetched', fetching && 'loading')}>
                        <section className={classes.title}>
                            <Container>
                                <Row>
                                    <Col xs={12} md={8} xl={6} className='col'>
                                        <h2>Вдохновляющие проекты</h2>
                                        <p>Посмотрите какие материалы, мебель и аксессуары мы использовали в своих проектах, вдохновляйтесь, собирайте свои списки на основе наших коллекций, решайтесь и меняйтесь к лучшему.</p>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section className={classes.compilation}>
                            <CompilationGridContainer
                                compilation={compilation}
                                handleClickMore={handleClickMore}
                                totalCount={totalCount}
                            />
                        </section>
                    </div>
                </Route>
            </Switch>
            <section className={classes.offer}>
                <Offer ad={ad} />
            </section>
            <BasketLink
                enabled={!isAuth}
                notEmpty={hasGoods}
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        totalCount: state.compilation.totalCount,
        compilation: state.compilation.compilation,
        compilationItem: state.compilation.compilationItem,
        itemsToShow: state.compilation.itemsToShow,
        ad: state.home.ad,
        isAuth: state.account.isAuth,
        hasGoods: state.catalog.hasGoods,
        compilationInCart: state.account.compilationInCart,
        fetching: state.staticContent.fetching
    }
}

const CompilationContainer = connect(mapStateToProps, {
    getCompilations,
    clearCompilations,
    getCompilationItem,
    isCompilationInCart
})(Compilation)

export default CompilationContainer
