import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs'
import CheckoutOrder from './CheckoutOrder/CheckoutOrder'
import { connect } from 'react-redux'
import {
	mergeLists,
	getLists,
	setRedirect,
	getGoodsOfList,
	deleteGood,
	ordersPrepare,
	createOrder,
	setCreatedOrder,
	getProductById
} from '../../redux/accountReducer'
import CombineModal from '../Account/Modals/CombineModal'
import { callModal, setDefaultValueIndex } from '../../redux/modalsReducer'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import CheckoutData from './CheckoutData/CheckoutData'
import PaymentErrorModal from '../Account/Modals/PaymentErrorModal'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import { compose } from 'redux'
import OrderRequestModal from '../Account/Modals/OrderRequestModal'
import cn from 'classnames'

const Checkout = ({
	lists,
	modals,
	deleteGood,
	callModal,
	mergeLists,
	defaultValueIndex,
	setDefaultValueIndex,
	isAuth,
	redirect,
	getLists,
	setRedirect,
	getGoodsOfList,
	ordersPrepare,
	orderInfo,
	createOrder,
	email,
	payURL,
	setCreatedOrder,
	oneClickList,
	getProductById,
	fetching,
	pTM,
	pM
}) => {
	const { path } = useRouteMatch()
	const history = useHistory()
	const { list, payment, } = useParams()
	const baseUrl = path.slice(0, path.lastIndexOf('/', path.lastIndexOf('/') - 1))

	const isOneClick = list && list.split('good_id_').length > 1

	useEffect(() => {
		isOneClick && getProductById(list.split('good_id_')[1])
	}, [list, getProductById, isAuth, isOneClick])

	useEffect(() => {
		!isOneClick &&
			lists.length < 1 && getLists()
	}, [lists.length, getLists, isOneClick])

	useEffect(() => {
		if (!isOneClick) {
			const current = list && lists.length > 0 && lists.filter(f => f.id === list)[0]
			lists.length > 0 && current && !current.items && getGoodsOfList(list)
		}
	}, [lists, lists, isOneClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		redirect && history.push(`${baseUrl}/${redirect}`)
		return () => setRedirect()
	}, [redirect, baseUrl, history, setRedirect])

	useEffect(() => {
		if (payURL) { window.location.href = payURL }
		return () => setCreatedOrder()
	}, [payURL, setCreatedOrder])

	return (
		<>
			<CombineModal
				modal={modals.find(f => f.id === 'combine')}
				lists={lists}
				callModal={callModal}
				mergeLists={mergeLists}
				baseUrl={baseUrl}
				defaultValueIndex={defaultValueIndex}
				setDefaultValueIndex={setDefaultValueIndex}
				checkout
			/>
			<PaymentErrorModal
				modal={modals.find(f => f.id === 'payment_error')}
				callModal={callModal}
				email={email}
				payment={payment}
			/>
			<OrderRequestModal
				modal={modals.find(f => f.id === 'order_request')}
				callModal={callModal}
			/>
			<Breadcrumbs hideFirst max={2} />
			<Container className={cn('fetched', fetching && 'loading')}>
				<Row>
					<Col xs={12} lg={6} xl={5} className='col'>
						<CheckoutOrder
							isAuth={isAuth}
							list={list}
							lists={lists}
							oneClickList={oneClickList}
							isOneClick={isOneClick}
							deleteGood={deleteGood}
							pTM={pTM}
							pM={pM}
						/>
					</Col>
					<Col xs={12} lg={{ span: 4, offset: 1 }} xl={{ span: 3, offset: 1 }} className='col'>
						<CheckoutData
							isAuth={isAuth}
							list={list}
							lists={lists}
							ordersPrepare={ordersPrepare}
							orderInfo={orderInfo}
							createOrder={createOrder}
							callModal={callModal}
							isOneClick={isOneClick}
						/>
					</Col>
				</Row>
			</Container>
		</>
	)
}

const mapStateToProps = state => {
	return (
		{
			products: state.catalog.products,
			lists: state.account.lists,
			modals: state.modals.modals,
			defaultValueIndex: state.modals.defaultValueIndex,
			isAuth: state.account.isAuth,
			redirect: state.account.redirect,
			orderInfo: state.account.orderInfo,
			email: state.staticContent.email,
			payURL: state.account.payURL,
			oneClickList: state.account.oneClickList,
			fetching: state.staticContent.fetching
		}
	)
}

export default compose(
	withBreakpoints,
	connect(mapStateToProps, {
		callModal,
		mergeLists,
		setDefaultValueIndex,
		getLists,
		setRedirect,
		getGoodsOfList,
		deleteGood,
		ordersPrepare,
		createOrder,
		setCreatedOrder,
		getProductById
	})
)(Checkout)