import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import BorderButton from '../../common/buttons/BorderButton/BorderButton'
import classes from './ProductEmpty.module.sass'

const ProductEmpty = ({ category, subcategory, getCategories, categories }) => {
    useEffect(() => {
        categories.length < 3 && getCategories()
    }, [categories.length, getCategories])

    const catName = () => {
        let name
        categories.forEach(c => {
            if (c.subcategories) {
                c.subcategories.forEach(s => {
                    if (s.path === subcategory) {
                        return name = s.name
                    }
                })
            } else {
                if (c.path === category) {
                    name = c.name
                }
            }
        })
        return name && name
    }

    return (
        <Container>
            <div className={classes.product}>
                <p>Этого временно нет в наличии. Посмотрите похожие товары в разделе {catName()}.</p>
                <BorderButton 
                    name={`Перейти в раздел ${catName()}`} 
                    to={`/catalog/${category}/${subcategory}`}
                />
            </div>
        </Container>
    )
}

export default ProductEmpty
