import React from 'react'
import SavePdfModal from './SavePdfModal'
import SendPdfModal from './SendPdfModal'
import CombineModal from './CombineModal'
import AddListModal from './AddListModal'
import { callModal, setDefaultValueIndex } from '../../../redux/modalsReducer'
import {
    downloadPdf,
    sendPdf,
    mergeLists,
    createList,
    deleteList,
    deleteOrder,
    downloadOrderPdf,
    sendOrderPdf
} from '../../../redux/accountReducer'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import PaymentSuccessModal from './PaymentSuccessModal'
import SendPdfSuccessModal from './SendPdfSuccessModal'
import SendPdfErrorModal from './SendPdfErrorModal'
import DeleteListModal from './DeleteListModal'
import DeleteOrderModal from './DeleteOrderModal'
import SaveOrderPdfModal from './SaveOrderPdfModal'
import SendOrderPdfModal from './SendOrderPdfModal'
import SendOrderPdfSuccessModal from './SendOrderPdfSuccessModal'
import BasketFirstVisit from './BasketFirstVisit'

const AccountPageModals = ({
    modals,
    lists,
    callModal,
    mergeLists,
    createList,
    defaultValueIndex,
    setDefaultValueIndex,
    baseUrl,
    downloadPdf,
    sendPdf,
    email,
    deleteList,
    deleteOrder,
    downloadOrderPdf,
    sendOrderPdf
}) => {
    const { list, payment } = useParams()
    return (
        modals.map((m, i) => {
            switch (m.id) {
                case 'save_pdf':
                    return <SavePdfModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        list={list}
                        downloadPdf={downloadPdf}
                    />
                case 'send_pdf':
                    return <SendPdfModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        list={list}
                        sendPdf={sendPdf}
                    />
                case 'send_pdf_success':
                    return <SendPdfSuccessModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                    />
                case 'send_pdf_error':
                    return <SendPdfErrorModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        email={email}
                    />
                case 'save_order_pdf':
                    return <SaveOrderPdfModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        list={list}
                        downloadOrderPdf={downloadOrderPdf}
                    />
                case 'send_order_pdf':
                    return <SendOrderPdfModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        list={list}
                        sendOrderPdf={sendOrderPdf}
                    />
                case 'send_order_pdf_success':
                    return <SendOrderPdfSuccessModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                    />
                case 'add_list':
                    return <AddListModal
                        key={i}
                        modal={m}
                        lists={lists}
                        callModal={callModal}
                        createList={createList}
                        defaultValueIndex={defaultValueIndex}
                        setDefaultValueIndex={setDefaultValueIndex}
                    />
                case 'combine':
                    return <CombineModal
                        key={i}
                        modal={m}
                        lists={lists}
                        callModal={callModal}
                        mergeLists={mergeLists}
                        baseUrl={baseUrl}
                        defaultValueIndex={defaultValueIndex}
                        setDefaultValueIndex={setDefaultValueIndex}
                    />
                case 'payment_successful':
                    return <PaymentSuccessModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        payment={payment}
                    />
                case 'delete_list':
                    return <DeleteListModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        deleteList={deleteList}
                    />
                case 'delete_order':
                    return <DeleteOrderModal
                        key={i}
                        modal={m}
                        callModal={callModal}
                        deleteOrder={deleteOrder}
                    />
                    case 'basket_first_visit':
                        return <BasketFirstVisit
                            key={i}
                            modal={m}
                            callModal={callModal}
                        />
                default:
                    return false
            }
        })
    )
}

const mapStateToProps = state => {
    return {
        modals: state.modals.modals,
        defaultValueIndex: state.modals.defaultValueIndex,
        lists: state.account.lists,
        email: state.staticContent.email
    }
}

export default connect(mapStateToProps, {
    callModal,
    mergeLists,
    createList,
    setDefaultValueIndex,
    downloadPdf,
    sendPdf,
    downloadOrderPdf,
    sendOrderPdf,
    deleteList,
    deleteOrder
})(AccountPageModals)
